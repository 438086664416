export const userLocal = {
  id: 12,
  name: "Lucie",
  surname: "Mourer",
  email: "communication.awayke@gmail.com",
  school: "IDRAC",
  role: "admin",
  group: [
    { id: 1232, name: "NDRC 1", size: 25, year: 2122 },
    { id: 1233, name: "NDRC 2", size: 23, year: 2122 },
    { id: 1234, name: "PGE 1", size: 33, year: 2122 },
    { id: 1235, name: "PGE 3", size: 25, year: 2122 },
    { id: 1236, name: "PGE 3", size: 25, year: 2021 },
    { id: 1237, name: "PGE 3", size: 25, year: 2021 },
    { id: 1238, name: "PGE 3", size: 25, year: 2021 },
  ],
};

export const studentLocal = [
  {
    id: 1,
    lastName: "Snow",
    name: "Jon",
    modules: 3,
    profilVisible: false,
    lastUpdateProfil: "02/02/22",
    profilResult: {
      disc: {
        r: 80,
        y: 90,
        b: 20,
        g: 40,
      },
      intellMultiple: {
        vl: 90,
        lm: 90,
        inter: 70,
        intra: 70,
        m: 50,
        k: 40,
        n: 20,
        s: 40,
      },
      motivation: {
        e: 90,
        c: 80,
        util: 70,
        indiv: 30,
        s: 40,
        t: 20,
      },
    },
  },
  {
    id: 2,
    lastName: "Lannister",
    name: "Cersei",
    modules: 2,
    profilVisible: false,
    lastUpdateProfil: "02/02/22",
    profilResult: {
      disc: {
        r: 80,
        y: 90,
        b: 20,
        g: 40,
      },
      intellMultiple: {
        vl: 90,
        lm: 90,
        inter: 70,
        intra: 70,
        m: 50,
        k: 40,
        n: 20,
        s: 40,
      },
      motivation: {
        e: 90,
        c: 80,
        util: 70,
        indiv: 30,
        s: 40,
        t: 20,
      },
    },
  },
  {
    id: 3,
    lastName: "Lannister",
    name: "Jaime",
    modules: 2,
    profilVisible: true,
    lastUpdateProfil: "02/02/22",
    profilResult: {
      disc: {
        r: 80,
        y: 90,
        b: 20,
        g: 40,
      },
      intellMultiple: {
        vl: 90,
        lm: 90,
        inter: 70,
        intra: 70,
        m: 50,
        k: 40,
        n: 20,
        s: 40,
      },
      motivation: {
        e: 90,
        c: 80,
        util: 70,
        indiv: 30,
        s: 40,
        t: 20,
      },
    },
  },
  {
    id: 4,
    lastName: "Stark",
    name: "Arya",
    modules: 1,
    profilVisible: true,
    lastUpdateProfil: "02/02/22",
    profilResult: {
      disc: [
        { label: "r", value: 80 },
        { label: "y", value: 90 },
        { label: "b", value: 20 },
        { label: "g", value: 40 },
      ],
      intellMultiple: [
        { label: "vl", value: 90 },
        { label: "lm", value: 90 },
        { label: "inter", value: 70 },
        { label: "intra", value: 70 },
        { label: "m", value: 50 },
        { label: "k", value: 40 },
        { label: "n", value: 20 },
        { label: "s", value: 40 },
      ],

      motivation: [
        { label: "e", value: 90 },
        { label: "c", value: 80 },
        { label: "util", value: 70 },
        { label: "indiv", value: 30 },
        { label: "s", value: 40 },
        { label: "t", value: 20 },
      ],
    },
  },
  {
    id: 5,
    lastName: "Targaryen",
    name: "Daenerys",
    modules: null,
    profilVisible: true,
    lastUpdateProfil: "02/02/22",
  },
  {
    id: 6,
    lastName: "Melisandre",
    name: null,
    modules: 1,
    profilVisible: true,
    lastUpdateProfil: "02/02/22",
    profilResult: {
      disc: {
        r: 80,
        y: 90,
        b: 20,
        g: 40,
      },
      intellMultiple: {
        vl: 90,
        lm: 90,
        inter: 70,
        intra: 70,
        m: 50,
        k: 40,
        n: 20,
        s: 40,
      },
      motivation: {
        e: 90,
        c: 80,
        util: 70,
        indiv: 30,
        s: 40,
        t: 20,
      },
    },
  },
  {
    id: 7,
    lastName: "Clifford",
    name: "Ferrara",
    modules: 3,
    profilVisible: true,
    lastUpdateProfil: "02/02/22",
    profilResult: {
      disc: {
        r: 80,
        y: 90,
        b: 20,
        g: 40,
      },
      intellMultiple: {
        vl: 90,
        lm: 90,
        inter: 70,
        intra: 70,
        m: 50,
        k: 40,
        n: 20,
        s: 40,
      },
      motivation: {
        e: 90,
        c: 80,
        util: 70,
        indiv: 30,
        s: 40,
        t: 20,
      },
    },
  },
  {
    id: 8,
    lastName: "Frances",
    name: "Rossini",
    modules: 3,
    profilVisible: true,
    lastUpdateProfil: "02/02/22",
    profilResult: {
      disc: {
        r: 80,
        y: 90,
        b: 20,
        g: 40,
      },
      intellMultiple: {
        vl: 90,
        lm: 90,
        inter: 70,
        intra: 70,
        m: 50,
        k: 40,
        n: 20,
        s: 40,
      },
      motivation: {
        e: 90,
        c: 80,
        util: 70,
        indiv: 30,
        s: 40,
        t: 20,
      },
    },
  },
  {
    id: 9,
    lastName: "Roxie",
    name: "Harvey",
    modules: 2,
    profilVisible: true,
    lastUpdateProfil: "02/02/22",
    profilResult: {
      disc: {
        r: 80,
        y: 90,
        b: 20,
        g: 40,
      },
      intellMultiple: {
        vl: 90,
        lm: 90,
        inter: 70,
        intra: 70,
        m: 50,
        k: 40,
        n: 20,
        s: 40,
      },
      motivation: {
        e: 90,
        c: 80,
        util: 70,
        indiv: 30,
        s: 40,
        t: 20,
      },
    },
  },
];
