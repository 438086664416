import {
  BigBrain,
  MiniBrain,
  WrapperPageDeskTop,
  WrapperGroupTitle,
  WrapperRightSection,
  WrapperHeader,
  WrapperSectionAndTitle,
  TitleSection,
  WrapperAllSection,
  WrapperOneSection,
  WrapperSection,
  WrapperContent,
  WrapperLeftSection,
  WrapperMiniBrain,
  SectionLeft,
  HeaderTitle,
  HeaderUpdate,
  HeaderSwitchVisible,
  HeaderLinkButton,
  WrapperRectColor,
  WrapperRectAndText,
  WrapperLegend,
  TitleLegend,
  SubTitleLegend,
  PointLegend,
  PointAndDescription,
  LegendText,
  SharePopUp,
  UndoneSection,
  WrapperQRCode,
  TooltipText,
  WrapperTooltipLevel,
  WrapperTooltipTag,
  WrapperTooltip,
  WrapperTooltipLevel1,
  WrapperTooltipLevel2,
  WrapperPagePhone,
  PhoneChartSection ,SectionChartPhone,ChartDescptionPhone,WrapperContentPhone,TitleSectionPhone,
} from "./StudentPage.style";
import React from "react";

import {
  phrasesActionDISC,
  phrasesCommunicationDISC,
  phrasesMotivationDISC,
} from "../../utils/algoProfil/modules/phrases/phrasesDISC.js";
import {
  phrasesMotivationValMot,
  phrasesActionValMot,
  phrasesCommunicationValMot,
} from "../../utils/algoProfil/modules/phrases/phrasesValMot.js";
import {
  phrasesActionIntMul,
  phrasesCommunicationIntMul,
  phrasesMotivationIntMul,
} from "../../utils/algoProfil/modules/phrases/phrasesIntellMul.js";

import { getBestPhrases } from "../../utils/algoProfil/modules/match/getBestPhrases.js";
import FormControlLabel from "@mui/material/FormControlLabel";
import { StyledSwitch } from "../../utils/StyledSwitch";
import { Button } from "@mui/material";
import ShareIcon from "@mui/icons-material/Share";
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import { useState } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { studentLocal } from "../../utils/userLocal";
import { StyledButton } from "../../utils/StyledButton.style";
import {
  Chart,
  DiscChart,
  IntellMultipleChart,
  MotivationChart,
} from "./sideGraph/SideGraph";
import { GET_MODULE_BY_STUDENT_ID } from "../../utils/graphql/module/query";
import { useMutation, useQuery } from "@apollo/client";
import { useEffect } from "react";
import { GET_GROUP_BY_ID } from "../../utils/graphql/group/query";
import { GET_CURRENT_USER } from "../../utils/graphql/user/query";
import {
  discColors,
  motivationsColors,
  intellMultipleColors,
} from "../../utils/theme";
import { UPDATE_USER } from "../../utils/graphql/user/mutation";
import { generateQRCode } from "./generateQRCode";
import { tokenise } from "../../utils/tokenise";






export const StudentPage = ({
  propsDevice,
  sidGroup,
  sidStudent,
  isShared,
}) => {
  const [isVisible, setVisible] = useState(true);
  let { idUser, idGroup, nameGroup, idStudent, name } = useParams();
  const [module, setModule] = useState(null);
  const [discValue, setDiscValue] = useState(null);
  const [valMotValue, setValMotValue] = useState(null);
  const [intMulValue, setIntMulValue] = useState(null);

  const [popUpVisible, setPopUpvisible] = useState(false);

  let navigate = useNavigate();

  const {
    loading: loadingModule,
    error: errorModule,
    data: dataModule,
  } = useQuery(GET_MODULE_BY_STUDENT_ID, {
    variables: {
      studentId: isShared ? sidStudent : parseInt(idStudent ? idStudent : 0),
    },
  });

  const {
    loading: loadingGroup,
    error: errorGroup,
    data: dataGroup,
  } = useQuery(GET_GROUP_BY_ID, {
    variables: {
      getGroupByIdId: isShared ? sidGroup : parseInt(idGroup),
    },
  });

  const {
    loading: loadingCurrentUser,
    error: errorCurrentUser,
    data: dataCurrentUser,
  } = useQuery(GET_CURRENT_USER);

  const [
    updateUser,
    { dataUpdatedUser, loadingUpdatedUser, errorUpdatedUser },
  ] = useMutation(UPDATE_USER);

  const rectAndText = (text, colors, level, tag,fontSize ) => {
    const levelKeys = Object.keys(level);


    return (
      <WrapperRectAndText>
        <TooltipText>
          <WrapperTooltip>Principalement lié aux résultats de :</WrapperTooltip>
          <WrapperTooltipTag>{tag}</WrapperTooltipTag>
          <WrapperTooltipLevel>
            <WrapperTooltipLevel1 color={colors[0]}>
              {" "}
              {levelKeys[0]}{" "}
            </WrapperTooltipLevel1>

            {levelKeys.length !== 1 ? (
              <>
                <>et</>{" "}
                <WrapperTooltipLevel2 color={colors[1]}>
                  {" "}
                  {levelKeys[1]}{" "}
                </WrapperTooltipLevel2>
              </>
            ) : (
              <></>
            )}
          </WrapperTooltipLevel>
        </TooltipText>

        {colors.map((color, index) => (
          <WrapperRectColor key={index} color={color} />
        ))}
        <WrapperSection fontSize={fontSize } >{text} </WrapperSection>
      </WrapperRectAndText>
    );
  };

  const sideDescriptionChart = (title, subtitle, content) => {
    return (
      <div style={{ width: "100%" }}>
        <WrapperLegend>
          <TitleLegend>{title}</TitleLegend>
          <SubTitleLegend>{subtitle}</SubTitleLegend>{" "}
        </WrapperLegend>
        {content.map((item) => {
          return (
            <PointAndDescription>
              <PointLegend color={item.color} />{" "}
              <LegendText>{item.name}</LegendText>
            </PointAndDescription>
          );
        })}
      </div>
    );
  };





  const enviroment = process.env.REACT_APP_ENV === "development" ?  "https://waykeup-dev.herokuapp.com/" :"https://www.waykeup.app/" 



  const formatDisc = () => {
    let disc = [];
    if (dataModule) {
      for (const [key, value] of Object.entries(
        dataModule?.getModuleByUserId?.resultDisc
      )) {
        if (key === "r" || key === "b" || key === "y" || key === "g") {
          const data = {
            label: key,
            value: value,
          };
          const dataBis = Object.assign({}, data);
          disc.push(dataBis);
        }
      }
      setDiscValue(disc);
    }
  };

  const formatValMot = () => {
    let valMot = [];
    if (dataModule) {
      for (const [key, value] of Object.entries(
        dataModule?.getModuleByUserId?.resultValMot
      )) {
        if (
          key === "e" ||
          key === "c" ||
          key === "util" ||
          key === "indiv" ||
          key === "s" ||
          key === "t"
        ) {
          const data = {
            label: key,
            value: value,
          };
          const dataBis = Object.assign({}, data);
          valMot.push(dataBis);
        }
      }
      setValMotValue(valMot);
    }
  };

  const formatIntMul = () => {
    let intMul = [];
    if (dataModule) {
      for (const [key, value] of Object.entries(
        dataModule?.getModuleByUserId?.resultIntMul
      )) {
        if (
          key === "vl" ||
          key === "lm" ||
          key === "inter" ||
          key === "intra" ||
          key === "m" ||
          key === "k" ||
          key === "n" ||
          key === "s"
        ) {
          const data = {
            label: key,
            value: value,
          };
          const dataBis = Object.assign({}, data);
          intMul.push(dataBis);
        }
      }
      setIntMulValue(intMul);
    }
  };
  useEffect(() => {
    setVisible(dataCurrentUser?.getCurrentUser?.profilVisible);
  }, [dataCurrentUser]);

  useEffect(() => {
    formatDisc();
    formatValMot();
    formatIntMul();
  }, [dataModule]);

  const updateVisibleProfil = async (isVisible, idUser) => {
    const data = await updateUser({
      variables: {
        userUpdateInput: {
          id: parseInt(idUser),
          profilVisible: !isVisible,
        },
      },
    });
    //console.log(data);
  };

  let sumDisc = 0;
  let sumIntMul = 0;
  let sumValMot = 0;
  discValue?.forEach((item) => {
    sumDisc += item?.value; // Ajoute la valeur de l'objet à la somme actuelle
  });

  intMulValue?.forEach((item) => {
    sumIntMul += item?.value; // Ajoute la valeur de l'objet à la somme actuelle
  });
  valMotValue?.forEach((item) => {
    sumValMot += item?.value; // Ajoute la valeur de l'objet à la somme actuelle
  });
  

  const shareURL = `${enviroment}shared/${tokenise(
    `${idUser}/${idGroup}/${idStudent}/${dataModule?.getModuleByUserId?.student?.name}`
  )}`;


  const downloadQRCode = () => {
    /*   //SVG
  const svgLink = document.createElement("a");

  
  svgLink.href = generateQRCode(shareURL);
  svgLink.download = "qrcode_profil_partageable_"+module?.student?.name+".svg";
  document.body.appendChild(svgLink);
  svgLink.click();
  document.body.removeChild(svgLink); */

    // PNG

    const qrCodeImg = document.getElementById("qrcode");

    // Créer un canvas pour convertir l'image en PNG
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    canvas.width = qrCodeImg.width;
    canvas.height = qrCodeImg.height;
    context.drawImage(qrCodeImg, 0, 0);

    // Convertir le contenu du canvas en PNG
    const dataURL = canvas.toDataURL("image/png");

    // Télécharger le PNG
    const pngLink = document.createElement("a");
    pngLink.href = dataURL;
    pngLink.download =
      "qrcode_profil_partageable_" +
      dataModule?.getModuleByUserId?.student?.name +
      ".png"; // Nom du fichier PNG
    document.body.appendChild(pngLink);
    pngLink.click();
    document.body.removeChild(pngLink);
  };

  const BestPhrasesDisplay = ({ phrases, tag, fontSize }) => {
    return (
      <div>
        {phrases.map((phrase, index) => {
          const colors = Object.keys(phrase.level).map((key) => {
            let colorStyle;
            switch (key) {
              case "dominant":
                colorStyle = "#F61010";
                break;
              case "influent":
                colorStyle = "#FCE200"; // Yellow color for 'y'
                break;
              case "stabilité":
                colorStyle = "#37E600"; // Green color for 'g'
                break;
              case "conformité":
                colorStyle = "#2B9DC6";
                break;
              case "linguistique":
                colorStyle = "#5bc5f2";
                break;
              case "logique":
                colorStyle = "#6bade8";
                break;
              case "intrapersonnel":
                colorStyle = "#7a95dd";
                break;
              case "interpersonnel":
                colorStyle = "#897dd0";
                break;
              case "naturaliste":
                colorStyle = "#d4049c";
                break;
              case "spaciale":
                colorStyle = "#b635b2";
                break;
              case "kinesthésique":
                colorStyle = "#9865c7";
                break;
              case "musicale":
                colorStyle = "#a74dbd";
                break;
              case "cognitive":
                colorStyle = "#b8c746";
                break;
              case "esthetique":
                colorStyle = "#a2f25c";
                break;

              case "individuelle":
                colorStyle = "#c3b13b";
                break;

              case "sociale":
                colorStyle = "#fa4402";
                break;

              case "utilitaire":
                colorStyle = "#ce9b2f";
                break;
              case "traditionnelle":
                colorStyle = "#e47019";
                break;
              default:
                console.log(`Sorry, we are out of ${key}.`);
            }

            return colorStyle;
          });

          return rectAndText(phrase.text, colors, phrase.level, tag,fontSize );
        })}
      </div>
    );
  };

  const bestPhrasesDiscAction = getBestPhrases(
    dataModule?.getModuleByUserId?.resultDisc,
    phrasesActionDISC,
    8
  );
  const bestPhrasesDiscCommunication = getBestPhrases(
    dataModule?.getModuleByUserId?.resultDisc,
    phrasesCommunicationDISC,
    4
  );
  const bestPhrasesDiscMotivation = getBestPhrases(
    dataModule?.getModuleByUserId?.resultDisc,
    phrasesMotivationDISC,
    4
  );
  ////
  const bestPhrasesIntMulAction = getBestPhrases(
    dataModule?.getModuleByUserId?.resultIntMul,
    phrasesActionIntMul,
    4
  );
  const bestPhrasesIntMulCommunication = getBestPhrases(
    dataModule?.getModuleByUserId?.resultIntMul,
    phrasesCommunicationIntMul,
    2
  );
  const bestPhrasesIntMulMotivation = getBestPhrases(
    dataModule?.getModuleByUserId?.resultIntMul,
    phrasesMotivationIntMul,
    2
  );
  /////
  const bestPhrasesValMotAction = getBestPhrases(
    dataModule?.getModuleByUserId?.resultValMot,
    phrasesActionValMot,
    4
  );
  const bestPhrasesValMotCommunication = getBestPhrases(
    dataModule?.getModuleByUserId?.resultValMot,
    phrasesCommunicationValMot,
    2
  );
  const bestPhrasesValMotMotivation = getBestPhrases(
    dataModule?.getModuleByUserId?.resultValMot,
    phrasesMotivationValMot,
    2
  );
  


  const printPage = () => {
    window.print();
  };

  return (
    <div style={{ display: "flex", width: "100%", justifyContent: "end" , height:"100vh"}}>
      <BigBrain />
      {propsDevice?.device !== "tabletOrMobile" ? (
        <WrapperPageDeskTop>
          <WrapperRightSection>
            <WrapperHeader>
              <WrapperGroupTitle>
                {isShared === true ? (
                  <></>
                ) : dataCurrentUser?.getCurrentUser?.role?.label ===
                  "student" ? (
                  <></>
                ) : (<>
                  <StyledButton
                    grota={true}
                    colorText={"#326880"}
                    backgroundColor={"transparent"}
                    colorHover={"#FFF"}
                    fontSize={"13px"}
                    isBorder={false}
                    margin={"0px"}
                    borderColor={"#326880"}
                    onClick={() =>
                      navigate(
                        "/list/" + idUser + "/" + idGroup + "/" + nameGroup
                      )
                    }
                  >
                    {"> "}
                    {dataModule?.getModuleByUserId?.student?.name
                      ? dataGroup?.getGroupById?.name
                      : "GROUP"}
                  </StyledButton>
                  <HeaderLinkButton>
                  <Button
                    variant="text"
                    startIcon={<LocalPrintshopIcon 
                      sx={{ color:"#000" }} />}
                      onClick={() =>
                        window.open(
                          `${enviroment}shared/${tokenise(
    `${idUser}/${idGroup}/${idStudent}/${dataModule?.getModuleByUserId?.student?.name}`)}`, '_blank')
                      }
                    sx={{ fontSize: "10px", width:"100px" }}
                  >
                    Imprimer
                  </Button>
                </HeaderLinkButton> </>
                )}
                {idUser !== idStudent ? (
                  <></>
                ) : isShared === true ? (
                  <HeaderLinkButton>
    <Button
      variant="text"
      startIcon={<LocalPrintshopIcon 
        sx={{ color:"#000" }} />}
      onClick={printPage}
      sx={{ fontSize: "10px", width:"100px" }}
    >
      Imprimer
    </Button>
  </HeaderLinkButton>
                ) : (
                  <HeaderSwitchVisible>
                    <FormControlLabel
                      control={
                        <StyledSwitch
                          checked={isVisible}
                          onChange={() => {
                            setVisible(!isVisible);
                            updateVisibleProfil(isVisible, idUser);
                          }}
                        />
                      }
                      label={
                        <div style={{ fontSize: "10px" }}>
                          {"Profil visible "}
                        </div>
                      }
                    />
                  </HeaderSwitchVisible>
                  
                )}
                 
              </WrapperGroupTitle>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  placeItems: "baseline",
                }}
              >
                <HeaderTitle>
                  <div>
                    {dataModule?.getModuleByUserId?.student?.name
                      ? dataModule?.getModuleByUserId?.student?.name
                      : ""}{" "}
                    {dataModule?.getModuleByUserId?.student?.lastname
                      ? dataModule?.getModuleByUserId?.student?.lastname
                      : ""}{" "}
                  </div>
                  
                  <HeaderUpdate> WaykeUp par AWAYKE</HeaderUpdate>
                </HeaderTitle>
                <img margin="0 5px 0 5px" width="110px" src="https://www.awayke.org/wp-content/uploads/2024/08/TESTSSFOND.png"/>

                {idUser !== idStudent ? (
                  <></>
                ) : isShared === true ? (
                  <></>
                ) : (
                  <HeaderLinkButton>
                    <Button
                      disabled={isVisible === false}
                      variant="contained"
                      startIcon={<ShareIcon />}
                      onClick={() => {
                        setPopUpvisible(!popUpVisible);

                        /*  navigate(
                          "/list/" + idUser + "/" + idGroup + "/" + nameGroup
                       ) */
                      }}
                      sx={{ fontSize: "10px", backgroundColor: "#376880" }}
                    >
                      Partager mon profil ( URL + QR CODE )
                      {/*  TODO onClick ={()=> copier ladresse "shared/idUser/idGroup/idStudent" dans le pressPapier}  */}
                    </Button>
                  </HeaderLinkButton>
                )}
              </div>
              {popUpVisible === true  && isVisible ===true ?(
                <>
                   <SharePopUp> <div>{`J'ai eu l'opportunité de travailler sur mes compétences `}</div> <div>{`en suivant un parcours Awayke !`}</div> 
                   <div>{` Voilà le résultat de mon introspection : `}</div><div style={{fontWeight:"700", fontStyle:"italic",color:"#5BC5F2"}}> {shareURL} </div> </SharePopUp>
                  <WrapperQRCode>
                    <img
                      src={generateQRCode(shareURL)}
                      width="150px"
                      id="qrcode"
                      alt="QR Code"
                      style={{ cursor: "pointer" }}
                      onClick={downloadQRCode}
                    />
                    cliquer pour télécharger le .png
                  </WrapperQRCode>
                </>
              ) : (
                <></>
              )}
            </WrapperHeader>
            <WrapperContent>
              <WrapperSectionAndTitle>
                {discValue && intMulValue && valMotValue ? (
                  <>
                    <TitleSection>
                      {"CE QUE JE FAIS AVEC FACILITÉ"}
                    </TitleSection>
                    <WrapperAllSection>
                      {sumDisc === 0 && isShared !== true ? (
                        <UndoneSection
                          onClick={() => {
                            navigate(
                              `/studentSingularitySetting/` +
                                dataCurrentUser?.getCurrentUser?.id
                            );
                          }}
                        >
                          {" "}
                          REMPLIR MON PROFIL "DISC"{" "}
                        </UndoneSection>
                      ) : sumDisc === 0 && isShared === true ? (
                        <></>
                      ) : (
                        <WrapperOneSection>
                          <BestPhrasesDisplay
                            phrases={bestPhrasesDiscAction}
                            tag={"DISC"}
                          />
                        </WrapperOneSection>
                      )}
                      {sumIntMul === 0 && isShared !== true ? (
                        <UndoneSection
                          onClick={() => {
                            navigate(
                              `/studentSingularitySetting/` +
                                dataCurrentUser?.getCurrentUser?.id
                            );
                          }}
                        >
                          {" "}
                          REMPLIR MON PROFIL "INTELLIGENCE MULTIPLE"{" "}
                        </UndoneSection>
                      ) : sumIntMul === 0 && isShared === true ? (
                        <></>
                      ) : (
                        <WrapperOneSection>
                          <BestPhrasesDisplay
                            phrases={bestPhrasesIntMulAction}
                            tag={"INTELLIGENCES "}
                          />
                        </WrapperOneSection>
                      )}
                      {sumValMot === 0 && isShared !== true ? (
                        <UndoneSection
                          onClick={() => {
                            navigate(
                              `/studentSingularitySetting/` +
                                dataCurrentUser?.getCurrentUser?.id
                            );
                          }}
                        >
                          {" "}
                          REMPLIR MON PROFIL "VALEURS & MOTIVATIONS"{" "}
                        </UndoneSection>
                      ) : sumValMot === 0 && isShared === true ? (
                        <></>
                      ) : (
                        <WrapperOneSection>
                          <BestPhrasesDisplay
                            phrases={bestPhrasesValMotAction}
                            tag={"MOTIVATIONS"}
                          />
                        </WrapperOneSection>
                      )}
                    </WrapperAllSection>{" "}
                  </>
                ) : (
                  <></>
                )}
              </WrapperSectionAndTitle>
              <WrapperSectionAndTitle>
                <TitleSection>{"CE QUI ME MOTIVE"}</TitleSection>
                {discValue && intMulValue && valMotValue ? (
                  <WrapperAllSection>
                    {sumDisc === 0 ? (
                      <UndoneSection
                        onClick={() => {
                          navigate(
                            `/studentSingularitySetting/` +
                              dataCurrentUser?.getCurrentUser?.id
                          );
                        }}
                      >
                        {" "}
                        REMPLIR MON PROFIL "DISC"{" "}
                      </UndoneSection>
                    ) : (
                      <WrapperOneSection>
                        <BestPhrasesDisplay
                          phrases={bestPhrasesDiscMotivation}
                          tag={"DISC"}
                        />
                      </WrapperOneSection>
                    )}
                    {sumIntMul === 0 && isShared !== true ? (
                      <UndoneSection
                        onClick={() => {
                          navigate(
                            `/studentSingularitySetting/` +
                              dataCurrentUser?.getCurrentUser?.id
                          );
                        }}
                      >
                        {" "}
                        REMPLIR MON PROFIL "INTELLIGENCE MULTIPLE"{" "}
                      </UndoneSection>
                    ) : sumIntMul === 0 && isShared === true ? (
                      <></>
                    ) : (
                      <WrapperOneSection>
                        <BestPhrasesDisplay
                          phrases={bestPhrasesIntMulMotivation}
                          tag={"INTELLIGENCES "}
                        />
                      </WrapperOneSection>
                    )}
                    {sumValMot === 0 ? (
                      <UndoneSection
                        onClick={() => {
                          navigate(
                            `/studentSingularitySetting/` +
                              dataCurrentUser?.getCurrentUser?.id
                          );
                        }}
                      >
                        {" "}
                        REMPLIR MON PROFIL "VALEURS & MOTIVATIONS"{" "}
                      </UndoneSection>
                    ) : (
                      <WrapperOneSection>
                        <BestPhrasesDisplay
                          phrases={bestPhrasesValMotMotivation}
                          tag={"MOTIVATIONS"}
                        />
                      </WrapperOneSection>
                    )}
                  </WrapperAllSection>
                ) : (
                  <></>
                )}
              </WrapperSectionAndTitle>
              <WrapperSectionAndTitle>
                <TitleSection>{"MA COMMUNICATION PRÉFÉRÉE"}</TitleSection>
                {discValue && intMulValue && valMotValue ? (
                  <WrapperAllSection>
                    {sumDisc === 0 ? (
                      <UndoneSection
                        onClick={() => {
                          navigate(
                            `/studentSingularitySetting/` +
                              dataCurrentUser?.getCurrentUser?.id
                          );
                        }}
                      >
                        {" "}
                        REMPLIR MON PROFIL "DISC"{" "}
                      </UndoneSection>
                    ) : (
                      <WrapperOneSection>
                        <BestPhrasesDisplay
                          phrases={bestPhrasesDiscCommunication}
                          tag={"DISC"}
                        />
                      </WrapperOneSection>
                    )}
                    {sumIntMul === 0 && isShared !== true ? (
                      <UndoneSection
                        onClick={() => {
                          navigate(
                            `/studentSingularitySetting/` +
                              dataCurrentUser?.getCurrentUser?.id
                          );
                        }}
                      >
                        {" "}
                        REMPLIR MON PROFIL "INTELLIGENCE MULTIPLE"{" "}
                      </UndoneSection>
                    ) : sumIntMul === 0 && isShared === true ? (
                      <></>
                    ) : (
                      <WrapperOneSection>
                        <BestPhrasesDisplay
                          phrases={bestPhrasesIntMulCommunication}
                          tag={"INTELLIGENCES "}
                        />
                      </WrapperOneSection>
                    )}
                    {sumValMot === 0 ? (
                      <UndoneSection
                        onClick={() => {
                          navigate(
                            `/studentSingularitySetting/` +
                              dataCurrentUser?.getCurrentUser?.id
                          );
                        }}
                      >
                        {" "}
                        REMPLIR MON PROFIL "VALEURS & MOTIVATIONS"{" "}
                      </UndoneSection>
                    ) : (
                      <WrapperOneSection>
                        <BestPhrasesDisplay
                          phrases={bestPhrasesValMotCommunication}
                          tag={"MOTIVATIONS"}
                        />
                      </WrapperOneSection>
                    )}
                  </WrapperAllSection>
                ) : (
                  <></>
                )}
              </WrapperSectionAndTitle>
            </WrapperContent>
          </WrapperRightSection>
          <WrapperLeftSection>
            {discValue && intMulValue && valMotValue ? (
              <>
                <WrapperMiniBrain>
                  {" "}
                  <MiniBrain />
                </WrapperMiniBrain>
                <SectionLeft>
                  <div style={{ width: "300px", height: "100%" }}>
                    <DiscChart
                      r={discValue.find((x) => x.label === "r").value}
                      y={discValue.find((x) => x.label === "y").value}
                      b={discValue.find((x) => x.label === "b").value}
                      g={discValue.find((x) => x.label === "g").value}
                    />
                  </div>
                  <div>
                    {sideDescriptionChart(
                      "COMMENT ?",
                      "Mes comportements",
                      discColors
                    )}
                  </div>
                </SectionLeft>
                <SectionLeft>
                  <div>
                    {sideDescriptionChart(
                      "AVEC QUOI ?",
                      "Mes intelligences",
                      intellMultipleColors
                    )}
                  </div>
                  <div style={{ width: "300px", height: "100%" }}>
                    <IntellMultipleChart
                      vl={intMulValue.find((x) => x.label === "vl").value}
                      lm={intMulValue.find((x) => x.label === "lm").value}
                      inter={intMulValue.find((x) => x.label === "inter").value}
                      intra={intMulValue.find((x) => x.label === "intra").value}
                      m={intMulValue.find((x) => x.label === "m").value}
                      k={intMulValue.find((x) => x.label === "k").value}
                      n={intMulValue.find((x) => x.label === "n").value}
                      s={intMulValue.find((x) => x.label === "s").value}
                    />
                  </div>
                </SectionLeft>
                <SectionLeft>
                  {" "}
                  <div style={{ width: "300px", height: "100%" }}>
                    <MotivationChart
                      e={valMotValue.find((x) => x.label === "e").value}
                      c={valMotValue.find((x) => x.label === "c").value}
                      util={valMotValue.find((x) => x.label === "util").value}
                      indiv={valMotValue.find((x) => x.label === "indiv").value}
                      s={valMotValue.find((x) => x.label === "s").value}
                      t={valMotValue.find((x) => x.label === "t").value}
                    />
                  </div>
                  <div>
                    {sideDescriptionChart(
                      "POURQUOI ?",
                      "Mes motivations",
                      motivationsColors
                    )}
                  </div>
                </SectionLeft>{" "}
              </>
            ) : (
              <></>
            )}
          </WrapperLeftSection>
        </WrapperPageDeskTop>
      ) : (
        <>
        <WrapperPagePhone> <WrapperGroupTitle>
                {isShared === true ? (
                  <></>
                ) : dataCurrentUser?.getCurrentUser?.role?.label ===
                  "student" ? (
                  <></>
                ) : (
                  <StyledButton
                    grota={true}
                    colorText={"#326880"}
                    backgroundColor={"transparent"}
                    colorHover={"#FFF"}
                    fontSize={"13px"}
                    isBorder={false}
                    margin={"0px"}
                    borderColor={"#326880"}
                    onClick={() =>
                      navigate(
                        "/list/" + idUser + "/" + idGroup + "/" + nameGroup
                      )
                    }
                  >
                    {"> "}
                    {dataModule?.getModuleByUserId?.student?.name
                      ? dataGroup?.getGroupById?.name
                      : "GROUP"}
                  </StyledButton>
                )}
                {idUser !== idStudent ? (
                  <></>
                ) : isShared === true ? (
                  <></>
                ) : (
                  <HeaderSwitchVisible>
                    <FormControlLabel
                      control={
                        <StyledSwitch
                          checked={isVisible}
                          onChange={() => {
                            setVisible(!isVisible);
                            updateVisibleProfil(isVisible, idUser);
                          }}
                        />
                      }
                      label={
                        <div style={{ fontSize: "10px" }}>
                          {"Profil visible "}
                        </div>
                      }
                    />
                  </HeaderSwitchVisible>
                )}
              </WrapperGroupTitle>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  placeItems: "baseline",
                }}
              >
                <HeaderTitle>
                  <div>
                    {dataModule?.getModuleByUserId?.student?.name
                      ? dataModule?.getModuleByUserId?.student?.name
                      : ""}{" "}
                    {dataModule?.getModuleByUserId?.student?.lastname
                      ? dataModule?.getModuleByUserId?.student?.lastname
                      : ""}{" "}
                  </div>
                  
                  <HeaderUpdate> WaykeUp par 
                  <img width="80px" src="https://www.awayke.org/wp-content/uploads/2024/08/TESTSSFOND.png"/>
                  </HeaderUpdate>
                  
                </HeaderTitle>
                </div>
                
<div style={{display:"flex", flexDirection:"column", alignItems:"center", alignSelf:"center"}}>
                {idUser !== idStudent ? (
                  <></>
                ) : isShared === true ? (
                  <></>
                ) : (
                  <HeaderLinkButton>
                    <Button
                      disabled={isVisible === false}
                      variant="contained"
                      startIcon={<ShareIcon />}
                      onClick={() => {
                        setPopUpvisible(!popUpVisible);

                        /*  navigate(
                          "/list/" + idUser + "/" + idGroup + "/" + nameGroup
                       ) */
                      }}
                      sx={{ fontSize: "10px", backgroundColor: "#376880" }}
                    >
                      Partager mon profil ( URL + QR CODE )
                      {/*  TODO onClick ={()=> copier ladresse "shared/idUser/idGroup/idStudent" dans le pressPapier}  */}
                    </Button>
                  </HeaderLinkButton>
                )}
              
              {popUpVisible === true  && isVisible ===true ?(
                <>
                  <SharePopUp> <div>{`J'ai eu l'opportunité de travailler sur mes compétences `}</div> <div>{`en suivant un parcours Awayke !`}</div> 
                  <div>{` Voilà le résultat de mon introspection : `}</div><div style={{fontWeight:"700", fontStyle:"italic",color:"#5BC5F2"}}> {shareURL} </div> </SharePopUp>
                  <WrapperQRCode>
                    <img
                      src={generateQRCode(shareURL)}
                      width="100px"
                      id="qrcode"
                      alt="QR Code"
                      style={{ cursor: "pointer" }}
                      onClick={downloadQRCode}
                    />
                    cliquer pour télécharger le .png
                  </WrapperQRCode>
                </>
              ) : (
                <></>
              )}
              </div>
              <PhoneChartSection>
              {discValue && intMulValue && valMotValue ? (
              <>
                
                <SectionChartPhone>
                  <div >
                    <DiscChart
                    height={"120px"}
                    maxWidth={"120px"}
                      r={discValue.find((x) => x.label === "r").value}
                      y={discValue.find((x) => x.label === "y").value}
                      b={discValue.find((x) => x.label === "b").value}
                      g={discValue.find((x) => x.label === "g").value}
                    />
                  </div>
                  <ChartDescptionPhone>
                    {sideDescriptionChart(
                      "COMMENT ?",
                      "Mes comportements",
                      discColors
                    )}
                  </ChartDescptionPhone>
                </SectionChartPhone>
                <SectionChartPhone>
                 
                  <div >
                    <IntellMultipleChart
                     height={"110px"}
                     maxWidth={"120px"}
                      vl={intMulValue.find((x) => x.label === "vl").value}
                      lm={intMulValue.find((x) => x.label === "lm").value}
                      inter={intMulValue.find((x) => x.label === "inter").value}
                      intra={intMulValue.find((x) => x.label === "intra").value}
                      m={intMulValue.find((x) => x.label === "m").value}
                      k={intMulValue.find((x) => x.label === "k").value}
                      n={intMulValue.find((x) => x.label === "n").value}
                      s={intMulValue.find((x) => x.label === "s").value}
                    />
                  </div>
                  <ChartDescptionPhone>
                    {sideDescriptionChart(
                      "AVEC QUOI ?",
                      "Mes intelligences",
                      intellMultipleColors
                    )}
                  </ChartDescptionPhone>
                </SectionChartPhone>
                <SectionChartPhone>
                  {" "}
                  <div >
                    <MotivationChart
                     height={"120px"}
                     maxWidth={"120px"}
                      e={valMotValue.find((x) => x.label === "e").value}
                      c={valMotValue.find((x) => x.label === "c").value}
                      util={valMotValue.find((x) => x.label === "util").value}
                      indiv={valMotValue.find((x) => x.label === "indiv").value}
                      s={valMotValue.find((x) => x.label === "s").value}
                      t={valMotValue.find((x) => x.label === "t").value}
                    />
                  </div>
                  <ChartDescptionPhone>
                    {sideDescriptionChart(
                      "POURQUOI ?",
                      "Mes motivations",
                      motivationsColors
                    )}
                  </ChartDescptionPhone>

                </SectionChartPhone>{" "}
              </>
            ) : (
              <></>
            )}</PhoneChartSection>
            <WrapperContentPhone>

            <WrapperSectionAndTitle>
                {discValue && intMulValue && valMotValue ? (
                  <>
                    <TitleSectionPhone>
                      {"CE QUE JE FAIS AVEC FACILITÉ"}
                    </TitleSectionPhone>
                    <WrapperAllSection>
                      {sumDisc === 0 && isShared !== true ? (
                        <UndoneSection
                          onClick={() => {
                            navigate(
                              `/studentSingularitySetting/` +
                                dataCurrentUser?.getCurrentUser?.id
                            );
                          }}
                        >
                          {" "}
                          REMPLIR MON PROFIL "DISC"{" "}
                        </UndoneSection>
                      ) : sumDisc === 0 && isShared === true ? (
                        <></>
                      ) : (
                        <WrapperOneSection>
                          <BestPhrasesDisplay
                            phrases={bestPhrasesDiscAction}
                            tag={"DISC"}
                            fontSize ={"11px"}
                          />
                        </WrapperOneSection>
                      )}
                      {sumIntMul === 0 && isShared !== true ? (
                        <UndoneSection
                          onClick={() => {
                            navigate(
                              `/studentSingularitySetting/` +
                                dataCurrentUser?.getCurrentUser?.id
                            );
                          }}
                        >
                          {" "}
                          REMPLIR MON PROFIL "INTELLIGENCE MULTIPLE"{" "}
                        </UndoneSection>
                      ) : sumIntMul === 0 && isShared === true ? (
                        <></>
                      ) : (
                        <WrapperOneSection>
                          <BestPhrasesDisplay
                            phrases={bestPhrasesIntMulAction}
                            tag={"INTELLIGENCES "}
                            fontSize ={"11px"}
                          />
                        </WrapperOneSection>
                      )}
                      {sumValMot === 0 && isShared !== true ? (
                        <UndoneSection
                          onClick={() => {
                            navigate(
                              `/studentSingularitySetting/` +
                                dataCurrentUser?.getCurrentUser?.id
                            );
                          }}
                        >
                          {" "}
                          REMPLIR MON PROFIL "VALEURS & MOTIVATIONS"{" "}
                        </UndoneSection>
                      ) : sumValMot === 0 && isShared === true ? (
                        <></>
                      ) : (
                        <WrapperOneSection>
                          <BestPhrasesDisplay
                            phrases={bestPhrasesValMotAction}
                            tag={"MOTIVATIONS"}
                            fontSize ={"11px"}
                          />
                        </WrapperOneSection>
                      )}
                    </WrapperAllSection>{" "}
                  </>
                ) : (
                  <></>
                )}
              </WrapperSectionAndTitle>
              <WrapperSectionAndTitle>
                <TitleSectionPhone>{"CE QUI ME MOTIVE"}</TitleSectionPhone>
                {discValue && intMulValue && valMotValue ? (
                  <WrapperAllSection>
                    {sumDisc === 0 ? (
                      <UndoneSection
                        onClick={() => {
                          navigate(
                            `/studentSingularitySetting/` +
                              dataCurrentUser?.getCurrentUser?.id
                          );
                        }}
                      >
                        {" "}
                        REMPLIR MON PROFIL "DISC"{" "}
                      </UndoneSection>
                    ) : (
                      <WrapperOneSection>
                        <BestPhrasesDisplay
                          phrases={bestPhrasesDiscMotivation}
                          tag={"DISC"}
                          fontSize ={"11px"}
                        />
                      </WrapperOneSection>
                    )}
                    {sumIntMul === 0 && isShared !== true ? (
                      <UndoneSection
                        onClick={() => {
                          navigate(
                            `/studentSingularitySetting/` +
                              dataCurrentUser?.getCurrentUser?.id
                          );
                        }}
                      >
                        {" "}
                        REMPLIR MON PROFIL "INTELLIGENCE MULTIPLE"{" "}
                      </UndoneSection>
                    ) : sumIntMul === 0 && isShared === true ? (
                      <></>
                    ) : (
                      <WrapperOneSection>
                        <BestPhrasesDisplay
                          phrases={bestPhrasesIntMulMotivation}
                          tag={"INTELLIGENCES "}
                          fontSize ={"11px"}
                        />
                      </WrapperOneSection>
                    )}
                    {sumValMot === 0 ? (
                      <UndoneSection
                        onClick={() => {
                          navigate(
                            `/studentSingularitySetting/` +
                              dataCurrentUser?.getCurrentUser?.id
                          );
                        }}
                      >
                        {" "}
                        REMPLIR MON PROFIL "VALEURS & MOTIVATIONS"{" "}
                      </UndoneSection>
                    ) : (
                      <WrapperOneSection>
                        <BestPhrasesDisplay
                          phrases={bestPhrasesValMotMotivation}
                          tag={"MOTIVATIONS"}
                          fontSize ={"11px"}
                        />
                      </WrapperOneSection>
                    )}
                  </WrapperAllSection>
                ) : (
                  <></>
                )}
              </WrapperSectionAndTitle>
              <WrapperSectionAndTitle>
                <TitleSectionPhone>{"MA COMMUNICATION PRÉFÉRÉE"}</TitleSectionPhone>
                {discValue && intMulValue && valMotValue ? (
                  <WrapperAllSection>
                    {sumDisc === 0 ? (
                      <UndoneSection
                        onClick={() => {
                          navigate(
                            `/studentSingularitySetting/` +
                              dataCurrentUser?.getCurrentUser?.id
                          );
                        }}
                      >
                        {" "}
                        REMPLIR MON PROFIL "DISC"{" "}
                      </UndoneSection>
                    ) : (
                      <WrapperOneSection>
                        <BestPhrasesDisplay
                          phrases={bestPhrasesDiscCommunication}
                          tag={"DISC"}
                        />
                      </WrapperOneSection>
                    )}
                    {sumIntMul === 0 && isShared !== true ? (
                      <UndoneSection
                        onClick={() => {
                          navigate(
                            `/studentSingularitySetting/` +
                              dataCurrentUser?.getCurrentUser?.id
                          );
                        }}
                      >
                        {" "}
                        REMPLIR MON PROFIL "INTELLIGENCE MULTIPLE"{" "}
                      </UndoneSection>
                    ) : sumIntMul === 0 && isShared === true ? (
                      <></>
                    ) : (
                      <WrapperOneSection>
                        <BestPhrasesDisplay
                          phrases={bestPhrasesIntMulCommunication}
                          tag={"INTELLIGENCES "}
                        />
                      </WrapperOneSection>
                    )}
                    {sumValMot === 0 ? (
                      <UndoneSection
                        onClick={() => {
                          navigate(
                            `/studentSingularitySetting/` +
                              dataCurrentUser?.getCurrentUser?.id
                          );
                        }}
                      >
                        {" "}
                        REMPLIR MON PROFIL "VALEURS & MOTIVATIONS"{" "}
                      </UndoneSection>
                    ) : (
                      <WrapperOneSection>
                        <BestPhrasesDisplay
                          phrases={bestPhrasesValMotCommunication}
                          tag={"MOTIVATIONS"}
                        />
                      </WrapperOneSection>
                    )}
                  </WrapperAllSection>
                ) : (
                  <></>
                )}
              </WrapperSectionAndTitle>

            </WrapperContentPhone>
            
            
            </WrapperPagePhone></>
      )}
    </div>
  );
};
