import {
  BackgroundDiv,
  InputsWrapper,
  BackgroundAlign,
  Title,
  TitleWaykeup,
  CookiesButton
} from "./Login.style";
import Cookies from "js-cookie"
import FormControl from "@mui/material/FormControl";

import crypto from "crypto-js";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import { useState } from "react";
import { theme } from "../../utils/theme";

import { StyledInput } from "../../utils/StyledInput.style";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import { StyledButton } from "../../utils/StyledButton.style";
import { StyledInlineTextButton } from "../../utils/StyledInlineTextButton.style";
import { LOG_IN } from "./graphql/mutation";
import { useMutation, useQuery } from "@apollo/client";
import { useToken } from "../../hooks/useToken";
import { useAuth } from "../../hooks/AuthContext";
import { useLocation, useNavigate } from "react-router-dom";
import { Snackbar } from "@mui/base";
import { Alert } from "@mui/material";
import { useEffect } from "react";
import { ForgotPassword } from "../../utils/ForgotPassword";
import { LoginButton } from "../signup/SignUp.style";

export const Login = ({ propsDevice }) => {
  const [values, setValues] = useState({
    email: "",
    password: "",
    showPassword: false,
  });

  const [login, { data, loading, error }] = useMutation(LOG_IN);
  const [expireTime, setExpireTime] = useState(Date.now() + 3600000);
  const [changePsw, setChangePsw] = useState(false);

  const [openAlert,setAlert] =useState(false)
  const { setToken } = useToken();
  let location = useLocation();
  let navigate = useNavigate();




  var tokenReset = crypto.AES.encrypt(
    "ResetPassword",
    "@Wk2SS3creet"
  ).toString();

 const {updateEpireTime } = useAuth();
/*   console.log(localStorage.getItem("resetToken")); */
  /* REVERT  var bytes = crypto.AES.decrypt(tokenReset, "@Wk2SS3creet");
  var originalText = bytes.toString(crypto.enc.Utf8); */

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
   if( error  ) {
    setAlert(!openAlert)}
  }, [  error ]);


  const handleRefreshCookies = () => {
    // Supprime tous les cookies
    const allCookies = Cookies.get();
    Object.keys(allCookies).forEach(cookieName => {
      Cookies.remove(cookieName);
    });
  
    // Recrée des cookies par défaut si nécessaire
    Cookies.set('example_cookie', 'default_value', { expires: 7 });
  
    // Optionnel : rafraîchir la page pour recharger les cookies
    window.location.reload();
  };

  return (
    <BackgroundAlign>
      <BackgroundDiv>
        <InputsWrapper sizeWidth={propsDevice.device}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              alignItems: "center",
              position: "relative",
            }}
          >
           <div style={{
              display: "flex",alignItems: "center", margin: "0px 10px"}}><div style={{
                display: "flex",alignItems: "center", margin: "0px 10px",fontSize: "20px"}}>🧭</div>< TitleWaykeup> WaykeUp by</TitleWaykeup></div> 
             <img margin="0 5px 0 5px" width="110px" src="https://www.awayke.org/wp-content/uploads/2024/08/TESTSSFOND.png"/>
            <Title sizeWidth={propsDevice.device}> {"SE CONNECTER"} </Title>
            <FormControl
              sx={{ margin: 1, width: "90%", alignItems: "stretch" }}
              variant="filled"
            >
              <div style={{ display: "flex" }}>
                <AlternateEmailIcon
                  sx={{ margin: "10px", alignSelf: "center" }}
                />
                <StyledInput
                  id="standard-adornment-email"
                  type={"text"}
                  value={values.email.toLowerCase()}
                  placeholder="Email"
                  onChange={handleChange("email")}
                />
              </div>

              <div style={{ display: "flex" }}>
                <InputAdornment
                  position="end"
                  sx={{ color: "#fff", alignSelf: "center", margin: "5px" }}
                >
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    size={"small"}
                    sx={{ color: "#fff", alignSelf: "center" }}
                  >
                    {values.showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
                <StyledInput
                  id="standard-adornment-password"
                  type={values.showPassword ? "text" : "password"}
                  value={values.password}
                  onChange={handleChange("password")}
                  placeholder="Mot de passe"
                />
              </div>
            </FormControl>
          </div>
          {<Snackbar
                  open={openAlert}
                  autoHideDuration={3000}
                  onClose={()=>setAlert(false)}>
                    <Alert
                    onClose={()=>setAlert(false)}
                    severity="error"
                    sx={{width:"100%"}}>
                        Identifiants Invalides 🥸
                    </Alert>
                   </Snackbar>}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              alignItems: "center",
              position: "relative",
            }}
          >
             <div style={{ display:"flex", flexDirection:"column", margin:"20px 0 0 0" }}>
              <StyledButton
                grota={true}
                colorText={theme.palette.primary.orangeText}
                onClick={async () => {
                  const data = await login({
                    variables: {
                      loginInput: {
                        email: values.email,
                        password: values.password,
                      },
                    },
                  });

                  if (data?.data.logIn) {
                    try {
                      await setToken(data.data.logIn);
                       // Mettre à jour le temps d'expiration une fois connecté
                       updateEpireTime (Date.now() + 3600000); // Met à jour avec une heure supplémentaire
                    } catch (e) {
                      console.error("Erreur lors de la mise à jour du jeton:", e);
                    }
                  } else {
                  }
                  if (location.state) {
                 
                    /* navigate(location.state); */
                  } else {
                    navigate("/workspace");
                  }
                }}
              >
                {"C'est parti !"}
              </StyledButton>
              <CookiesButton onClick={handleRefreshCookies}> Problèmes de connection ? Rafraichir les cookies 🍪 </CookiesButton>
            </div>
           
          </div>
        </InputsWrapper>
      </BackgroundDiv>
    </BackgroundAlign>
  );
};
