import React, { useState, useEffect } from "react";
import { RoundClass } from "../../utils/roundClass/RoundClass";
import { WrapperWorkspace } from "./Workspace.style";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { colors } from "../../utils/colors";
import { InputLabel } from "@mui/material";
import { FormControl } from "@mui/material";
import { StyledButton } from "../../utils/StyledButton.style";
import { DialogInput } from "../../utils/dialog/DialogInput";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useNavigate, useParams } from "react-router-dom";

import { GET_CURRENT_USER } from "../../utils/graphql/user/query";
import { useQuery, useMutation, gql } from "@apollo/client";
import { CREATE_SCHOOL } from "./graphgl/mutation";
import { GET_GROUPS } from "../../utils/graphql/group/query";
import { CREATE_GROUP } from "../../utils/graphql/group/mutation";
import { GET_SCHOOLS } from "../../utils/graphql/school/query";
import { GET_GROUP_BY_SCHOOL_ID } from "../../utils/graphql/group/query";

export const WorkspaceForAdminToSchool = ({ propsDevice, idSchoolAdmin }) => {
  let navigate = useNavigate();
  let { idSchool, name } = useParams();

  const {
    client,
    loading: loadingCurrentUser,
    error: errorCurrentUser,
    data: dataCurrentUser,
  } = useQuery(GET_CURRENT_USER);



  const {
    loading: loadingGroupBy,
    error: errorGroupBy,
    data: dataGroupBy,
  } = useQuery(GET_GROUP_BY_SCHOOL_ID, {
    variables: {
      schoolId: parseInt(idSchool) ,
    },
  });





  const [year, setYear] = useState(2324);
  const [openDialog, setOpenDialog] = useState(false);
  const [valueLibele, setValueLibele] = useState("");
  const [valueYear, setValueYear] = useState(2324);
  const [valueSchool, setValueSchool] = useState("");
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredItems, setFilteredItems] = useState([]);

  const {
    loading: loadingGroup,
    error: errorGroup,
    data: dataGroup,
  } = useQuery(GET_GROUPS);

  const {
    loading: loadingSchools,
    error: errorSchools,
    data: dataSchools,
  } = useQuery(GET_SCHOOLS);

  

  

  const [
    createGroup,
    { dataCreationGroup, loadingCreationGroup, errorCreationGroup },
  ] = useMutation(CREATE_GROUP, {
    update(cache, { data: { createGroup } }) {
      cache.modify({
        fields: {
          groups(existingsGroups = []) {
            const newGroupRef = cache.writeFragment({
              data: createGroup,
              fragment: gql`
                fragment NewGroup on Group {
                  name
                  school_id
                  year
                }
              `,
            });
            return [...existingsGroups, newGroupRef];
          },
        },
      });
    },
  });

  const handleChange = (event) => {
    setYear(event.target.value);
  };

  const handleClickOpen = () => {
    setOpenDialog(!openDialog);
  };


  const addNewItem = async (libelle, year, school) => {
    const data = await createGroup({
      variables: {
        groupInput: {
          name: libelle,
          school_id: parseInt(idSchool),
          year: parseInt(year),
        },optimisticResponse: {
          __typename: "Mutation",
          createSchool: {
              __typename: "Group",
              school_id: parseInt(school),
          year: parseInt(year),
          }
      }
      },
    });


  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  const handleConfirm = (value, year, school) => {
    setOpenDialog(false);

    addNewItem(value, year, school);
  };

  const redirect = () => {
    if (dataCurrentUser) {
      if (dataCurrentUser?.getCurrentUser?.roleId === 2) {
        navigate("/workspace");
      }
      if (dataCurrentUser?.getCurrentUser?.roleId === 3) {
        navigate(
          `/studentSingularitySetting/${dataCurrentUser?.getCurrentUser?.id.parse()}`
        );
      }
    }
  };
  redirect();



  useEffect(() => {
    if (dataGroupBy) {
      const groups = Object.values(dataGroupBy?.getGroupBySchoolId || {});
      const filtered = groups.filter(item =>
        item?.name?.toLowerCase().includes(searchTerm.toLowerCase()) && item?.year === year
      );
      setFilteredItems(filtered);
    }
  }, [searchTerm, dataGroupBy, year]);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        margin: "20px",
        width: "100%",
        height: "100%",
        alignItems: "center",
      }}
    >
      {" "}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "90%",
        }}
      >
        <div
          style={{
            alignSelf: "center",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              font: "normal normal bold 27px Helvetica Neue",
              color: "#316880",
            }}
          >
            {name}
          </div>
          <StyledButton
            grota={true}
            colorText={"#326880"}
            backgroundColor={"transparent"}
            colorHover={"#FFF"}
            fontSize={"13px"}
            isBorder={true}
            borderColor={"#326880"}
            onClick={handleClickOpen}
          >
            {"AJOUTER UNE CLASSE"}
          </StyledButton>
        </div>
{name === "AWAYKE" ? <></>: 
        <FormControl sx={{ m: 1, minWidth: 80 }}>
          <InputLabel id="demo-simple-select-autowidth-label">Année</InputLabel>

          <Select
            labelId="demo-simple-select-autowidth-label"
            id="demo-simple-select-autowidth"
            value={year}
            onChange={handleChange}
            label="Année"
            sx={{ margin: "0 10px 0 0" }}
          >
            <MenuItem value={2223}>2022-2023</MenuItem>
            <MenuItem value={2324}>2023-2024</MenuItem>
            <MenuItem value={2425}>2024-2025</MenuItem>
          </Select>
        </FormControl>}
      </div>
      <input
    type="text"
    placeholder="Rechercher..."
    value={searchTerm}
    onChange={(e) => setSearchTerm(e.target.value)}
  />
      <WrapperWorkspace>
    

  {filteredItems.length > 0 ? (
    filteredItems.map((item, index) => (
      <RoundClass
        key={item?.id || index}
        propsDevice={propsDevice.device}
        item={item}
        itemSize={item["students"]?.length || 0}
        color={colors[index]}
        currentUserId={dataCurrentUser?.getCurrentUser?.id}
        navigateLink={`/list/${dataCurrentUser?.getCurrentUser?.id}/${item?.id}/${item?.name}`}
      />
    ))
  ) : (
    <>Aucun élément trouvé</>
  )}
      </WrapperWorkspace>
      <div>
        <Dialog open={openDialog} onClose={handleClose}>
          <DialogTitle>{"AJOUTER UN GROUPE"}</DialogTitle>
          <DialogContent>
            <DialogContentText>{""}</DialogContentText>

            <div style={{ display: "flex", alignItems: "center" }}>
              <FormControl sx={{ m: 1, minWidth: 200 }}>
                <div id="demo-simple-select-autowidth-label">Établissement</div>

                {dataSchools?.getSchools?.map((item) => {
                  if (item.id == idSchool) {
                    return (
                      <TextField
                        sx={{ width: "250px" }}
                        size={"100%"}
                        value={item.name}
                      />
                    );
                  }
                })}
              </FormControl>
              <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                <InputLabel id="demo-select-small">Année</InputLabel>
                <Select
                  labelId="demo-select-small"
                  id="demo-select-small"
                  value={valueYear}
                  label="Année"
                  onChange={(e) => setValueYear(e.target.value)}
                >
                  <MenuItem value={2223}>2022-2023</MenuItem>
                  <MenuItem value={2324}>2023-2024</MenuItem>
                  <MenuItem value={2425}>2024-2025</MenuItem>
                </Select>
              </FormControl>
            </div>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label={"Libélé du groupe"}
              type="text"
              fullWidth
              variant="standard"
              value={valueLibele}
              onChange={(e) => setValueLibele(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Fermer</Button>
            <Button
              onClick={() => handleConfirm(valueLibele, valueYear, valueSchool)}
            >
              Confirmer
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
};
