export const phrasesActionIntMul = [
  {
    text: "Utilise des rythmes pour mémoriser des informations.",
    scores: {
      musicale: 0.8,
      intrapersonnel: 0.2,
      interpersonnel: 0.1,
      naturaliste: 0.1,
      spaciale: 0.1,
      kinesthésique: 0.1,
      linguistique: 0.1,
      logique: 0.1,
    },
  },
  {
    text: "Danse ou bouge en rythme pour se concentrer.",
    scores: {
      musicale: 0.7,
      intrapersonnel: 0.1,
      interpersonnel: 0.1,
      naturaliste: 0.1,
      spaciale: 0.1,
      kinesthésique: 0.8,
      linguistique: 0.1,
      logique: 0.1,
    },
  },
  {
    text: "Compose des chansons pour exprimer des idées.",
    scores: {
      musicale: 0.9,
      intrapersonnel: 0.3,
      interpersonnel: 0.1,
      naturaliste: 0.1,
      spaciale: 0.1,
      kinesthésique: 0.1,
      linguistique: 0.1,
      logique: 0.1,
    },
  },
  {
    text: "Préfère travailler seul pour mieux se concentrer.",
    scores: {
      musicale: 0.1,
      intrapersonnel: 0.8,
      interpersonnel: 0.1,
      naturaliste: 0.1,
      spaciale: 0.1,
      kinesthésique: 0.1,
      linguistique: 0.1,
      logique: 0.1,
    },
  },
  {
    text: "Réfléchit profondément avant de prendre des décisions importantes.",
    scores: {
      musicale: 0.1,
      intrapersonnel: 0.9,
      interpersonnel: 0.1,
      naturaliste: 0.1,
      spaciale: 0.1,
      kinesthésique: 0.1,
      linguistique: 0.1,
      logique: 0.1,
    },
  },
  {
    text: "Fixe des objectifs personnels pour se motiver.",
    scores: {
      musicale: 0.1,
      intrapersonnel: 0.9,
      interpersonnel: 0.1,
      naturaliste: 0.1,
      spaciale: 0.1,
      kinesthésique: 0.1,
      linguistique: 0.1,
      logique: 0.1,
    },
  },
  {
    text: "Travaille efficacement en équipe pour atteindre des objectifs communs.",
    scores: {
      musicale: 0.1,
      intrapersonnel: 0.1,
      interpersonnel: 0.8,
      naturaliste: 0.1,
      spaciale: 0.1,
      kinesthésique: 0.1,
      linguistique: 0.1,
      logique: 0.1,
    },
  },
  {
    text: "Comprend intuitivement les émotions et les motivations des autres.",
    scores: {
      musicale: 0.1,
      intrapersonnel: 0.1,
      interpersonnel: 0.9,
      naturaliste: 0.1,
      spaciale: 0.1,
      kinesthésique: 0.1,
      linguistique: 0.1,
      logique: 0.1,
    },
  },
  {
    text: "Utilise la communication verbale et non verbale pour influencer positivement les autres.",
    scores: {
      musicale: 0.1,
      intrapersonnel: 0.1,
      interpersonnel: 0.8,
      naturaliste: 0.1,
      spaciale: 0.1,
      kinesthésique: 0.1,
      linguistique: 0.1,
      logique: 0.1,
    },
  },
  {
    text: "Observe et analyse les schémas et les relations dans la nature.",
    scores: {
      musicale: 0.1,
      intrapersonnel: 0.1,
      interpersonnel: 0.1,
      naturaliste: 0.8,
      spaciale: 0.1,
      kinesthésique: 0.1,
      linguistique: 0.1,
      logique: 0.1,
    },
  },
  {
    text: "S'intéresse aux animaux et à leur comportement dans leur habitat naturel.",
    scores: {
      musicale: 0.1,
      intrapersonnel: 0.1,
      interpersonnel: 0.1,
      naturaliste: 0.9,
      spaciale: 0.1,
      kinesthésique: 0.1,
      linguistique: 0.1,
      logique: 0.1,
    },
  },
  {
    text: "Catalogue et classe les spécimens naturels pour comprendre leur diversité.",
    scores: {
      musicale: 0.1,
      intrapersonnel: 0.1,
      interpersonnel: 0.1,
      naturaliste: 0.7,
      spaciale: 0.1,
      kinesthésique: 0.1,
      linguistique: 0.1,
      logique: 0.1,
    },
  },
  {
    text: "Utilise des images mentales pour résoudre des problèmes complexes.",
    scores: {
      musicale: 0.1,
      intrapersonnel: 0.1,
      interpersonnel: 0.1,
      naturaliste: 0.1,
      spaciale: 0.8,
      kinesthésique: 0.1,
      linguistique: 0.1,
      logique: 0.1,
    },
  },
  {
    text: "Crée des modèles visuels détaillés pour représenter des concepts abstraits.",
    scores: {
      musicale: 0.1,
      intrapersonnel: 0.1,
      interpersonnel: 0.1,
      naturaliste: 0.1,
      spaciale: 0.9,
      kinesthésique: 0.1,
      linguistique: 0.1,
      logique: 0.1,
    },
  },
  {
    text: "Lis des cartes et navigue dans des environnements complexes avec facilité.",
    scores: {
      musicale: 0.1,
      intrapersonnel: 0.1,
      interpersonnel: 0.1,
      naturaliste: 0.1,
      spaciale: 0.7,
      kinesthésique: 0.1,
      linguistique: 0.1,
      logique: 0.1,
    },
  },
  {
    text: "Apprend mieux à travers des activités physiques et pratiques.",
    scores: {
      musicale: 0.1,
      intrapersonnel: 0.1,
      interpersonnel: 0.1,
      naturaliste: 0.1,
      spaciale: 0.1,
      kinesthésique: 0.8,
      linguistique: 0.1,
      logique: 0.1,
    },
  },
  {
    text: "Utilise le mouvement corporel pour exprimer des idées et des émotions.",
    scores: {
      musicale: 0.1,
      intrapersonnel: 0.1,
      interpersonnel: 0.1,
      naturaliste: 0.1,
      spaciale: 0.1,
      kinesthésique: 0.9,
      linguistique: 0.1,
      logique: 0.1,
    },
  },
  {
    text: "Maîtrise des compétences fines et grossières pour réaliser des tâches variées.",
    scores: {
      musicale: 0.1,
      intrapersonnel: 0.1,
      interpersonnel: 0.1,
      naturaliste: 0.1,
      spaciale: 0.1,
      kinesthésique: 0.7,
      linguistique: 0.1,
      logique: 0.1,
    },
  },
  {
    text: "S'exprime clairement et de manière persuasive à l'écrit et à l'oral.",
    scores: {
      musicale: 0.1,
      intrapersonnel: 0.1,
      interpersonnel: 0.1,
      naturaliste: 0.1,
      spaciale: 0.1,
      kinesthésique: 0.1,
      linguistique: 0.8,
      logique: 0.1,
    },
  },
  {
    text: "Utilise un vocabulaire étendu pour communiquer des idées complexes.",
    scores: {
      musicale: 0.1,
      intrapersonnel: 0.1,
      interpersonnel: 0.1,
      naturaliste: 0.1,
      spaciale: 0.1,
      kinesthésique: 0.1,
      linguistique: 0.9,
      logique: 0.1,
    },
  },
  {
    text: "Apprend mieux à travers la lecture et l'écriture.",
    scores: {
      musicale: 0.1,
      intrapersonnel: 0.1,
      interpersonnel: 0.1,
      naturaliste: 0.1,
      spaciale: 0.1,
      kinesthésique: 0.1,
      linguistique: 0.8,
      logique: 0.1,
    },
  },
  {
    text: "Utilise la logique pour résoudre des problèmes mathématiques complexes.",
    scores: {
      musicale: 0.1,
      intrapersonnel: 0.1,
      interpersonnel: 0.1,
      naturaliste: 0.1,
      spaciale: 0.1,
      kinesthésique: 0.1,
      linguistique: 0.1,
      logique: 0.9,
    },
  },
  {
    text: "Analyse et interprète les données pour tirer des conclusions précises.",
    scores: {
      musicale: 0.1,
      intrapersonnel: 0.1,
      interpersonnel: 0.1,
      naturaliste: 0.1,
      spaciale: 0.1,
      kinesthésique: 0.1,
      linguistique: 0.1,
      logique: 0.9,
    },
  },
  {
    text: "Développe des théories et des modèles pour expliquer des phénomènes naturels.",
    scores: {
      musicale: 0.1,
      intrapersonnel: 0.1,
      interpersonnel: 0.1,
      naturaliste: 0.1,
      spaciale: 0.1,
      kinesthésique: 0.1,
      linguistique: 0.1,
      logique: 0.8,
    },
  },
];

export const phrasesCommunicationIntMul = [
  {
    text: "Communique ses émotions à travers la musique et le rythme.",
    scores: {
      musicale: 0.8,
      intrapersonnel: 0.2,
      interpersonnel: 0.1,
      naturaliste: 0.1,
      spaciale: 0.1,
      kinesthésique: 0.1,
      linguistique: 0.1,
      logique: 0.1,
    },
  },
  {
    text: "Utilise des compositions musicales pour exprimer des idées complexes.",
    scores: {
      musicale: 0.9,
      intrapersonnel: 0.3,
      interpersonnel: 0.1,
      naturaliste: 0.1,
      spaciale: 0.1,
      kinesthésique: 0.1,
      linguistique: 0.1,
      logique: 0.1,
    },
  },
  {
    text: "Collabore avec d'autres musiciens pour créer une harmonie musicale.",
    scores: {
      musicale: 0.7,
      intrapersonnel: 0.1,
      interpersonnel: 0.8,
      naturaliste: 0.1,
      spaciale: 0.1,
      kinesthésique: 0.1,
      linguistique: 0.1,
      logique: 0.1,
    },
  },
  // Ajouter d'autres phrases pertinentes pour l'intelligence musicale-rhythmique

  {
    text: "Exprime ses pensées et ses émotions à travers des journaux personnels.",
    scores: {
      musicale: 0.1,
      intrapersonnel: 0.8,
      interpersonnel: 0.1,
      naturaliste: 0.1,
      spaciale: 0.1,
      kinesthésique: 0.1,
      linguistique: 0.1,
      logique: 0.1,
    },
  },
  {
    text: "Participe à des séances de méditation pour se connaître mieux soi-même.",
    scores: {
      musicale: 0.1,
      intrapersonnel: 0.9,
      interpersonnel: 0.1,
      naturaliste: 0.1,
      spaciale: 0.1,
      kinesthésique: 0.1,
      linguistique: 0.1,
      logique: 0.1,
    },
  },
  {
    text: "Utilise des techniques d'introspection pour résoudre des conflits internes.",
    scores: {
      musicale: 0.1,
      intrapersonnel: 0.9,
      interpersonnel: 0.1,
      naturaliste: 0.1,
      spaciale: 0.1,
      kinesthésique: 0.1,
      linguistique: 0.1,
      logique: 0.1,
    },
  },
  // Ajouter d'autres phrases pertinentes pour l'intelligence intrapersonnelle

  {
    text: "Écoute activement les autres pour comprendre leurs besoins et leurs émotions.",
    scores: {
      musicale: 0.1,
      intrapersonnel: 0.1,
      interpersonnel: 0.9,
      naturaliste: 0.1,
      spaciale: 0.1,
      kinesthésique: 0.1,
      linguistique: 0.1,
      logique: 0.1,
    },
  },
  {
    text: "Utilise des compétences en communication pour résoudre les conflits de manière pacifique.",
    scores: {
      musicale: 0.1,
      intrapersonnel: 0.1,
      interpersonnel: 0.8,
      naturaliste: 0.1,
      spaciale: 0.1,
      kinesthésique: 0.1,
      linguistique: 0.1,
      logique: 0.1,
    },
  },
  {
    text: "Anime des discussions de groupe pour encourager la participation de tous.",
    scores: {
      musicale: 0.1,
      intrapersonnel: 0.1,
      interpersonnel: 0.8,
      naturaliste: 0.1,
      spaciale: 0.1,
      kinesthésique: 0.1,
      linguistique: 0.1,
      logique: 0.1,
    },
  },
  // Ajouter d'autres phrases pertinentes pour l'intelligence interpersonnelle

  {
    text: "Partage des connaissances sur l'environnement pour sensibiliser les autres à la conservation.",
    scores: {
      musicale: 0.1,
      intrapersonnel: 0.1,
      interpersonnel: 0.1,
      naturaliste: 0.8,
      spaciale: 0.1,
      kinesthésique: 0.1,
      linguistique: 0.1,
      logique: 0.1,
    },
  },
  {
    text: "Organise des expéditions pour étudier la faune et la flore locales.",
    scores: {
      musicale: 0.1,
      intrapersonnel: 0.1,
      interpersonnel: 0.1,
      naturaliste: 0.9,
      spaciale: 0.1,
      kinesthésique: 0.1,
      linguistique: 0.1,
      logique: 0.1,
    },
  },
  {
    text: "Enseigne des méthodes de jardinage durable pour promouvoir l'autosuffisance.",
    scores: {
      musicale: 0.1,
      intrapersonnel: 0.1,
      interpersonnel: 0.1,
      naturaliste: 0.7,
      spaciale: 0.1,
      kinesthésique: 0.1,
      linguistique: 0.1,
      logique: 0.1,
    },
  },
  // Ajouter d'autres phrases pertinentes pour l'intelligence naturaliste

  {
    text: "Utilise des présentations visuelles pour expliquer des concepts complexes.",
    scores: {
      musicale: 0.1,
      intrapersonnel: 0.1,
      interpersonnel: 0.1,
      naturaliste: 0.1,
      spaciale: 0.8,
      kinesthésique: 0.1,
      linguistique: 0.1,
      logique: 0.1,
    },
  },
  {
    text: "Crée des œuvres d'art visuelles pour inspirer et provoquer la réflexion.",
    scores: {
      musicale: 0.1,
      intrapersonnel: 0.1,
      interpersonnel: 0.1,
      naturaliste: 0.1,
      spaciale: 0.9,
      kinesthésique: 0.1,
      linguistique: 0.1,
      logique: 0.1,
    },
  },
  {
    text: "Utilise des outils de modélisation 3D pour concevoir des prototypes réalistes.",
    scores: {
      musicale: 0.1,
      intrapersonnel: 0.1,
      interpersonnel: 0.1,
      naturaliste: 0.1,
      spaciale: 0.9,
      kinesthésique: 0.1,
      linguistique: 0.1,
      logique: 0.1,
    },
  },
  // Ajouter d'autres phrases pertinentes pour l'intelligence visuo-spatiale

  {
    text: "Démontre des compétences athlétiques exceptionnelles lors de compétitions sportives.",
    scores: {
      musicale: 0.1,
      intrapersonnel: 0.1,
      interpersonnel: 0.1,
      naturaliste: 0.1,
      spaciale: 0.1,
      kinesthésique: 0.9,
      linguistique: 0.1,
      logique: 0.1,
    },
  },
  {
    text: "Enseigne des techniques de danse pour exprimer des émotions à travers le mouvement.",
    scores: {
      musicale: 0.1,
      intrapersonnel: 0.1,
      interpersonnel: 0.1,
      naturaliste: 0.1,
      spaciale: 0.1,
      kinesthésique: 0.8,
      linguistique: 0.1,
      logique: 0.1,
    },
  },
  {
    text: "Utilise des compétences en artisanat pour créer des objets fonctionnels et esthétiques.",
    scores: {
      musicale: 0.1,
      intrapersonnel: 0.1,
      interpersonnel: 0.1,
      naturaliste: 0.1,
      spaciale: 0.1,
      kinesthésique: 0.9,
      linguistique: 0.1,
      logique: 0.1,
    },
  },
  // Ajouter d'autres phrases pertinentes pour l'intelligence corporelle-kinesthésique

  {
    text: "Communique clairement des idées complexes à travers l'écriture persuasive.",
    scores: {
      musicale: 0.1,
      intrapersonnel: 0.1,
      interpersonnel: 0.1,
      naturaliste: 0.1,
      spaciale: 0.1,
      kinesthésique: 0.1,
      linguistique: 0.9,
      logique: 0.1,
    },
  },
  {
    text: "Participe à des débats pour défendre des points de vue avec des arguments convaincants.",
    scores: {
      musicale: 0.1,
      intrapersonnel: 0.1,
      interpersonnel: 0.1,
      naturaliste: 0.1,
      spaciale: 0.1,
      kinesthésique: 0.1,
      linguistique: 0.9,
      logique: 0.1,
    },
  },
  {
    text: "Rédige des scripts pour des productions artistiques et des discours captivants.",
    scores: {
      musicale: 0.1,
      intrapersonnel: 0.1,
      interpersonnel: 0.1,
      naturaliste: 0.1,
      spaciale: 0.1,
      kinesthésique: 0.1,
      linguistique: 0.9,
      logique: 0.1,
    },
  },
  // Ajouter d'autres phrases pertinentes pour l'intelligence verbale-linguistique

  {
    text: "Utilise des raisonnements logiques pour résoudre des problèmes complexes en informatique.",
    scores: {
      musicale: 0.1,
      intrapersonnel: 0.1,
      interpersonnel: 0.1,
      naturaliste: 0.1,
      spaciale: 0.1,
      kinesthésique: 0.1,
      linguistique: 0.1,
      logique: 0.9,
    },
  },
  {
    text: "Analyse des algorithmes pour optimiser les performances des systèmes informatiques.",
    scores: {
      musicale: 0.1,
      intrapersonnel: 0.1,
      interpersonnel: 0.1,
      naturaliste: 0.1,
      spaciale: 0.1,
      kinesthésique: 0.1,
      linguistique: 0.1,
      logique: 0.9,
    },
  },
  {
    text: "Programme des simulations pour modéliser des phénomènes naturels complexes.",
    scores: {
      musicale: 0.1,
      intrapersonnel: 0.1,
      interpersonnel: 0.1,
      naturaliste: 0.1,
      spaciale: 0.1,
      kinesthésique: 0.1,
      linguistique: 0.1,
      logique: 0.8,
    },
  },
  // Ajouter d'autres phrases pertinentes pour l'intelligence logique
];

export const phrasesMotivationIntMul = [
  {
    text: "Est motivé par la possibilité de créer et d'apprécier des compositions musicales uniques.",
    scores: {
      musicale: 0.9,
      intrapersonnel: 0.3,
      interpersonnel: 0.1,
      naturaliste: 0.1,
      spaciale: 0.1,
      kinesthésique: 0.1,
      linguistique: 0.1,
      logique: 0.1,
    },
  },
  {
    text: "Trouve de la satisfaction dans la création de nouvelles mélodies et rythmes inspirants.",
    scores: {
      musicale: 0.8,
      intrapersonnel: 0.2,
      interpersonnel: 0.1,
      naturaliste: 0.1,
      spaciale: 0.1,
      kinesthésique: 0.1,
      linguistique: 0.1,
      logique: 0.1,
    },
  },
  {
    text: "Explore différents genres musicaux pour enrichir sa compréhension de la musique.",
    scores: {
      musicale: 0.7,
      intrapersonnel: 0.1,
      interpersonnel: 0.1,
      naturaliste: 0.1,
      spaciale: 0.1,
      kinesthésique: 0.1,
      linguistique: 0.1,
      logique: 0.1,
    },
  },
  // Ajouter d'autres phrases pertinentes pour l'intelligence musicale-rhythmique

  {
    text: "Est motivé par le désir de se connaître profondément et d'atteindre l'auto-actualisation.",
    scores: {
      musicale: 0.1,
      intrapersonnel: 0.9,
      interpersonnel: 0.1,
      naturaliste: 0.1,
      spaciale: 0.1,
      kinesthésique: 0.1,
      linguistique: 0.1,
      logique: 0.1,
    },
  },
  {
    text: "Trouve de la satisfaction à dépasser ses limites personnelles et à s'améliorer constamment.",
    scores: {
      musicale: 0.1,
      intrapersonnel: 0.8,
      interpersonnel: 0.1,
      naturaliste: 0.1,
      spaciale: 0.1,
      kinesthésique: 0.1,
      linguistique: 0.1,
      logique: 0.1,
    },
  },
  {
    text: "Est stimulé par les défis qui favorisent la croissance personnelle et l'exploration intérieure.",
    scores: {
      musicale: 0.1,
      intrapersonnel: 0.9,
      interpersonnel: 0.1,
      naturaliste: 0.1,
      spaciale: 0.1,
      kinesthésique: 0.1,
      linguistique: 0.1,
      logique: 0.1,
    },
  },
  // Ajouter d'autres phrases pertinentes pour l'intelligence intrapersonnelle

  {
    text: "Est motivé par le désir d'aider les autres à atteindre leurs objectifs et à résoudre leurs problèmes.",
    scores: {
      musicale: 0.1,
      intrapersonnel: 0.1,
      interpersonnel: 0.8,
      naturaliste: 0.1,
      spaciale: 0.1,
      kinesthésique: 0.1,
      linguistique: 0.1,
      logique: 0.1,
    },
  },
  {
    text: "Trouve de la satisfaction à contribuer à une atmosphère de coopération et de compréhension mutuelle.",
    scores: {
      musicale: 0.1,
      intrapersonnel: 0.1,
      interpersonnel: 0.9,
      naturaliste: 0.1,
      spaciale: 0.1,
      kinesthésique: 0.1,
      linguistique: 0.1,
      logique: 0.1,
    },
  },
  {
    text: "Est stimulé par les interactions sociales enrichissantes et les relations significatives avec autrui.",
    scores: {
      musicale: 0.1,
      intrapersonnel: 0.1,
      interpersonnel: 0.9,
      naturaliste: 0.1,
      spaciale: 0.1,
      kinesthésique: 0.1,
      linguistique: 0.1,
      logique: 0.1,
    },
  },
  // Ajouter d'autres phrases pertinentes pour l'intelligence interpersonnelle

  {
    text: "Est motivé par la passion pour explorer et protéger l'environnement naturel.",
    scores: {
      musicale: 0.1,
      intrapersonnel: 0.1,
      interpersonnel: 0.1,
      naturaliste: 0.9,
      spaciale: 0.1,
      kinesthésique: 0.1,
      linguistique: 0.1,
      logique: 0.1,
    },
  },
  {
    text: "Trouve de la satisfaction à étudier la diversité des espèces et des écosystèmes.",
    scores: {
      musicale: 0.1,
      intrapersonnel: 0.1,
      interpersonnel: 0.1,
      naturaliste: 0.8,
      spaciale: 0.1,
      kinesthésique: 0.1,
      linguistique: 0.1,
      logique: 0.1,
    },
  },
  {
    text: "Est stimulé par les défis liés à la conservation et à la durabilité environnementale.",
    scores: {
      musicale: 0.1,
      intrapersonnel: 0.1,
      interpersonnel: 0.1,
      naturaliste: 0.8,
      spaciale: 0.1,
      kinesthésique: 0.1,
      linguistique: 0.1,
      logique: 0.1,
    },
  },
  // Ajouter d'autres phrases pertinentes pour l'intelligence naturaliste

  {
    text: "Est motivé par la possibilité de créer des œuvres d'art visuellement captivantes et originales.",
    scores: {
      musicale: 0.1,
      intrapersonnel: 0.1,
      interpersonnel: 0.1,
      naturaliste: 0.1,
      spaciale: 0.9,
      kinesthésique: 0.1,
      linguistique: 0.1,
      logique: 0.1,
    },
  },
  {
    text: "Trouve de la satisfaction à utiliser des outils de design pour donner vie à des concepts imaginatifs.",
    scores: {
      musicale: 0.1,
      intrapersonnel: 0.1,
      interpersonnel: 0.1,
      naturaliste: 0.1,
      spaciale: 0.8,
      kinesthésique: 0.1,
      linguistique: 0.1,
      logique: 0.1,
    },
  },
  {
    text: "Est stimulé par les défis artistiques qui mettent à l'épreuve sa créativité et son sens esthétique.",
    scores: {
      musicale: 0.1,
      intrapersonnel: 0.1,
      interpersonnel: 0.1,
      naturaliste: 0.1,
      spaciale: 0.8,
      kinesthésique: 0.1,
      linguistique: 0.1,
      logique: 0.1,
    },
  },
  // Ajouter d'autres phrases pertinentes pour l'intelligence visuo-spatiale

  {
    text: "Est motivé par le plaisir du mouvement et de la maîtrise corporelle dans les activités physiques.",
    scores: {
      musicale: 0.1,
      intrapersonnel: 0.1,
      interpersonnel: 0.1,
      naturaliste: 0.1,
      spaciale: 0.1,
      kinesthésique: 0.9,
      linguistique: 0.1,
      logique: 0.1,
    },
  },
  {
    text: "Trouve de la satisfaction à améliorer ses compétences dans les arts du spectacle et les sports.",
    scores: {
      musicale: 0.1,
      intrapersonnel: 0.1,
      interpersonnel: 0.1,
      naturaliste: 0.1,
      spaciale: 0.1,
      kinesthésique: 0.8,
      linguistique: 0.1,
      logique: 0.1,
    },
  },
  {
    text: "Est stimulé par les défis qui exigent une coordination et une agilité corporelles exceptionnelles.",
    scores: {
      musicale: 0.1,
      intrapersonnel: 0.1,
      interpersonnel: 0.1,
      naturaliste: 0.1,
      spaciale: 0.1,
      kinesthésique: 0.9,
      linguistique: 0.1,
      logique: 0.1,
    },
  },
  // Ajouter d'autres phrases pertinentes pour l'intelligence corporelle-kinesthésique

  {
    text: "Est motivé par la possibilité de partager des idées et des connaissances à travers des mots et des discours puissants.",
    scores: {
      musicale: 0.1,
      intrapersonnel: 0.1,
      interpersonnel: 0.1,
      naturaliste: 0.1,
      spaciale: 0.1,
      kinesthésique: 0.1,
      linguistique: 0.9,
      logique: 0.1,
    },
  },
  {
    text: "Trouve de la satisfaction à écrire et à communiquer de manière persuasive et efficace.",
    scores: {
      musicale: 0.1,
      intrapersonnel: 0.1,
      interpersonnel: 0.1,
      naturaliste: 0.1,
      spaciale: 0.1,
      kinesthésique: 0.1,
      linguistique: 0.8,
      logique: 0.1,
    },
  },
  {
    text: "Est stimulé par les défis intellectuels qui exigent une réflexion critique et une analyse approfondie.",
    scores: {
      musicale: 0.1,
      intrapersonnel: 0.1,
      interpersonnel: 0.1,
      naturaliste: 0.1,
      spaciale: 0.1,
      kinesthésique: 0.1,
      linguistique: 0.9,
      logique: 0.1,
    },
  },
  // Ajouter d'autres phrases pertinentes pour l'intelligence verbale-linguistique

  {
    text: "Est motivé par les défis qui nécessitent une analyse logique approfondie et une résolution de problèmes structurée.",
    scores: {
      musicale: 0.1,
      intrapersonnel: 0.1,
      interpersonnel: 0.1,
      naturaliste: 0.1,
      spaciale: 0.1,
      kinesthésique: 0.1,
      linguistique: 0.1,
      logique: 0.9,
    },
  },
  {
    text: "Trouve de la satisfaction à comprendre et à appliquer des principes mathématiques et scientifiques complexes.",
    scores: {
      musicale: 0.1,
      intrapersonnel: 0.1,
      interpersonnel: 0.1,
      naturaliste: 0.1,
      spaciale: 0.1,
      kinesthésique: 0.1,
      linguistique: 0.1,
      logique: 0.9,
    },
  },
  {
    text: "Est stimulé par les problèmes difficiles qui exigent une pensée critique et une analyse rigoureuse.",
    scores: {
      musicale: 0.1,
      intrapersonnel: 0.1,
      interpersonnel: 0.1,
      naturaliste: 0.1,
      spaciale: 0.1,
      kinesthésique: 0.1,
      linguistique: 0.1,
      logique: 0.8,
    },
  },
  // Ajouter d'autres phrases pertinentes pour l'intelligence logique
];
