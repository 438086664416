import styled from "styled-components";
import imageBrain from "../../asset/imageBrain.svg";
import "../../App.css"
import "../../asset/fonts/grotasansaltrd-heavy.otf"


export const MiniBrain = styled.div`
  background-image: url("${imageBrain}");
  background-size: cover;
  height: 21vh;
  width: 37vh;
  background-repeat: no-repeat;
  padding: 10px;
  display: flex;
  justify-content: center;
`;


export const WrapperQRCode = styled.div`
display: flex;
flex-direction: column;
align-items: center;
font-size: 12px;
font-style: italic;
`;

export const UndoneSection = styled.div`
//font-family: GrotaSansAltRd;
font-style:italic;
background-color: #5ac5f157;
margin: 0 18px;
padding: 13px 13px;
border-radius: 10px;
display: flex;
box-shadow: 3px 2px 12px 3px #0000003b;
align-items: center;
justify-content: center;
text-align: center;
&:hover{
  cursor: pointer;
  box-shadow:2px 4px 12px 3px #0000003b;
}
transition : all 0.2s ease
`

export const PhoneChartSection = styled.div`
display: flex;

    flex-direction: row;
    width: 100%;
    flex-wrap: wrap;
`

export const SharePopUp = styled.div`

height: auto;
font-size:12px;
    padding: 5px;
    display: flex;
    margin: 10px 2px;
    background-color: #ffffff;
    border-radius: 5px;
    box-shadow: 0px 0px 10px #00000036;
    color: #232323;
    transition : all 0.5s ease-out;
    line-break: anywhere;
        flex-direction: column;
    align-items: center;
`

export const BigBrain = styled.div`
  background-image: url("${imageBrain}");
  background-size: cover;
  height: 78vh;
  width: 133vh;
  background-repeat: no-repeat;
  padding: 10px;
  display: flex;
  justify-content: center;
  position: absolute;
  z-index: -1;
  bottom: 0px;
  opacity: 0.1;
`;

export const WrapperPageDeskTop = styled.div`
  display: flex;
  width: 100%;
`;


export const WrapperPagePhone = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  padding: 0px 10px;
`;

export const WrapperRightSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 15px 0px 12px 20px;
`;

export const WrapperGroupTitle = styled.div`
  display: flex;
  width: 100%;
  color: #316880;
  font-weight: 600;
  font-size: 15px;
  align-items: baseline;
  white-space: nowrap;
  justify-content: space-between;
`;

export const WrapperHeader = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  padding: 0 0 10px 0;

  border-bottom: solid 0.5px #00000029;
`;

export const HeaderTitle = styled.div`
  display: flex;
  width: 100%;
  color: #316880;
  font-weight: 800;
  font-size: 35px;
  align-items: baseline;
  white-space: nowrap;
  margin: 0 4px 0 0;
`;
export const HeaderUpdate = styled.div`
  display: flex;
  width: 100%;
  font-style: italic;
  font-weight: 300;
  font-size: 12px;
  color: #5f5e5e;
  margin: 0 5px 0 5px;
`;
export const HeaderSwitchVisible = styled.div`
  display: flex;
  width: 90%;
  font-size: 12px;
  place-content: end;
`;
export const HeaderLinkButton = styled.div`
  display: flex;
  width: 90%;
  place-content: end;
  flex-direction: column;
  margin : 0 5px 0 10px;
`;

export const WrapperSectionAndTitle = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 10px 0 0 0;
`;

export const TitleSection = styled.div`
  display: flex;
  width: 100%;
  font-weight: 900;

  font-size: 45px;
//  font-family: GrotaSansAltRd;
  margin: 2px 0 0 0;
  color: #316880;
`;


export const TitleSectionPhone = styled.div`
  display: flex;
  width: 100%;
  font-weight: 900;

  font-size: 25px;
//  font-family: GrotaSansAltRd;
  margin: 2px 0 0 0;
  color: #316880;
`;

export const WrapperAllSection = styled.div`
  display: flex;
  width: 100%;
  margin: 7px 0;
`;
export const WrapperContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-y: auto;

  height: 85vh;

  ::-webkit-scrollbar {
    width: 7px;
    background: transparent;
    height: 100%;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background-color: #316880bd;
  }
`;

export const WrapperOneSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 50%;
  max-width: 100%;
  margin: 0 5px;
`;


export const TooltipText = styled.div`
display: flex;
visibility: hidden;
width: fit-content;
margin: 0px;
padding: 5px 17px;
font-size: 12px;
position: absolute;
height: fit-content;
text-transform: none;
Z-INDEX: 1;
top: -60px;
left: 0%;
background-color: #292a2b;
color: #fff;
font-weight: bold;
border-radius: 2px;
flex-direction: column;
    align-items: center;

`;

export const WrapperRectAndText = styled.div`
  display: flex;
  width: 100%;
  height: auto;
  word-break: break-all;
  align-content: center;
  position : relative; 
  
  margin: 4px 0;
  text-transform: uppercase;
  &:hover ${TooltipText} {
    transition : all 0.2s ease;
    visibility: visible;
    border-radius: 10px;
  }
`;


export const WrapperSection = styled.div`
  display: flex;
   height: auto;
  width: 100%;
  font-size: ${(props) => props.fontSize ?? "15px"};
  font-style: bold;
  font-weight: 500;
  text-transform: uppercase;
  word-break: break-word;
  &: hover{
    font-weight: 800;
  } 
`;



export const WrapperRectColor = styled.div`
  display: flex;
  width: 10px;
  background-color: ${(props) => props.color ?? "#5bc5f2"};
  height: auto;
  border-radius: 6px;
  margin: 0 3px 0 0;
`;



export const WrapperLeftSection = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 15px;
  margin-left: 5px;
  width: 60%;
`;

export const WrapperMiniBrain = styled.div`
  display: flex;
  place-content: end;
  width: 100%;
`;

export const SectionLeft = styled.div`
  display: flex;
  width: 100%;
  margin: 2px 0;
`;

export const WrapperContentPhone = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
    align-content: center;
    flex-direction: column;
`;


export const SectionChartPhone = styled.div`
  display: flex;
  width: 33%;
  margin: 2px 0;
  flex-direction: column;
    align-items: center;
`;

export const ChartDescptionPhone = styled.div`
  display: flex;
  scale:0.7;
`;

export const WrapperLegend = styled.div`
  display: flex;
  flex-direction: column;

  margin: 0 0 5px 0;
  width: 155px;
  height: 60px;

  background: #1d1d1b 0% 0%;

  align-items: center;
  justify-content: center;
  border-radius: 7px;
`;

export const TitleLegend = styled.div`
  display: flex;

  font-weight: 800;
  font-size: 20px;
  color: #fff;
  justify-content: center;
//  font-family: GrotaSansAltRd;
`;

export const SubTitleLegend = styled.div`
  display: flex;
  justify-content: center;

  font-weight: 600;
  color: #fff;
  font-size: 15px;
//  font-family: GrotaSansAltRd;
`;

export const PointLegend = styled.div`
  display: flex;
  height: 10px;
  width: 10px;
  border-radius: 10px;
  margin: 0 5px;
  background-color: ${(props) => props.color ?? "#5BC5F2"};
`;

export const PointAndDescription = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  margin: 5px 0 px;
  font-size: 14px;
`;

export const LegendText = styled.div`
  display: flex;
  justify-content: center;

  font-weight: 600;
  color: #316880;
  font-size: 15px;
//  font-family: GrotaSansAltRd;
`;


export const WrapperTooltip = styled.div`
font-size: 10px;
    FONT-WEIGHT: 300;
`;

export const WrapperTooltipTag = styled.div`
font-size: 10px;
FONT-WEIGHT: 800;
`;

export const WrapperTooltipLevel = styled.div`
display: flex;
`;


export const WrapperTooltipLevel1 = styled.div`
color : ${(props) => props.color ?? "#5bc5f2"};
margin: 0px 4px;
`;
export const WrapperTooltipLevel2 = styled.div`
color : ${(props) => props.color ?? "#5bc5f2"};
margin: 0px 4px;

`;