import crypto from "crypto-js";

let key =  "@Wk2SS3cree!"

export const decrypt = (item) =>{

    let itemDecrypted = item.replaceAll('20%==', '/')
    var bytes = crypto.AES.decrypt(itemDecrypted, key);
    var originalText = bytes.toString(crypto.enc.Utf8).split("/");

    return originalText


}