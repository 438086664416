import { StudentPage } from "../studentPage/StudentPage";
import { useParams } from "react-router-dom";
import { decrypt } from "../../utils/decrypt";
import { GET_USER_BY_ID } from "../../utils/graphql/user/query";
import { useQuery, useMutation } from "@apollo/client";
import { BigBrain } from "../studentPage/StudentPage.style";

export const SharedPage = ({ propsDevice, currentUser }) => {
  /* let { idUser, idGroup, idStudent } = useParams(); */

  let { idCrypted } = useParams();
  var originalText = decrypt(idCrypted)


  


  const idUser = parseInt(originalText[0]);
  const idGroup = parseInt(originalText[1]);
  const idStudent= parseInt(originalText[2]);
  const studentName= parseInt(originalText[3]);


  const { loading: loadingUser, data: dataUser } = useQuery(GET_USER_BY_ID, {
    variables: {
      getUserByIdId: idUser,
    },
  });


  const avaliable = dataUser?.getUserById?.profilVisible

  
  return (
    <> {avaliable === true ? <StudentPage
      propsDevice={propsDevice}
      currentUser={currentUser}
      isShared={true}
      sidGroup={idGroup}
      sidStudent={idStudent}
    /> : <div style={{display :"flex" ,     justifyContent: "flex-start",
      flexDirection: "column", alignItems: "center",}}> <BigBrain />  <img margin="0 5px 0 5px" width="110px" src="https://www.awayke.org/wp-content/uploads/2024/08/TESTSSFOND.png"/> Oups ! profil indisponible</div>}</>
  );
};
