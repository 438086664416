import {
    BackgroundDiv,
    InputsWrapper,
    BackgroundAlign,
    Title,
  } from "./NotFound.style";
  

 
  import { useLocation, useNavigate } from "react-router-dom";

  
  export const NotFound = ({ propsDevice }) => {

  
  
    
    let location = useLocation();
    let navigate = useNavigate();
  
  
  
    return (
      <BackgroundAlign>
        <BackgroundDiv>
          <InputsWrapper sizeWidth={propsDevice.device}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                alignItems: "center",
                position: "relative",
              }}
            >
              <Title sizeWidth={propsDevice.device}> {"404 NOT FOUND ! "} </Title>
              
  
               
            </div>
           
                 
            
          </InputsWrapper>
        </BackgroundDiv>
      </BackgroundAlign>
    );
  };
  