import { gql } from "@apollo/client";

export const GET_MODULE_BY_STUDENT_ID = gql`
  query GetModuleByUserId($studentId: Int!) {
    getModuleByUserId(student_id: $studentId) {
      resultValMot {
        id
        name
        e
        c
        util
        indiv
        s
        t
        comment
      }
      resultDisc {
        id
        name
        r
        y
        b
        g
        comment
      }
      resultIntMul {
        id
        name
        vl
        lm
        inter
        intra
        m
        k
        n
        s
        comment
      }
      student {
        name
        lastname
        school {
          name
        }
        email
        id
      }
    }
  }
`;
