import { createTheme } from "@mui/material/styles";
import {
  purple,
  blueGrey,
  orange,
  deepOrange,
  blue,
} from "@mui/material/colors";
import Rating from "@mui/material/Rating";
import { styled } from "@mui/material/styles";
import { Typography } from "@mui/material";

export const theme = createTheme({
  palette: {
    primary: {
      main: purple[500],
      darkText: blue[900],
      lightText: blueGrey[50],
      unfocusText: blueGrey[300],
      orangeText: "#E74E0F",
      errorText: deepOrange["A700"],
    },
  },
});

export const discColors = [
  { name: "Dominant", color: "#F61010", label: "r" },
  { name: "Influence", color: "#FCE200", label: "y" },
  { name: "Stabilité", color: "#37E600", label: "g" },
  { name: "Conformité", color: "#2B9DC6", label: "b" },
];



export const intellMultipleColors = [
  { name: "Verbo-Linguistique", color: "#5bc5f2",label:'vl' },
  { name: "Logico-Mathématique", color: " #6bade8" ,label:'lm'},
  { name: "Intrapersonnelle", color: "#7a95dd" ,label:'intra'},
  { name: "Interpersonnelle", color: "#897dd2" ,label:'inter'},
  { name: "Kinésthésique", color: "#9865c7" ,label:'k'},
  { name: "Musicale", color: " #a74dbd" ,label:'m'},
  { name: "Spaciale", color: "#b635b2" ,label:'s'},
  { name: "Naturaliste", color: "#d4049c" ,label:'n'},
];

export const motivationsColors = [
  { name: "Esthétique", color: "#a2f25c",label:'e' },
  { name: "Cognitive", color: "#b8c746" ,label:'c'},
  { name: "Individuelle", color: "#c3b13b" ,label:'indiv'},
  { name: "Utilitaire", color: "#ce9b2f" ,label:'util'},
  { name: "Traditionnelle", color: "#e47019" ,label:'t'},
  { name: "Sociale", color: "#fa4402" ,label:'s'},
];

export const selectDiscColor = (item, position) => {
  let newList = item.sort((a, b) => a.value - b.value).reverse();

  if (newList[position].label === "r") {
    return discColors.find((x) => x.name === "Dominant").color;
  }
  if (newList[position].label === "y") {
    return discColors.find((x) => x.name === "Influence").color;
  }
  if (newList[position].label === "g") {
    return discColors.find((x) => x.name === "Stabilité").color;
  }
  if (newList[position].label === "b") {
    return discColors.find((x) => x.name === "Conformité").color;
  }
};

export const selectDiscValue = (item, position) => {
  let newList = item.sort((a, b) => a.value - b.value).reverse();

  if (newList[position].label === "r") {
    return newList[position].value;
  }
  if (newList[position].label === "y") {
    return newList[position].value;
  }
  if (newList[position].label === "g") {
    return newList[position].value;
  }
  if (newList[position].label === "b") {
    return newList[position].value;
  }
};
export const selectIntellMultipleColor = (item, position) => {
  let newList = item.sort((a, b) => a.value - b.value).reverse();

  if (newList[position].label === "vl") {
    return intellMultipleColors.find((x) => x.name === "Verbo-Linguistique").color;
  }
  if (newList[position].label === "lm") {
    return intellMultipleColors.find((x) => x.name === "Logico-Mathématique").color;
  }
  if (newList[position].label === "inter") {
    return intellMultipleColors.find((x) => x.name === "Interpersonnelle").color;
  }
  if (newList[position].label === "intra") {
    return intellMultipleColors.find((x) => x.name === "Intrapersonnelle").color;
  }
  if (newList[position].label === "m") {
    return intellMultipleColors.find((x) => x.name === "Musicale").color;
  }
  if (newList[position].label === "k") {
    return intellMultipleColors.find((x) => x.name === "Kinésthésique").color;
  }
  if (newList[position].label === "n") {
    return intellMultipleColors.find((x) => x.name === "Naturaliste").color;
  }
  if (newList[position].label === "s") {
    return intellMultipleColors.find((x) => x.name === "Spaciale").color;
  }
};

export const selectIntellMultipleValue = (item, position) => {
  let newList = item.sort((a, b) => a.value - b.value).reverse();

  if (newList[position].label === "vl") {
    return newList[position].value;
  }
  if (newList[position].label === "lm") {
    return newList[position].value;
  }
  if (newList[position].label === "inter") {
    return newList[position].value;
  }
  if (newList[position].label === "intra") {
    return newList[position].value;
  }
  if (newList[position].label === "m") {
    return newList[position].value;
  }
  if (newList[position].label === "k") {
    return newList[position].value;
  }
  if (newList[position].label === "n") {
    return newList[position].value;
  }
  if (newList[position].label === "s") {
    return newList[position].value;
  }
};

export const selectMotivationColor = (item, position) => {
  let newList = item.sort((a, b) => a.value - b.value).reverse();

  if (newList[position].label === "e") {
    return motivationsColors.find((x) => x.name === "Esthétique").color;
  }
  if (newList[position].label === "c") {
    return motivationsColors.find((x) => x.name === "Cognitive").color;
  }
  if (newList[position].label === "util") {
    return motivationsColors.find((x) => x.name === "Utilitaire").color;
  }
  if (newList[position].label === "indiv") {
    return motivationsColors.find((x) => x.name === "Individuelle").color;
  }
  if (newList[position].label === "s") {
    return motivationsColors.find((x) => x.name === "Sociale").color;
  }
  if (newList[position].label === "t") {
    return motivationsColors.find((x) => x.name === "Traditionnelle").color;
  }
};

export const selectMotivationValue = (item, position) => {
  let newList = item.sort((a, b) => a.value - b.value).reverse();

  if (newList[position].label === "e") {
    return newList[position].value;
  }
  if (newList[position].label === "c") {
    return newList[position].value;
  }
  if (newList[position].label === "util") {
    return newList[position].value;
  }
  if (newList[position].label === "indiv") {
    return newList[position].value;
  }
  if (newList[position].label === "s") {
    return newList[position].value;
  }
  if (newList[position].label === "t") {
    return newList[position].value;
  }
};

export const formatDisc = (module) => {
  let disc = [];
  if (!module) {
    return disc;
  }
  for (const [key, value] of Object.entries(module?.resultDisc)) {
    if (key === "r" || key === "b" || key === "y" || key === "g") {
      const data = {
        label: key,
        value: value,
      };
      const dataBis = Object.assign({}, data);
      disc.push(dataBis);
    }
  }
  return disc;
};

export const formatValMot = (module) => {
  let valMot = [];
  if (!module) {
    return valMot;
  }
  for (const [key, value] of Object.entries(module?.resultValMot)) {
    if (
      key === "e" ||
      key === "c" ||
      key === "util" ||
      key === "indiv" ||
      key === "s" ||
      key === "t"
    ) {
      const data = {
        label: key,
        value: value,
      };
      const dataBis = Object.assign({}, data);
      valMot.push(dataBis);
    }
  }
  return valMot;
};

export const formatIntMul = (module) => {
  let intMul = [];
  if (!module) {
    return intMul;
  }
  for (const [key, value] of Object.entries(module?.resultIntMul)) {
    if (
      key === "vl" ||
      key === "lm" ||
      key === "inter" ||
      key === "intra" ||
      key === "m" ||
      key === "k" ||
      key === "n" ||
      key === "s"
    ) {
      const data = {
        label: key,
        value: value,
      };
      const dataBis = Object.assign({}, data);
      intMul.push(dataBis);
    }
  }
  return intMul;
};

export const motivationFirst = (item) => {
  let data = [];

  Object.entries(item).map((obj) => {
    if (obj[1] >= 80)
      switch (obj[0]) {
        case "c":
          data.push("Cognitif");
          break;
        case "e":
          data.push("Esthétique");
          break;
        case "util":
          data.push("Utilitaire");
          break;
        case "indiv":
          data.push("Individuel");
          break;
        case "s":
          data.push("Social");
          break;
        case "t":
          data.push("Traditionnel");
          break;

        default:
          break;
      }
  });

  return data;
};

export const motivationSecond = (item) => {
  let data = [];
  Object.entries(item).map((obj) => {
    if (obj[1] < 80 && obj[1] > 40)
      switch (obj[0]) {
        case "c":
          data.push("Cognitif");
          break;
        case "e":
          data.push("Esthétique");
          break;
        case "util":
          data.push("Utilitaire");
          break;
        case "indiv":
          data.push("Individuel");
          break;
        case "s":
          data.push("Social");
          break;
        case "t":
          data.push("Traditionnel");
          break;

        default:
          break;
      }
  });

  return data;
};

export const motivationLast = (item) => {
  let data = [];
  Object.entries(item).map((obj) => {
    if (obj[1] <= 40)
      switch (obj[0]) {
        case "c":
          data.push("Cognitif");
          break;
        case "e":
          data.push("Esthétique");
          break;
        case "util":
          data.push("Utilitaire");
          break;
        case "indiv":
          data.push("Individuel");
          break;
        case "s":
          data.push("Social");
          break;
        case "t":
          data.push("Traditionnel");
          break;

        default:
          break;
      }
  });

  return data;
};

export const discFirst = (item) => {
  let data = [];
  if (!item) {
    return data;
  }

  Object.entries(item).map((obj) => {
    if (obj[1] >= 80)
      switch (obj[0]) {
        case "r":
          data.push("Dominant");
          break;
        case "y":
          data.push("Influent");
          break;
        case "b":
          data.push("Conformité");
          break;
        case "g":
          data.push("Stabilité");
          break;

        default:
          break;
      }
  });

  return data;
};

export const discSecond = (item) => {
  let data = [];
  if (!item) {
    return data;
  }

  Object.entries(item).map((obj) => {
    if (obj[1] < 80 && obj[1] >= 40)
      switch (obj[0]) {
        case "r":
          data.push("Dominant");
          break;
        case "y":
          data.push("Influent");
          break;
        case "b":
          data.push("Conformité");
          break;
        case "g":
          data.push("Stabilité");
          break;

        default:
          break;
      }
  });

  return data;
};

export const discLast = (item) => {
  let data = [];
  if (!item) {
    return data;
  }
  Object.entries(item).map((obj) => {
    if (obj[1] < 40)
      switch (obj[0]) {
        case "r":
          data.push("Dominant");
          break;
        case "y":
          data.push("Influent");
          break;
        case "b":
          data.push("Conformité");
          break;
        case "g":
          data.push("Stabilité");
          break;

        default:
          break;
      }
  });
  return data;
};

export const intelligenceMulFirst = (item) => {
  let data = [];
  if (!item) {
    return data;
  }
  Object.entries(item).map((obj) => {
    if (obj[1] >= 80)
      switch (obj[0]) {
        case "vl":
          data.push("Verbo-Linguistique");
          break;
        case "lm":
          data.push("Logico-Mathématique");
          break;
        case "inter":
          data.push("Interpersonnelle");
          break;
        case "intra":
          data.push("Intrapersonelle");
          break;
        case "s":
          data.push("Spaciale");
          break;
        case "m":
          data.push("Musicale");
          break;
        case "k":
          data.push("Kynésthésique");
          break;
        case "n":
          data.push("Naturaliste");
          break;

        default:
          break;
      }
  });

  return data;
};

export const intelligenceMulSecond = (item) => {
  let data = [];
  if (!item) {
    return data;
  }

  Object.entries(item).map((obj) => {
    if (obj[1] < 80 && obj[1] > 40)
      switch (obj[0]) {
        case "vl":
          data.push("Verbo-Linguistique");
          break;
        case "lm":
          data.push("Logico-Mathématique");
          break;
        case "inter":
          data.push("Interpersonnelle");
          break;
        case "intra":
          data.push("Intrapersonelle");
          break;
        case "s":
          data.push("Spaciale");
          break;
        case "m":
          data.push("Musicale");
          break;
        case "k":
          data.push("Kynésthésique");
          break;
        case "n":
          data.push("Naturaliste");
          break;

        default:
          break;
      }
  });

  return data;
};

export const intelligenceMulLast = (item) => {
  let data = [];
  if (!item) {
    return data;
  }

  Object.entries(item).map((obj) => {
    if (obj[1] <= 40)
      switch (obj[0]) {
        case "vl":
          data.push("Verbo-Linguistique");
          break;
        case "lm":
          data.push("Logico-Mathématique");
          break;
        case "inter":
          data.push("Interpersonnelle");
          break;
        case "intra":
          data.push("Intrapersonelle");
          break;
        case "s":
          data.push("Spaciale");
          break;
        case "m":
          data.push("Musicale");
          break;
        case "k":
          data.push("Kynésthésique");
          break;
        case "n":
          data.push("Naturaliste");
          break;

        default:
          break;
      }
  });

  return data;
};

export const StyledRatingY = styled(Rating)({
  "& .MuiRating-iconFilled": {
    color: "#F2EC5B",
  },
  "& .MuiRating-iconHover": {
    color: "#f2ec5bad",
  },
});

export const StyledRatingR = styled(Rating)({
  "& .MuiRating-iconFilled": {
    color: "#F25B5B",
  },
  "& .MuiRating-iconHover": {
    color: "#f25b5b96",
  },
});

export const StyledRatingB = styled(Rating)({
  "& .MuiRating-iconFilled": {
    color: "#1CB9FF",
  },
  "& .MuiRating-iconHover": {
    color: "#1cb9ff8a",
  },
});

export const StyledRatingG = styled(Rating)({
  "& .MuiRating-iconFilled": {
    color: "#1CFF73",
  },
  "& .MuiRating-iconHover": {
    color: "#1cff7373",
  },
});

export const StyledRatingLM = styled(Rating)({
  "& .MuiRating-iconFilled": {
    color: "#6bade8",
  },
  "& .MuiRating-iconHover": {
    color: "#6bade8ad",
  },
});

export const StyledRatingVL = styled(Rating)({
  "& .MuiRating-iconFilled": {
    color: "#5bc5f2",
  },
  "& .MuiRating-iconHover": {
    color: "#5bc5f296",
  },
});

export const StyledRatingM = styled(Rating)({
  "& .MuiRating-iconFilled": {
    color: "#1CB9FF",
  },
  "& .MuiRating-iconHover": {
    color: "#1cb9ff8a",
  },
});

export const StyledRatingS = styled(Rating)({
  "& .MuiRating-iconFilled": {
    color: "#b635b2",
  },
  "& .MuiRating-iconHover": {
    color: "#b635b273",
  },
});

export const StyledRatingK = styled(Rating)({
  "& .MuiRating-iconFilled": {
    color: "#9865c7",
  },
  "& .MuiRating-iconHover": {
    color: "#9865c7ad",
  },
});

export const StyledRatingINTER = styled(Rating)({
  "& .MuiRating-iconFilled": {
    color: "#897dd2",
  },
  "& .MuiRating-iconHover": {
    color: "#897dd296",
  },
});

export const StyledRatingINTRA = styled(Rating)({
  "& .MuiRating-iconFilled": {
    color: "#7a95dd",
  },
  "& .MuiRating-iconHover": {
    color: "#7a95dd8a",
  },
});

export const StyledRatingN = styled(Rating)({
  "& .MuiRating-iconFilled": {
    color: "#d4049c",
  },
  "& .MuiRating-iconHover": {
    color: "#d4049c73",
  },
});

export const StyledRatingE = styled(Rating)({
  "& .MuiRating-iconFilled": {
    color: "#a2f25",
  },
  "& .MuiRating-iconHover": {
    color: "#a2f25ad",
  },
});

export const StyledRatingC = styled(Rating)({
  "& .MuiRating-iconFilled": {
    color: "#b8c746",
  },
  "& .MuiRating-iconHover": {
    color: "#b8c74696",
  },
});

export const StyledRatingI = styled(Rating)({
  "& .MuiRating-iconFilled": {
    color: "#c3b13b",
  },
  "& .MuiRating-iconHover": {
    color: "#c3b13b8a",
  },
});

export const StyledRatingU = styled(Rating)({
  "& .MuiRating-iconFilled": {
    color: "#ce9b2f",
  },
  "& .MuiRating-iconHover": {
    color: "#ce9b2f73",
  },
});

export const StyledRatingT = styled(Rating)({
  "& .MuiRating-iconFilled": {
    color: "#e47019",
  },
  "& .MuiRating-iconHover": {
    color: "#e47019ad",
  },
});

export const StyledRatingSO = styled(Rating)({
  "& .MuiRating-iconFilled": {
    color: "#fa4402",
  },
  "& .MuiRating-iconHover": {
    color: "#fa440296",
  },
});
