

  export const phrasesMotivationDISC = [
    { text: "Recherche des défis à relever.", scores: { dominant: 0.9, stabilité: 0.3, conformité: 0.3, influent: 0.6 } },
    { text: "Besoin de se sentir reconnu et apprécié.", scores: { dominant: 0.4, stabilité: 0.6, conformité: 0.3, influent: 0.8 } },
    { text: "Cherche à améliorer constamment ses compétences.", scores: { dominant: 0.7, stabilité: 0.5, conformité: 0.8, influent: 0.5 } },
    { text: "Motivé par le désir d'atteindre des objectifs ambitieux.", scores: { dominant: 0.9, stabilité: 0.4, conformité: 0.4, influent: 0.6 } },
    { text: "Trouve sa motivation dans la reconnaissance de ses pairs.", scores: { dominant: 0.5, stabilité: 0.5, conformité: 0.4, influent: 0.8 } },
    { text: "Cherche à se dépasser et à relever des défis constants.", scores: { dominant: 0.9, stabilité: 0.4, conformité: 0.3, influent: 0.6 } },
    { text: "Aime travailler dans des environnements dynamiques.", scores: { dominant: 0.7, stabilité: 0.4, conformité: 0.4, influent: 0.7 } },
    { text: "Motivé par le besoin d'autonomie.", scores: { dominant: 0.8, stabilité: 0.3, conformité: 0.4, influent: 0.5 } },
    { text: "Besoin de stabilité et de sécurité.", scores: { dominant: 0.4, stabilité: 0.8, conformité: 0.7, influent: 0.3 } },
    { text: "Recherche l'harmonie dans son environnement de travail.", scores: { dominant: 0.3, stabilité: 0.8, conformité: 0.6, influent: 0.4 } },
    { text: "Besoin d'un cadre structuré et organisé.", scores: { dominant: 0.4, stabilité: 0.7, conformité: 0.9, influent: 0.3 } },
    { text: "Cherche à maintenir des relations de travail harmonieuses.", scores: { dominant: 0.3, stabilité: 0.7, conformité: 0.6, influent: 0.5 } },
    { text: "Motivé par le respect des procédures.", scores: { dominant: 0.4, stabilité: 0.6, conformité: 0.9, influent: 0.3 } },
    { text: "Besoin de comprendre et d'analyser avant d'agir.", scores: { dominant: 0.3, stabilité: 0.6, conformité: 0.9, influent: 0.4 } },
    { text: "Recherche l'excellence dans ses actions.", scores: { dominant: 0.5, stabilité: 0.6, conformité: 0.9, influent: 0.4 } },
    { text: "Motivé par le désir d'aider les autres.", scores: { dominant: 0.4, stabilité: 0.8, conformité: 0.4, influent: 0.7 } },
    { text: "Besoin d'un environnement de travail coopératif.", scores: { dominant: 0.3, stabilité: 0.8, conformité: 0.5, influent: 0.6 } },
    { text: "Recherche des responsabilités croissantes.", scores: { dominant: 0.8, stabilité: 0.4, conformité: 0.4, influent: 0.6 } },
    { text: "Motivé par l'accomplissement de tâches complexes.", scores: { dominant: 0.7, stabilité: 0.5, conformité: 0.8, influent: 0.4 } },
    { text: "Aime résoudre des problèmes.", scores: { dominant: 0.6, stabilité: 0.5, conformité: 0.8, influent: 0.4 } },
    { text: "Cherche à influencer et persuader les autres.", scores: { dominant: 0.7, stabilité: 0.4, conformité: 0.4, influent: 0.9 } },
    { text: "Besoin de nouveauté et de variété.", scores: { dominant: 0.6, stabilité: 0.4, conformité: 0.3, influent: 0.8 } },
    { text: "Motivé par des environnements de travail flexibles.", scores: { dominant: 0.5, stabilité: 0.4, conformité: 0.4, influent: 0.7 } },
    { text: "Recherche l'innovation et la créativité.", scores: { dominant: 0.5, stabilité: 0.4, conformité: 0.4, influent: 0.8 } },
    { text: "Aime collaborer avec les autres.", scores: { dominant: 0.4, stabilité: 0.7, conformité: 0.5, influent: 0.8 } },
    { text: "Cherche à se dépasser continuellement.", scores: { dominant: 0.8, stabilité: 0.4, conformité: 0.4, influent: 0.6 } },
    { text: "Motivé par la recherche de résultats mesurables.", scores: { dominant: 0.7, stabilité: 0.5, conformité: 0.7, influent: 0.4 } },
    { text: "Aime partager ses connaissances.", scores: { dominant: 0.5, stabilité: 0.6, conformité: 0.5, influent: 0.8 } },
    { text: "Motivé par la recherche de solutions novatrices.", scores: { dominant: 0.6, stabilité: 0.4, conformité: 0.5, influent: 0.8 } },
    { text: "Cherche à atteindre des niveaux élevés de performance.", scores: { dominant: 0.8, stabilité: 0.5, conformité: 0.7, influent: 0.4 } },
  ];

  export const phrasesCommunicationDISC = [
    { text: "S'exprime de manière convaincante, captivant l'auditoire.", scores: { dominant: 0.8, influent: 0.7, stabilité: 0.3, conformité: 0.2 } },
    { text: "Partage ses visions de manière enthousiaste et optimiste.", scores: { dominant: 0.7, influent: 0.8, stabilité: 0.2, conformité: 0.1 } },
    { text: "Utilise un langage persuasif, démontrant une grande force.", scores: { dominant: 0.9, influent: 0.5, stabilité: 0.3, conformité: 0.1 } },
    { text: "Communique avec confiance, captivant son public.", scores: { dominant: 0.85, influent: 0.3, stabilité: 0.4, conformité: 0.1 } },
    { text: "Adopte un langage persuasif et convaincant.", scores: { dominant: 0.9, influent: 0.4, stabilité: 0.2, conformité: 0.1 } },
    { text: "S'exprime avec une grande assurance, faisant preuve de détermination dans la communication.", scores: { dominant: 0.85, influent: 0.2, stabilité: 0.4, conformité: 0.1 } },
    { text: "Communique de manière résolue et affirmée, démontrant une confiance soutenue dans ses décisions.", scores: { dominant: 0.9, influent: 0.1, stabilité: 0.5, conformité: 0.1 } },
    { text: "Communique de manière franche et décidée, exprimant ses idées avec conviction et énergie.", scores: { dominant: 0.95, influent: 0.1, stabilité: 0.6, conformité: 0.1 } },
    { text: "S'exprime avec dynamisme et charme, créant une atmosphère.", scores: { dominant: 0.2, influent: 0.7, stabilité: 0.1, conformité: 0.1 } },
    { text: "Crée une atmosphère engageante et positive.", scores: { dominant: 0.1, influent: 0.75, stabilité: 0.15, conformité: 0.1 } },
    { text: "Favorise une approche enthousiaste dans ses interactions.", scores: { dominant: 0.1, influent: 0.8, stabilité: 0.1, conformité: 0.1 } },
    { text: "Communique de manière directe et assurée, démontrant une confiance marquée dans ses expressions.", scores: { dominant: 0.1, influent: 0.8, stabilité: 0.1, conformité: 0.1 } },
    { text: "Communique de manière franche et décidée, exprimant ses idées avec conviction et énergie.", scores: { dominant: 0.1, influent: 0.9, stabilité: 0.1, conformité: 0.1 } },
    { text: "S'exprime de manière réfléchie, détaillée et précise.", scores: { dominant: 0.1, influent: 0.2, stabilité: 0.7, conformité: 0.1 } },
    { text: "Partage des informations avec un discours lent et précis.", scores: { dominant: 0.1, influent: 0.1, stabilité: 0.6, conformité: 0.3 } },
    { text: "Communique de manière formelle, détaillée et exacte.", scores: { dominant: 0.1, influent: 0.3, stabilité: 0.65, conformité: 0.05 } },
    { text: "S'exprime avec un ton calme, détaillé et réfléchi.", scores: { dominant: 0.1, influent: 0.1, stabilité: 0.7, conformité: 0.2 } },
    { text: "Privilégie une communication structurée et précise.", scores: { dominant: 0.1, influent: 0.1, stabilité: 0.75, conformité: 0.15 } },
    { text: "Favorise une communication axée sur les détails et la précision.", scores: { dominant: 0.1, influent: 0.1, stabilité: 0.3, conformité: 0.6 } },
    { text: "Communique avec rigueur, soulignant l'importance des détails.", scores: { dominant: 0.1, influent: 0.1, stabilité: 0.35, conformité: 0.55 } },
    { text: "Communique de manière formelle, mettant l'accent sur la précision.", scores: { dominant: 0.1, influent: 0.1, stabilité: 0.4, conformité: 0.5 } },
    { text: "S'exprime avec une grande précision et exactitude.", scores: { dominant: 0.1, influent: 0.1, stabilité: 0.45, conformité: 0.45 } },
    { text: "Est peu en interaction, reste à l'écart.", scores: { dominant: 0.4, influent: 0.1, stabilité: 0.8, conformité: 0.7 } },
    { text: "Ne montre pas ses émotions.", scores: { dominant: 0.2, influent: 0.1, stabilité: 0.9, conformité: 0.6 } },
    { text: "A un ton de voix chaleureux, parle doucement, avec un discours paisible.", scores: { dominant: 0.1, influent: 0.4, stabilité: 0.8, conformité: 0.1 } },
    { text: "A une poignée de main douce, amicale.", scores: { dominant: 0.1, influent: 0.4, stabilité: 0.8, conformité: 0.3 } },
    { text: "Ses mouvements sont lents, manifeste de la patience.", scores: { dominant: 0.1, influent: 0.1, stabilité: 0.8, conformité: 0.6 } },
    { text: "S'habille de manière neutre (afin de passer inaperçu).", scores: { dominant: 0.1, influent: 0.1, stabilité: 0.8, conformité: 0.9 } },
    { text: "Loyal envers ceux à qui il s'identifie.", scores: { dominant: 0.1, influent: 0.3, stabilité: 0.9, conformité: 0.7 } },
    { text: "Écoute bien.", scores: { dominant: 0.1, influent: 0.1, stabilité: 0.9, conformité: 0.9 } },
    { text: "Patient et compréhensif.", scores: { dominant: 0.1, influent: 0.1, stabilité: 0.9, conformité: 0.8 } },
  ];


  export const phrasesActionDISC  = [
    { text: "PRENDRE LA PAROLE", scores: { dominant: 0.8, stabilité: 0.3, conformité: 0.4, influent: 0.9 } },
    { text: "MAINTENIR LA BONNE ENTENTE DANS UNE ÉQUIPE", scores: { dominant: 0.4, stabilité: 0.8, conformité: 0.3, influent: 0.9 } },
    { text: "M'ADAPTER AUX CHANGEMENTS", scores: { dominant: 0.8, stabilité: 0.3, conformité: 0.5, influent: 0.8 } },
    { text: "M'INSPIRER ET ÊTRE CRÉATIF", scores: { dominant: 0.5, stabilité: 0.4, conformité: 0.2, influent: 0.8 } },
    { text: "PRENDRE DES DÉCISIONS", scores: { dominant: 0.9, stabilité: 0.3, conformité: 0.7, influent: 0.3 } },
    { text: "ME METTRE EN ACTION", scores: { dominant: 0.9, stabilité: 0.3, conformité: 0.5, influent: 0.8 } },
    { text: "GARDER L'OBJECTIF EN VUE", scores: { dominant: 0.9, stabilité: 0.4, conformité: 0.7, influent: 0.3 } },
    { text: "VOIR L'ASPECT POSITIF D'UNE SITUATION", scores: { dominant: 0.5, stabilité: 0.9, conformité: 0.3, influent: 0.9 } },
    { text: "Vision positive des situations.", scores: { dominant: 0.2, stabilité: 0.7, conformité: 0.3, influent: 0.8 } },
  { text: "Sait facilement partager ses idées avec son entourage.", scores: { dominant: 0.3, stabilité: 0.6, conformité: 0.4, influent: 0.9 } },
  { text: "Communique avec aisance.", scores: { dominant: 0.3, stabilité: 0.5, conformité: 0.4, influent: 0.9 } },
  { text: "Bon médiateur.rice.", scores: { dominant: 0.4, stabilité: 0.7, conformité: 0.6, influent: 0.8 } },
  { text: "Fait preuve de créativité.", scores: { dominant: 0.4, stabilité: 0.3, conformité: 0.5, influent: 0.8 } },
  { text: "S'inspire de son environnement.", scores: { dominant: 0.3, stabilité: 0.5, conformité: 0.6, influent: 0.7 } },
  { text: "Capacité à inspirer les autres.", scores: { dominant: 0.4, stabilité: 0.5, conformité: 0.4, influent: 0.9 } },
  { text: "S'illustre dans des situations sociales avec aisance.", scores: { dominant: 0.4, stabilité: 0.4, conformité: 0.4, influent: 0.9 } },
  { text: "Grande capacité d'inspiration dans les projets.", scores: { dominant: 0.5, stabilité: 0.4, conformité: 0.3, influent: 0.8 } },
  { text: "Favorise une approche ludique et créative.", scores: { dominant: 0.3, stabilité: 0.4, conformité: 0.4, influent: 0.8 } },
  { text: "Exerce un contrôle rigoureux sur chaque tâche.", scores: { dominant: 0.6, stabilité: 0.5, conformité: 0.9, influent: 0.4 } },
  { text: "Applique des critères élevés dans ses décisions et actions.", scores: { dominant: 0.6, stabilité: 0.5, conformité: 0.8, influent: 0.4 } },
  { text: "Maintient des normes élevées dans ses actions quotidiennes.", scores: { dominant: 0.5, stabilité: 0.6, conformité: 0.8, influent: 0.3 } },
  { text: "Développe des plans et des stratégies avec précision.", scores: { dominant: 0.5, stabilité: 0.6, conformité: 0.9, influent: 0.4 } },
  { text: "Se distingue par son excellente gestion des détails.", scores: { dominant: 0.4, stabilité: 0.7, conformité: 0.9, influent: 0.3 } },
  { text: "Exerce un contrôle strict sur chaque aspect de sa vie professionnelle.", scores: { dominant: 0.6, stabilité: 0.5, conformité: 0.9, influent: 0.4 } },
  { text: "Démontre une grande compétence dans l'analyse approfondie.", scores: { dominant: 0.4, stabilité: 0.7, conformité: 0.8, influent: 0.3 } },
  { text: "Applique une approche méthodique dans toutes ses actions.", scores: { dominant: 0.5, stabilité: 0.6, conformité: 0.9, influent: 0.4 } },
  { text: "Maintient un contrôle strict pour atteindre des résultats parfaits.", scores: { dominant: 0.5, stabilité: 0.5, conformité: 0.9, influent: 0.3 } },
  { text: "Exerce un contrôle précis et constant dans chaque domaine.", scores: { dominant: 0.5, stabilité: 0.5, conformité: 0.8, influent: 0.3 } },
  { text: "Indépendant, autonome.", scores: { dominant: 0.8, stabilité: 0.3, conformité: 0.3, influent: 0.4 } },
  { text: "Rapide, toujours en mouvement.", scores: { dominant: 0.8, stabilité: 0.2, conformité: 0.3, influent: 0.5 } },
  { text: "Énergique, dynamique, volontaire.", scores: { dominant: 0.8, stabilité: 0.3, conformité: 0.3, influent: 0.6 } },
  { text: "Sait prendre des risques.", scores: { dominant: 0.9, stabilité: 0.2, conformité: 0.2, influent: 0.5 } },
  { text: "Motivé à diriger et à relever des défis.", scores: { dominant: 0.9, stabilité: 0.3, conformité: 0.3, influent: 0.6 } },
  { text: "Décide facilement, va droit au but.", scores: { dominant: 0.8, stabilité: 0.3, conformité: 0.3, influent: 0.4 } },
  { text: "Exprime ses opinions ouvertement.", scores: { dominant: 0.7, stabilité: 0.4, conformité: 0.3, influent: 0.7 } },
  { text: "Exigeant avec lui-même comme avec autrui.", scores: { dominant: 0.7, stabilité: 0.4, conformité: 0.3, influent: 0.6 } },
  { text: "Reste concentré sur l'atteinte de l'objectif.", scores: { dominant: 0.8, stabilité: 0.3, conformité: 0.4, influent: 0.5 } },
  { text: "Tire les autres vers le haut.", scores: { dominant: 0.7, stabilité: 0.5, conformité: 0.3, influent: 0.6 } },
  { text: "Méthodique, constant, aime aller au bout d'une tâche.", scores: { dominant: 0.4, stabilité: 0.7, conformité: 0.8, influent: 0.3 } },
];