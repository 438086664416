import styled from "styled-components";
import ClassRoomIcon from "../../asset/ClassRoomIcon.svg";

export const RoundWrapper = styled.div`
  width: 145px;
  height: 145px;
  border-radius: 100%;
  background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 2px 2px 19px #00000029;
  opacity: 1;
  margin: 10px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: all 150ms ease;
  align-items: center;
  &:hover {
    transform: scale(1.1);
  }
`;

export const ClassIcon = styled.div`
  background-image: url("${ClassRoomIcon}");
  background-size: cover;
  height: 40%;
  width: 60%;
  background-repeat: no-repeat;
  padding: 10px;
  display: flex;
  align-self: center;
  justify-content: center;
`;

export const ClassWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 25px;
  height: fit-content;
  align-items: center;
`;
