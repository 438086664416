export const colors = [
  "#144153",
  "#ffdd00",
  "#5bc5f2",
  "#e74e0f",
  "#b2b2b2",
  "#05d328",
  "#ff9b04",
  "#d61c73",
  "#144153",
  "#ffdd00",
  "#5bc5f2",
  "#e74e0f",
  "#b2b2b2",
  "#05d328",
  "#ff9b04",
  "#d61c73",
];
