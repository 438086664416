import React from "react";
import { useLocation } from "react-router-dom";
import { useNavigate, Link } from "react-router-dom";
import { Login } from "../pages/login/Login";

export const PrivateRoute = ({ token,children}) => {
 /* const token = localStorage.getItem("token");  */
  const resetToken = localStorage.getItem("resetToken");
  const location = useLocation();
  const navigate = useNavigate();
/*   console.log("token:",token, "resetToken:", resetToken,"location.pathname:", location.pathname);
  console.log("condition !token && le pathname inclulogin ou signup :",!token && !["/login"].includes(location.pathname));
 */
 if (!token && !["/login"].includes(location.pathname)) {
     navigate("/login"); 
  } 
  return children;
};
