import { useMutation, useQuery } from "@apollo/client";
import { makeStyles } from "@material-ui/styles";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import GroupsIcon from "@mui/icons-material/Groups";
import HomeIcon from "@mui/icons-material/Home";
import PersonIcon from "@mui/icons-material/Person";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import LinearProgress from "@mui/material/LinearProgress";
import crypto from "crypto-js";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { GET_GROUP_BY_ID } from "../../utils/graphql/group/query";
import { GET_SCHOOL_BY_ID } from "../../utils/graphql/school/query";
import { StyledButton } from "../../utils/StyledButton.style";
import { StyledInput } from "../../utils/StyledInput.style";
import { theme } from "../../utils/theme";
import { SIGN_UP } from "./graphQl/mutation";
import {
  BackgroundAlign,
  BackgroundDiv,
  InputsWrapper,
  LoginButton,
  Title,TitleWaykeup 
} from "./SignUp.style";
import { decrypt } from "../../utils/decrypt";

const useStyles = makeStyles({
  input: {
    color: theme.palette.primary.lightText,
  },
});

export const SignUp = ({ propsDevice }) => {
  /*  let { idRole, idSchool, idGroup } = useParams(); */
  let { idCrypted } = useParams();
 var originalText = decrypt(idCrypted)
  /* const originalText= ['4', '1', '1']; */



  const idRole = parseInt(originalText[0]);
  const idSchool = parseInt(originalText[1]);
  const idGroup = parseInt(originalText[2]);

  let navigate = useNavigate();

  const {
    loading: loadingSchool,
    error: errorSchool,
    data: dataSchool,
  } = useQuery(GET_SCHOOL_BY_ID, {
    variables: {
      getSchoolByIdId: parseInt(originalText[1]),
    },
  });

  const {
    loading: loadingGroup,
    error: errorGroup,
    data: dataGroup,
  } = useQuery(GET_GROUP_BY_ID, {
    variables: {
      getGroupByIdId: parseInt(originalText[2]),
    },
  });


  const [values, setValues] = useState({
    name: "",
    lastname: "",
    email: "",
    password: "",
    showPassword: false,
    confirmPassword: "",
    showConfirmPassword: false,
    school_id: idSchool,
    roleId: idRole,
    groupId: idGroup,
    school: dataSchool?.getSchoolById?.name,
    group: dataGroup?.getGroupById?.name,
  });

  useEffect(() => {
    setValues({
      ...values,

      school: dataSchool?.getSchoolById?.name,
      group: dataGroup?.getGroupById?.name,
      school_id: idSchool,
      roleId: idRole,
      groupId: idGroup,
    });
  }, [dataSchool, dataGroup]);

  const [signup, { data, loading, error }] = useMutation(SIGN_UP);

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };
  const handleClickShowConfirmPassword = () => {
    setValues({
      ...values,
      showConfirmPassword: !values.showConfirmPassword,
    });
  };

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const classes = useStyles();

  
  return (
    <BackgroundAlign>
      <BackgroundDiv>
        <InputsWrapper sizeWidth={propsDevice.device}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              alignItems: "center",
              position: "relative",
              height: "100%",
            }}
          >
            <div style={{
              display: "flex",alignItems: "center", margin: "0px 10px"}}><div style={{
                display: "flex",alignItems: "center", margin: "0px 10px",fontSize: "20px"}}>🧭</div>< TitleWaykeup> WaykeUp by</TitleWaykeup></div> 
             <img margin="0 5px 0 5px" width="110px" src="https://www.awayke.org/wp-content/uploads/2024/08/TESTSSFOND.png"/>
            <Title sizeWidth={propsDevice.device}> CRÉER MON COMPTE </Title>
            <div style={{ margin: 1, width: "90%", alignItems: "stretch" }}>
              <div
                style={{
                  margin: "5px 0px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{
                    marginLeft: 10,
                    color: "#FFF",
                    fontSize: 12,
                    fontFamily: "Helvetica Neue",
                  }}
                >
                  {"ÉTABLISSEMENT"}
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    margin: "2px 0",
                  }}
                >
                  <HomeIcon
                    sx={{
                      margin: "0 10px",
                      alignSelf: "center",
                      color: "#000",
                    }}
                  />
                  <div
                    style={{
                      height: "17px",
                      marginLeft: "5px",
                      fontSize: "15px",
                      padding: "10px 15px",
                      backgroundColor: "#000",
                      alignContent: "center",
                      fontFamily: "Helvetica Neue",
                      width: "90%",
                      color: "#FFF",
                    }}
                  >
                    {loadingSchool === true ? (
                      <LinearProgress />
                    ) : (
                      values.school
                    )}
                  </div>
                </div>
              </div>
              <div
                style={{
                  margin: "5px 0px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{
                    marginLeft: 10,
                    color: "#FFF",
                    fontSize: 12,
                    fontFamily: "Helvetica Neue",
                  }}
                >
                  {"GROUPE"}
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <GroupsIcon
                    sx={{
                      margin: "0 10px",
                      alignSelf: "center",
                      color: "#000",
                    }}
                  />
                  <div
                    style={{
                      height: "17px",
                      marginLeft: "5px",
                      fontSize: "15px",
                      padding: "10px 15px",
                      backgroundColor: "#000",
                      alignContent: "center",
                      fontFamily: "Helvetica Neue",
                      width: "90%",
                      color: "#FFF",
                    }}
                  >
                    {loadingSchool === true ? <LinearProgress /> : values.group}
                  </div>
                </div>
              </div>
            </div>
            <FormControl
              sx={{ margin: 1, width: "90%", alignItems: "stretch" }}
              variant="filled"
            >
              <div
                style={{
                  margin: "5px 0px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{
                    marginLeft: 10,
                    color: "#FFF",
                    fontSize: 12,
                    fontFamily: "Helvetica Neue",
                  }}
                >
                  {"PRÉNOM"}
                </div>
                <div style={{ display: "flex" }}>
                  <PersonIcon sx={{ margin: "10px", alignSelf: "center" }} />
                  <StyledInput
                    id="standard-adornment-name"
                    type={"text"}
                    value={values.name}
                    placeholder="Tony"
                    onChange={handleChange("name")}
                  />
                </div>
              </div>
              <div
                style={{
                  margin: "5px 0px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{
                    marginLeft: 10,
                    color: "#FFF",
                    fontSize: 12,
                    fontFamily: "Helvetica Neue",
                  }}
                >
                  {"NOM"}
                </div>
                <div style={{ display: "flex" }}>
                  <PersonIcon sx={{ margin: "10px", alignSelf: "center" }} />
                  <StyledInput
                    id="standard-adornment-surname"
                    type={"text"}
                    value={values.lastname}
                    placeholder="Stark"
                    onChange={handleChange("lastname")}
                  />
                </div>
              </div>
              <div
                style={{
                  margin: "5px 0px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{
                    marginLeft: 10,
                    color: "#FFF",
                    fontSize: 12,
                    fontFamily: "Helvetica Neue",
                  }}
                >
                  {"EMAIL"}
                </div>
                <div style={{ display: "flex" }}>
                  <AlternateEmailIcon
                    sx={{ margin: "10px", alignSelf: "center" }}
                  />
                  <StyledInput
                    id="standard-adornment-email"
                    type={"text"}
                    value={values.email}
                    placeholder="tony.stark@avengers.com"
                    onChange={handleChange("email")}
                  />
                </div>
              </div>

              <div
                style={{
                  margin: "5px 0px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{
                    marginLeft: 10,
                    color: "#FFF",
                    fontSize: 12,
                    fontFamily: "Helvetica Neue",
                  }}
                >
                  {"MOT DE PASSE"}
                </div>
                <div style={{ display: "flex" }}>
                  <InputAdornment
                    position="end"
                    sx={{ color: "#fff", alignSelf: "center", margin: "5px" }}
                  >
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      size={"small"}
                      sx={{ color: "#fff", alignSelf: "center" }}
                    >
                      {values.showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                  <StyledInput
                    id="standard-adornment-password"
                    type={values.showPassword ? "text" : "password"}
                    value={values.password}
                    onChange={handleChange("password")}
                    placeholder="Mot de passe"
                  />
                </div>
              </div>
              <div
                style={{
                  margin: "5px 0px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{
                    marginLeft: 10,
                    color: "#FFF",
                    fontSize: 12,
                    fontFamily: "Helvetica Neue",
                  }}
                >
                  {"CONFIRMER LE MOT DE PASSE"}
                </div>
                <div style={{ display: "flex" }}>
                  <InputAdornment
                    position="end"
                    sx={{ color: "#fff", alignSelf: "center", margin: "5px" }}
                  >
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowConfirmPassword}
                      onMouseDown={handleMouseDownPassword}
                      size={"small"}
                      sx={{ color: "#fff", alignSelf: "center" }}
                    >
                      {values.showConfirmPassword ? (
                        <VisibilityOff />
                      ) : (
                        <Visibility />
                      )}
                    </IconButton>
                  </InputAdornment>
                  <StyledInput
                    id="standard-adornment-confirmPassword"
                    type={values.showConfirmPassword ? "text" : "password"}
                    value={values.confirmPassword}
                    onChange={handleChange("confirmPassword")}
                    placeholder="Confirmer mot de passe"
                  />
                </div>
              </div>
            </FormControl>
         

          <div style={{ display:"flex", flexDirection:"column", margin:"20px 0 0 0" }}>
            <StyledButton
              grota={true}
              colorText={theme.palette.primary.orangeText}
              onClick={async (e) => {
                e.preventDefault();
                await signup({
                  variables: {
                    signupInput: {
                      email: values.email,
                      name: values.name,
                      lastname: values.lastname,
                      password: values.password,
                      confirmPassword: values.confirmPassword,
                      roleId: parseInt(values.roleId),
                      groupId: parseInt(values.groupId),
                      school_id: parseInt(values.school_id),
                    },
                  },
                });
                navigate("/login");
                if (data) {
                
                }
                if (loading) {
                 
                }
                if (error) {
                 
                }
              }}
            >
              {"C'est parti !"}
            </StyledButton>
           
            <LoginButton onClick={ () => {navigate("/login");} }>J'ai déjà un compte, me connecter ! </LoginButton>
          </div>
          </div>
          {/*  </div> */}
        </InputsWrapper>
      </BackgroundDiv>
    </BackgroundAlign>
  );
};
