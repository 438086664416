import { gql } from "@apollo/client";

export const GET_SCHOOL_BY_ID = gql`
  query GetSchoolById($getSchoolByIdId: ID!) {
    getSchoolById(id: $getSchoolByIdId) {
      name
    }
  }
`;

export const GET_SCHOOLS = gql`
  query GetSchools {
    getSchools {
      id
     name
      Group {
        name
        id
      }
      User {
        id
        name
      }
    }
  }
`;
