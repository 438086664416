// AuthContext.js
import { createContext, useState, useContext } from "react";
import { setContext } from "@apollo/client/link/context";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [expireTime, setExpireTime] = useState(
    localStorage.getItem("expireTime") || Date.now() + 3600000
  );

  

  const updateExpireTime = (newTime) => {
    setExpireTime(newTime);
  };

  return (
    <AuthContext.Provider value={{ expireTime, updateExpireTime }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};