export const phrasesActionValMot = [
  {
    text: "Trouve satisfaction dans la maîtrise de compétences qui renforcent leur autonomie et leur influence.",
    scores: {
      cognitive: 0.5,
      esthetique: 0.4,
      individuelle: 0.9,
      sociale: 0.1,
      utilitaire: 0.4,
      traditionnelle: 0.2,
    },
  },
  {
    text: "Utilise des méthodes systématiques pour résoudre des problèmes complexes.",
    scores: {
      cognitive: 0.8,
      esthetique: 0.2,
      individuelle: 0.5,
      sociale: 0.1,
      utilitaire: 0.6,
      traditionnelle: 0.1,
    },
  },
  {
    text: "S'engage activement dans des actions visant à aider et à soutenir les autres.",
    scores: {
      cognitive: 0.1,
      esthetique: 0.4,
      individuelle: 0.3,
      sociale: 0.9,
      utilitaire: 0.2,
      traditionnelle: 0.5,
    },
  },
  {
    text: "Investit leurs ressources pour obtenir un retour sur investissement mesurable.",
    scores: {
      cognitive: 0.4,
      esthetique: 0.2,
      individuelle: 0.1,
      sociale: 0.3,
      utilitaire: 0.8,
      traditionnelle: 0.2,
    },
  },
  {
    text: "S'engage dans des activités créatives pour exprimer leur vision esthétique.",
    scores: {
      cognitive: 0.2,
      esthetique: 0.8,
      individuelle: 0.3,
      sociale: 0.1,
      utilitaire: 0.3,
      traditionnelle: 0.1,
    },
  },
  {
    text: "Poursuit des objectifs personnels en utilisant des stratégies tactiques pour atteindre leurs aspirations.",
    scores: {
      cognitive: 0.5,
      esthetique: 0.1,
      individuelle: 0.8,
      sociale: 0.1,
      utilitaire: 0.6,
      traditionnelle: 0.1,
    },
  },
  {
    text: "Dirige et guident les autres dans la réalisation d'objectifs communs.",
    scores: {
      cognitive: 0.3,
      esthetique: 0.5,
      individuelle: 0.6,
      sociale: 0.7,
      utilitaire: 0.3,
      traditionnelle: 0.1,
    },
  },
  {
    text: "Cherche constamment à améliorer leurs compétences pour rester compétitifs dans leur domaine.",
    scores: {
      cognitive: 0.5,
      esthetique: 0.1,
      individuelle: 0.7,
      sociale: 0.1,
      utilitaire: 0.4,
      traditionnelle: 0.1,
    },
  },
  {
    text: "Utilise leur influence pour apporter des changements positifs dans leur environnement.",
    scores: {
      cognitive: 0.2,
      esthetique: 0.5,
      individuelle: 0.7,
      sociale: 0.7,
      utilitaire: 0.3,
      traditionnelle: 0.1,
    },
  },
  {
    text: "S'efforce de créer un équilibre entre leur vie personnelle et professionnelle pour maintenir leur bien-être.",
    scores: {
      cognitive: 0.3,
      esthetique: 0.9,
      individuelle: 0.5,
      sociale: 0.1,
      utilitaire: 0.7,
      traditionnelle: 0.4,
    },
  },
  {
    text: "Adopte une approche analytique pour évaluer et résoudre les défis auxquels ils Est confrontés.",
    scores: {
      cognitive: 0.9,
      esthetique: 0.3,
      individuelle: 0.3,
      sociale: 0.1,
      utilitaire: 0.6,
      traditionnelle: 0.3,
    },
  },
  {
    text: "Encourage l'innovation et la créativité dans leurs projets pour atteindre des résultats exceptionnels.",
    scores: {
      cognitive: 0.4,
      esthetique: 0.5,
      individuelle: 0.4,
      sociale: 0.1,
      utilitaire: 0.4,
      traditionnelle: 0.1,
    },
  },
  {
    text: "Préfère travailler de manière autonome pour pouvoir influencer directement les résultats de leurs efforts.",
    scores: {
      cognitive: 0.2,
      esthetique: 0.1,
      individuelle: 0.8,
      sociale: 0.1,
      utilitaire: 0.5,
      traditionnelle: 0.1,
    },
  },
  {
    text: "Adopte une approche méthodique et organisée dans la gestion de leurs tâches quotidiennes.",
    scores: {
      cognitive: 0.7,
      esthetique: 0.6,
      individuelle: 0.4,
      sociale: 0.1,
      utilitaire: 0.7,
      traditionnelle: 0.5,
    },
  },
  {
    text: "Recherche des opportunités de leadership pour influencer positivement leur environnement de travail.",
    scores: {
      cognitive: 0.2,
      esthetique: 0.1,
      individuelle: 0.4,
      sociale: 0.5,
      utilitaire: 0.3,
      traditionnelle: 0.1,
    },
  },
  {
    text: "S'engage dans des actions rituelles ou traditionnelles pour renforcer les valeurs culturelles et historiques.",
    scores: {
      cognitive: 0.1,
      esthetique: 0.3,
      individuelle: 0.3,
      sociale: 0.1,
      utilitaire: 0.1,
      traditionnelle: 0.8,
    },
  },
  {
    text: "Adhère strictement aux principes moraux et éthiques établis dans tous les aspects de la vie professionnelle et personnelle.",
    scores: {
      cognitive: 0.1,
      esthetique: 0.4,
      individuelle: 0.2,
      sociale: 0.1,
      utilitaire: 0.1,
      traditionnelle: 0.9,
    },
  },
  {
    text: "Participe activement à des événements et des activités traditionnelles pour maintenir et transmettre les coutumes ancestrales.",
    scores: {
      cognitive: 0.1,
      esthetique: 0.1,
      individuelle: 0.3,
      sociale: 0.1,
      utilitaire: 0.1,
      traditionnelle: 0.8,
    },
  },
  {
    text: "Valorise les pratiques et les croyances traditionnelles contre les influences externes.",
    scores: {
      cognitive: 0.1,
      esthetique: 0.1,
      individuelle: 0.2,
      sociale: 0.1,
      utilitaire: 0.1,
      traditionnelle: 0.9,
    },
  },
  {
    text: "Valorise les valeurs familiales et de communauté et veille au maintenant des normes et des pratiques communes.",
    scores: {
      cognitive: 0.1,
      esthetique: 0.5,
      individuelle: 0.2,
      sociale: 0.6,
      utilitaire: 0.1,
      traditionnelle: 0.9,
    },
  },
  {
    text: "Se concentre sur des actions et des projets qui apportent un retour sur investissement clair et mesurable.",
    scores: {
      cognitive: 0.6,
      esthetique: 0.1,
      individuelle: 0.1,
      sociale: 0.1,
      utilitaire: 0.9,
      traditionnelle: 0.1,
    },
  },
  {
    text: "Planifie et met en œuvre des initiatives axées sur l'efficacité et la productivité pour maximiser les résultats.",
    scores: {
      cognitive: 0.6,
      esthetique: 0.1,
      individuelle: 0.1,
      sociale: 0.1,
      utilitaire: 0.9,
      traditionnelle: 0.1,
    },
  },
  {
    text: "Investit du temps et des ressources dans des activités qui offrent des bénéfices tangibles et pratiques à court et à long terme.",
    scores: {
      cognitive: 0.6,
      esthetique: 0.3,
      individuelle: 0.1,
      sociale: 0.1,
      utilitaire: 0.9,
      traditionnelle: 0.1,
    },
  },
  {
    text: "Met en place des processus et des systèmes optimisés pour maximiser l'efficacité opérationnelle et minimiser les gaspillages.",
    scores: {
      cognitive: 0.65,
      esthetique: 0.1,
      individuelle: 0.1,
      sociale: 0.1,
      utilitaire: 0.9,
      traditionnelle: 0.1,
    },
  },
  {
    text: "Engage des actions qui visent à maximiser les rendements et à minimiser les coûts pour atteindre des objectifs économiques spécifiques.",
    scores: {
      cognitive: 0.6,
      esthetique: 0.2,
      individuelle: 0.5,
      sociale: 0.1,
      utilitaire: 0.9,
      traditionnelle: 0.1,
    },
  },
  {
    text: "Poursuit activement des objectifs personnels ambitieux pour atteindre une croissance et un développement personnels continus.",
    scores: {
      cognitive: 0.3,
      esthetique: 0.3,
      individuelle: 0.9,
      sociale: 0.1,
      utilitaire: 0.6,
      traditionnelle: 0.1,
    },
  },
  {
    text: "Entreprend des actions indépendantes et autonomes pour exprimer et réaliser ses propres aspirations et valeurs.",
    scores: {
      cognitive: 0.3,
      esthetique: 0.7,
      individuelle: 0.9,
      sociale: 0.1,
      utilitaire: 0.1,
      traditionnelle: 0.1,
    },
  },
  {
    text: "Explore activement des opportunités qui favorisent l'auto-expression et l'accomplissement personnel.",
    scores: {
      cognitive: 0.6,
      esthetique: 0.8,
      individuelle: 0.9,
      sociale: 0.1,
      utilitaire: 0.1,
      traditionnelle: 0.5,
    },
  },
  {
    text: "Engage des actions axées sur le renforcement de la confiance en soi et la réalisation de soi à travers diverses expériences.",
    scores: {
      cognitive: 0.4,
      esthetique: 0.4,
      individuelle: 0.9,
      sociale: 0.1,
      utilitaire: 0.3,
      traditionnelle: 0.5,
    },
  },
  {
    text: "Agit de manière proactive pour atteindre ses propres objectifs personnels et professionnels avec détermination et persévérance.",
    scores: {
      cognitive: 0.4,
      esthetique: 0.1,
      individuelle: 0.9,
      sociale: 0.1,
      utilitaire: 0.7,
      traditionnelle: 0.1,
    },
  },
];



export const phrasesCommunicationValMot = [
  {
    text: "Communique en utilisant des valeurs et des principes traditionnels pour guider les interactions et les décisions.",
    scores: {
      cognitive: 0.1,
      esthetique: 0.1,
      individuelle: 0.2,
      sociale: 0.1,
      utilitaire: 0.1,
      traditionnelle: 0.7,
    },
  },
  {
    text: "Favorise un dialogue ouvert et honnête pour résoudre les différends de manière constructive.",
    scores: {
      cognitive: 0.6,
      esthetique: 0.7,
      individuelle: 0.1,
      sociale: 0.5,
      utilitaire: 0.2,
      traditionnelle: 0.4,
    },
  },
  {
    text: "Transmet les enseignements ancestraux à travers des récits et des anecdotes pour préserver la culture.",
    scores: {
      cognitive: 0.1,
      esthetique: 0.1,
      individuelle: 0.3,
      sociale: 0.1,
      utilitaire: 0.1,
      traditionnelle: 0.7,
    },
  },
  {
    text: "Utilise un langage respectueux et formel pour renforcer les normes sociales établies.",
    scores: {
      cognitive: 0.1,
      esthetique: 0.1,
      individuelle: 0.2,
      sociale: 0.1,
      utilitaire: 0.1,
      traditionnelle: 0.8,
    },
  },
  {
    text: "Participe à des cérémonies et rituels pour renforcer le sentiment d'appartenance à une communauté traditionnelle.",
    scores: {
      cognitive: 0.1,
      esthetique: 0.1,
      individuelle: 0.2,
      sociale: 0.1,
      utilitaire: 0.1,
      traditionnelle: 0.8,
    },
  },
  {
    text: "Communique en utilisant des symboles et des coutumes culturelles pour transmettre des messages profonds.",
    scores: {
      cognitive: 0.1,
      esthetique: 0.1,
      individuelle: 0.2,
      sociale: 0.1,
      utilitaire: 0.1,
      traditionnelle: 0.8,
    },
  },
  {
    text: "Privilégie les faits et les données objectives dans les discussions et les présentations.",
    scores: {
      cognitive: 0.7,
      esthetique: 0.1,
      individuelle: 0.2,
      sociale: 0.1,
      utilitaire: 0.1,
      traditionnelle: 0.1,
    },
  },
  {
    text: "Centré sur l'expérience subjective et la beauté.",
    scores: {
      cognitive: 0.1,
      esthetique: 0.65,
      individuelle: 0.2,
      sociale: 0.1,
      utilitaire: 0.1,
      traditionnelle: 0.1,
    },
  },
  {
    text: "Focalisé sur la réduction des conflits et la maximisation du potentiel humain.",
    scores: {
      cognitive: 0.1,
      esthetique: 0.5,
      individuelle: 0.1,
      sociale: 0.8,
      utilitaire: 0.5,
      traditionnelle: 0.1,
    },
  },
  {
    text: "Est centrée sur l’expérience de soi et d’autrui.",
    scores: {
      cognitive: 0.1,
      esthetique: 0.7,
      individuelle: 0.5,
      sociale: 0.4,
      utilitaire: 0.1,
      traditionnelle: 0.1,
    },
  },
  {
    text: "Met l'accent sur le retour sur investissement et l'efficacité des actions entreprises.",
    scores: {
      cognitive: 0.2,
      esthetique: 0.1,
      individuelle: 0.1,
      sociale: 0.1,
      utilitaire: 0.7,
      traditionnelle: 0.1,
    },
  },
  {
    text: "Communique de manière empathique pour favoriser la compréhension et le soutien mutuel.",
    scores: {
      cognitive: 0.1,
      esthetique: 0.2,
      individuelle: 0.3,
      sociale: 0.65,
      utilitaire: 0.1,
      traditionnelle: 0.1,
    },
  },
  {
    text: "Utilise des arguments rationnels et des données factuelles pour soutenir ses points de vue.",
    scores: {
      cognitive: 0.65,
      esthetique: 0.1,
      individuelle: 0.2,
      sociale: 0.1,
      utilitaire: 0.2,
      traditionnelle: 0.1,
    },
  },
  {
    text: "Partage des histoires et des expériences personnelles pour inspirer et motiver les autres.",
    scores: {
      cognitive: 0.2,
      esthetique: 0.4,
      individuelle: 0.4,
      sociale: 0.65,
      utilitaire: 0.1,
      traditionnelle: 0.1,
    },
  },
  {
    text: "Encourage la collaboration et l'ouverture d'esprit lors des discussions et des prises de décision.",
    scores: {
      cognitive: 0.3,
      esthetique: 0.5,
      individuelle: 0.2,
      sociale: 0.65,
      utilitaire: 0.1,
      traditionnelle: 0.1,
    },
  },
  {
    text: "Utilise des métaphores et des analogies pour rendre les concepts complexes accessibles à tous.",
    scores: {
      cognitive: 0.4,
      esthetique: 0.3,
      individuelle: 0.2,
      sociale: 0.1,
      utilitaire: 0.2,
      traditionnelle: 0.1,
    },
  },
  {
    text: "S'exprime de manière claire et concise pour éviter les malentendus et les interprétations erronées.",
    scores: {
      cognitive: 0.5,
      esthetique: 0.1,
      individuelle: 0.1,
      sociale: 0.1,
      utilitaire: 0.2,
      traditionnelle: 0.1,
    },
  },
  {
    text: "Utilise des techniques de persuasion éthiques pour influencer positivement les opinions et les comportements.",
    scores: {
      cognitive: 0.3,
      esthetique: 0.4,
      individuelle: 0.1,
      sociale: 0.5,
      utilitaire: 0.2,
      traditionnelle: 0.7,
    },
  },
  {
    text: "Utilise des outils technologiques avancés pour faciliter la communication à distance et en temps réel.",
    scores: {
      cognitive: 0.7,
      esthetique: 0.4,
      individuelle: 0.4,
      sociale: 0.1,
      utilitaire: 0.8,
      traditionnelle: 0.1,
    },
  },
  {
    text: "Participe activement à des forums et des groupes de discussion pour partager des idées et des informations pertinentes.",
    scores: {
      cognitive: 0.6,
      esthetique: 0.2,
      individuelle: 0.5,
      sociale: 0.7,
      utilitaire: 0.3,
      traditionnelle: 0.1,
    },
  },
  {
    text: "Utilise des données et des statistiques pour justifier les décisions et persuader les autres de l'efficacité des solutions proposées.",
    scores: {
      cognitive: 0.7,
      esthetique: 0.1,
      individuelle: 0.1,
      sociale: 0.1,
      utilitaire: 0.8,
      traditionnelle: 0.1,
    },
  },
  {
    text: "Communique les avantages tangibles et les retours sur investissement des actions proposées.",
    scores: {
      cognitive: 0.6,
      esthetique: 0.1,
      individuelle: 0.1,
      sociale: 0.1,
      utilitaire: 0.8,
      traditionnelle: 0.1,
    },
  },
  {
    text: "Utilise des arguments pragmatiques pour convaincre de l'efficacité des processus et des méthodes.",
    scores: {
      cognitive: 0.6,
      esthetique: 0.1,
      individuelle: 0.1,
      sociale: 0.1,
      utilitaire: 0.8,
      traditionnelle: 0.1,
    },
  },
  {
    text: "Communique de manière succincte et efficace pour maximiser la productivité et minimiser les pertes de temps.",
    scores: {
      cognitive: 0.6,
      esthetique: 0.1,
      individuelle: 0.1,
      sociale: 0.1,
      utilitaire: 0.9,
      traditionnelle: 0.1,
    },
  },
  {
    text: "Présente des stratégies axées sur les résultats concrets et mesurables pour motiver l'adhésion.",
    scores: {
      cognitive: 0.6,
      esthetique: 0.1,
      individuelle: 0.1,
      sociale: 0.1,
      utilitaire: 0.9,
      traditionnelle: 0.1,
    },
  },
  {
    text: "S'exprime de manière à mettre en valeur les aspirations personnelles et les objectifs de croissance individuelle.",
    scores: {
      cognitive: 0.3,
      esthetique: 0.1,
      individuelle: 0.9,
      sociale: 0.1,
      utilitaire: 0.1,
      traditionnelle: 0.1,
    },
  },
  {
    text: "Communique les réussites personnelles et les progrès réalisés pour inspirer les autres.",
    scores: {
      cognitive: 0.3,
      esthetique: 0.1,
      individuelle: 0.8,
      sociale: 0.1,
      utilitaire: 0.1,
      traditionnelle: 0.1,
    },
  },
  {
    text: "Utilise des récits personnels et des expériences personnelles pour illustrer des points et engager émotionnellement.",
    scores: {
      cognitive: 0.4,
      esthetique: 0.1,
      individuelle: 0.8,
      sociale: 0.1,
      utilitaire: 0.1,
      traditionnelle: 0.1,
    },
  },
  {
    text: "Adapte le style de communication en fonction des préférences individuelles pour favoriser une meilleure compréhension.",
    scores: {
      cognitive: 0.4,
      esthetique: 0.1,
      individuelle: 0.9,
      sociale: 0.1,
      utilitaire: 0.1,
      traditionnelle: 0.1,
    },
  },
  {
    text: "Encourage l'auto-expression et l'affirmation de soi à travers une communication ouverte et bienveillante.",
    scores: {
      cognitive: 0.4,
      esthetique: 0.1,
      individuelle: 0.9,
      sociale: 0.1,
      utilitaire: 0.1,
      traditionnelle: 0.1,
    },
  },
];

export const phrasesMotivationValMot = [
  {
    text: "Trouve satisfaction dans l'exploration et l'appréciation de la beauté et de la forme.",
    scores: {
      cognitive: 0.1,
      esthetique: 0.8,
      individuelle: 0.3,
      sociale: 0.1,
      utilitaire: 0.2,
      traditionnelle: 0.1,
    },
  },
  {
    text: "Est motivé par la résolution de problèmes et l'élucidation de mystères.",
    scores: {
      cognitive: 0.9,
      esthetique: 0.1,
      individuelle: 0.2,
      sociale: 0.1,
      utilitaire: 0.1,
      traditionnelle: 0.1,
    },
  },
  {
    text: "Est motivé par l'acquisition de compétences qui leur permettent de progresser dans leur carrière.",
    scores: {
      cognitive: 0.3,
      esthetique: 0.1,
      individuelle: 0.7,
      sociale: 0.1,
      utilitaire: 0.2,
      traditionnelle: 0.1,
    },
  },
  {
    text: "Est motivé par la possibilité d'améliorer la société et d'aider les individus à atteindre leur potentiel.",
    scores: {
      cognitive: 0.1,
      esthetique: 0.1,
      individuelle: 0.2,
      sociale: 0.9,
      utilitaire: 0.1,
      traditionnelle: 0.1,
    },
  },
  {
    text: "Est motivé par l'application pratique des connaissances pour résoudre des problèmes concrets.",
    scores: {
      cognitive: 0.4,
      esthetique: 0.1,
      individuelle: 0.1,
      sociale: 0.1,
      utilitaire: 0.6,
      traditionnelle: 0.1,
    },
  },
  {
    text: "Cherche à développer une compréhension profonde et analytique du monde qui les entoure.",
    scores: {
      cognitive: 0.8,
      esthetique: 0.1,
      individuelle: 0.3,
      sociale: 0.1,
      utilitaire: 0.1,
      traditionnelle: 0.1,
    },
  },
  {
    text: "Est motivé par la recherche de nouvelles expériences et de sensations fortes.",
    scores: {
      cognitive: 0.1,
      esthetique: 0.2,
      individuelle: 0.6,
      sociale: 0.1,
      utilitaire: 0.1,
      traditionnelle: 0.1,
    },
  },
  {
    text: "Est motivé par le désir de maintenir l'ordre et la stabilité dans leur environnement.",
    scores: {
      cognitive: 0.1,
      esthetique: 0.1,
      individuelle: 0.1,
      sociale: 0.1,
      utilitaire: 0.8,
      traditionnelle: 0.1,
    },
  },
  {
    text: "Est motivé par la quête de vérité et la découverte de nouvelles perspectives.",
    scores: {
      cognitive: 0.7,
      esthetique: 0.1,
      individuelle: 0.4,
      sociale: 0.1,
      utilitaire: 0.1,
      traditionnelle: 0.1,
    },
  },
  {
    text: "Est motivé par le désir de protéger et de préserver l'environnement naturel.",
    scores: {
      cognitive: 0.1,
      esthetique: 0.1,
      individuelle: 0.1,
      sociale: 0.6,
      utilitaire: 0.2,
      traditionnelle: 0.1,
    },
  },
  {
    text: "Est motivé par le besoin de contrôle et de prévisibilité dans leur vie quotidienne.",
    scores: {
      cognitive: 0.1,
      esthetique: 0.1,
      individuelle: 0.1,
      sociale: 0.1,
      utilitaire: 0.7,
      traditionnelle: 0.1,
    },
  },
  {
    text: "Est motivé par le désir d'établir des relations significatives et enrichissantes avec les autres.",
    scores: {
      cognitive: 0.1,
      esthetique: 0.1,
      individuelle: 0.3,
      sociale: 0.7,
      utilitaire: 0.1,
      traditionnelle: 0.1,
    },
  },
  {
    text: "Est motivé par le besoin d'innover et de transformer les industries traditionnelles.",
    scores: {
      cognitive: 0.3,
      esthetique: 0.3,
      individuelle: 0.2,
      sociale: 0.1,
      utilitaire: 0.5,
      traditionnelle: 0.1,
    },
  },
  {
    text: "Est motivé par le désir de défier les conventions et d'introduire de nouvelles idées disruptives.",
    scores: {
      cognitive: 0.6,
      esthetique: 0.2,
      individuelle: 0.2,
      sociale: 0.1,
      utilitaire: 0.3,
      traditionnelle: 0.1,
    },
  },
  {
    text: "Est motivé par la recherche de solutions novatrices aux défis mondiaux actuels.",
    scores: {
      cognitive: 0.5,
      esthetique: 0.1,
      individuelle: 0.1,
      sociale: 0.3,
      utilitaire: 0.4,
      traditionnelle: 0.1,
    },
  },
  {
    text: "Recherche la satisfaction dans le respect et la perpétuation des valeurs et des traditions culturelles anciennes.",
    scores: {
      cognitive: 0.1,
      esthetique: 0.1,
      individuelle: 0.3,
      sociale: 0.1,
      utilitaire: 0.1,
      traditionnelle: 0.8,
    },
  },
  {
    text: "Trouve du sens et de la direction en suivant des principes moraux et éthiques immuables dans toutes les décisions.",
    scores: {
      cognitive: 0.1,
      esthetique: 0.1,
      individuelle: 0.2,
      sociale: 0.1,
      utilitaire: 0.1,
      traditionnelle: 0.9,
    },
  },
  {
    text: "S'engage dans des actions qui préservent et renforcent l'identité culturelle et historique de la communauté.",
    scores: {
      cognitive: 0.1,
      esthetique: 0.1,
      individuelle: 0.3,
      sociale: 0.1,
      utilitaire: 0.1,
      traditionnelle: 0.8,
    },
  },
  {
    text: "Se sent motivé par le sentiment de sécurité et de stabilité offert par le respect des coutumes et des traditions établies.",
    scores: {
      cognitive: 0.1,
      esthetique: 0.1,
      individuelle: 0.2,
      sociale: 0.1,
      utilitaire: 0.1,
      traditionnelle: 0.9,
    },
  },
  {
    text: "Trouve un but et une signification dans la contribution à la préservation des pratiques traditionnelles contre les influences externes.",
    scores: {
      cognitive: 0.1,
      esthetique: 0.1,
      individuelle: 0.2,
      sociale: 0.1,
      utilitaire: 0.1,
      traditionnelle: 0.9,
    },
  },
  {
    text: "Est motivé par la perspective de maximiser les résultats tangibles et mesurables dans toutes les activités entreprises.",
    scores: {
      cognitive: 0.6,
      esthetique: 0.1,
      individuelle: 0.1,
      sociale: 0.1,
      utilitaire: 0.9,
      traditionnelle: 0.1,
    },
  },
  {
    text: "Trouve de la satisfaction dans l'utilisation efficace des ressources pour obtenir des bénéfices significatifs et pratiques.",
    scores: {
      cognitive: 0.6,
      esthetique: 0.1,
      individuelle: 0.1,
      sociale: 0.1,
      utilitaire: 0.9,
      traditionnelle: 0.1,
    },
  },
  {
    text: "Est guidé par la recherche constante d'efficacité et de rentabilité dans toutes les décisions et actions.",
    scores: {
      cognitive: 0.6,
      esthetique: 0.1,
      individuelle: 0.1,
      sociale: 0.1,
      utilitaire: 0.9,
      traditionnelle: 0.1,
    },
  },
  {
    text: "Se sent motivé par la perspective d'obtenir des résultats concrets et mesurables à court et à long terme.",
    scores: {
      cognitive: 0.6,
      esthetique: 0.1,
      individuelle: 0.1,
      sociale: 0.1,
      utilitaire: 0.9,
      traditionnelle: 0.1,
    },
  },
  {
    text: "Trouve une satisfaction personnelle dans la réalisation d'objectifs qui offrent des avantages pratiques et économiques.",
    scores: {
      cognitive: 0.6,
      esthetique: 0.1,
      individuelle: 0.1,
      sociale: 0.1,
      utilitaire: 0.9,
      traditionnelle: 0.1,
    },
  },
  {
    text: "Est motivé par la poursuite de ses aspirations personnelles et de son développement personnel continu.",
    scores: {
      cognitive: 0.3,
      esthetique: 0.1,
      individuelle: 0.9,
      sociale: 0.1,
      utilitaire: 0.1,
      traditionnelle: 0.1,
    },
  },
  {
    text: "Trouve un sens et une direction personnels en alignant ses actions sur ses valeurs et ses objectifs personnels.",
    scores: {
      cognitive: 0.3,
      esthetique: 0.1,
      individuelle: 0.8,
      sociale: 0.1,
      utilitaire: 0.1,
      traditionnelle: 0.1,
    },
  },
  {
    text: "Est motivé par la recherche constante d'auto-expression et d'accomplissement personnel à travers ses actions.",
    scores: {
      cognitive: 0.4,
      esthetique: 0.1,
      individuelle: 0.8,
      sociale: 0.1,
      utilitaire: 0.1,
      traditionnelle: 0.1,
    },
  },
  {
    text: "Trouve de la satisfaction dans la réalisation de soi et la croissance personnelle à travers des expériences et des défis personnels.",
    scores: {
      cognitive: 0.4,
      esthetique: 0.1,
      individuelle: 0.9,
      sociale: 0.1,
      utilitaire: 0.1,
      traditionnelle: 0.1,
    },
  },
  {
    text: "Est motivé par le désir de surpasser ses propres limites et d'atteindre ses objectifs personnels avec détermination.",
    scores: {
      cognitive: 0.4,
      esthetique: 0.1,
      individuelle: 0.9,
      sociale: 0.1,
      utilitaire: 0.1,
      traditionnelle: 0.1,
    },
  },
];
