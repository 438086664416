import React, { useEffect, useState } from "react";
import GroupsIcon from "@mui/icons-material/Groups";
import SchoolIcon from "@mui/icons-material/School";
import PersonIcon from "@mui/icons-material/Person";
import { useNavigate, useParams } from "react-router-dom";
import { DELETE_SCHOOL } from "../../pages/workspace/graphgl/mutation";
import { DELETE_GROUP } from "../graphql/group/mutation";
import { useMutation , useQuery} from "@apollo/client";

import { RoundWrapper, ClassWrapper } from "./RoundClass.style";
import { GET_SCHOOL_BY_ID } from "../graphql/school/query";

export const RoundClass = ({
  propsDevice,
  item,
  itemSize,
  color,
  currentUserId,
  navigateLink,
  currentUserRoleId
}) => {
  const colorGroup = color ?? "#5BC5F2";
  let navigate = useNavigate();


  const {
    loading: loadingSchool,
    error: errorSchool,
    data: dataSchool,
  } = useQuery(GET_SCHOOL_BY_ID, {
    variables: {
      getSchoolByIdId: parseInt(item?.school_id),
    },
  });

  const [
    deleteSchool,
    { dataDeletionSchool, loadingDeletionSchool, errorDeletionSchool },
  ] = useMutation(DELETE_SCHOOL, {
    update(cache, { data: { deleteSchool } }) {
      cache.evict({ id: cache.identify(deleteSchool) });
    },
  });


  const [
    deleteGroup,
    { dataDeletionGroup, loadingDeletionGroup, errorDeletionGroup },
  ] = useMutation(DELETE_GROUP, {
    update(cache, { data: { deleteGroup } }) {
      cache.evict({ id: cache.identify(deleteGroup) });
    },
  });

  const deleteElement = async (id) => {
if (item.__typename ==="School" ){
   
    const data = await deleteSchool({
      variables: {
        
          deleteSchoolId: parseInt(id),
        }
        
      
      },
    ); 

    return data
  }
    else if (item.__typename ==="Group"){
    const data = await deleteGroup({
      variables: {
        
          deleteGroupId: parseInt(id),
        }
        
      
      },
    );

  return data }
   
    
  };
console.log(itemSize )

  return (
    <>
      <ClassWrapper>
        <RoundWrapper
          onClick={() =>
            navigate(navigateLink
              /* `/list` + `/` + currentUserId + `/` + item.id + `/` + item.name */
            )
          }
        >
          {" "}
          <SchoolIcon sx={{ fill: colorGroup, height: "30%", width: "30%" }} />
          <GroupsIcon
            sx={{
              fill: colorGroup,
              height: "60%",
              width: "60%",
              margin: "-25px 0 10px 0",
            }}
          />
        </RoundWrapper>
        {item.__typename ==="Group" ? <div
          style={{
            font: "italic normal 300 15px Helvetica Neue",
            color: "#002B3F",
            textTransform: "uppercase",
          }}
        > {dataSchool?.getSchoolById?.name}</div> :<></> }
        <div
          style={{
            font: "italic normal 300 25px Helvetica Neue",
            color: "#002B3F",
            textTransform: "uppercase",
          }}
        >
          {item ? item.name : "name"}
        </div>
       { currentUserRoleId !==4  ? <div
          style={{
            font: "italic normal 300 15px Helvetica Neue",
            color: "#E74E0F",
            textTransform: "uppercase",
            cursor:"pointer"
          }}
          onClick={()=> deleteElement (item.id)}
        >
          {"SUPPRIMER"}
        </div> : <></>}
        <div style={{ display: "flex", placeItems: "flex-end" }}>
        {item.__typename ==="Group" ? <PersonIcon /> :   <SchoolIcon sx={{ fill: "#000", height: "20px", width: "20px", margin:"0 2px" }} />}
          <div
            style={{
              font: "italic normal 350 15px Helvetica Neue",
              color: "#002B3F",
            }}
          >
           
            {itemSize ? itemSize : "N"}
          </div>
        </div>
      </ClassWrapper>
    </>
  );
};
