import {
  Link,
  Route,
  Routes,
  useLocation,
  Navigate,
  useNavigate,
} from "react-router-dom";
import { Login } from "../pages/login/Login";
import { NotFound } from "../pages/notFound/NotFound";
import { PageListTab } from "../pages/pageListTab/PageListTab";
import { SignUp } from "../pages/signup/SignUp";
import { StudentPage } from "../pages/studentPage/StudentPage";
import { Workspace } from "../pages/workspace/Workspace";
import { SideMenu } from "../utils/sideMenu/SideMenu";
import { userLocal } from "../utils/userLocal";
import { SharedPage } from "../pages/sharePage/SharedPage";
import { GET_CURRENT_USER } from "../utils/graphql/user/query";
import { GET_MODULE_BY_STUDENT_ID } from "../utils/graphql/module/query";
import { useQuery } from "@apollo/client";
import { PrivateRoute } from "./PrivateRoute";
import { WorkspaceForAdmin } from "../pages/workspace/WorkspaceForAdmin";
import { WorkspaceForAdminToSchool } from "../pages/workspace/WorkspaceForAdminToSchool";
import { StudentSingularitySettings,Test } from "../pages/studentSpace/StudentSingularitySettings";
import { ForgotPassword } from "../utils/ForgotPassword";
import { UserSettings } from "../pages/user/UserSettings";

export const Router = ({ device, retina, portrait }) => {
  const propsDevice = {
    device: device,
    retina: retina,
    portrait: portrait,
  };

  const {
    client,
    loading: loadingCurrentUser,
    error: errorCurrentUser,
    data: dataCurrentUser,
  } = useQuery(GET_CURRENT_USER);



  const setCurrentUser = async (value) => {
    await localStorage.setItem("currentUser", JSON.stringify(value));
  };

  setCurrentUser(dataCurrentUser?.getCurrentUser);
  const token = localStorage.getItem("token");
/*   const resetToken = localStorage.getItem("resetToken"); */
 

  const {
    loading: loadingModule,
    error: errorModule,
    data: dataModule,
  } = useQuery(GET_MODULE_BY_STUDENT_ID, {
    variables: {
      studentId: parseInt(dataCurrentUser?.getCurrentUser?.id) || 0,
    },
  });
  


  const location = useLocation();
  let navigate = useNavigate();
 
  

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection:
          propsDevice.device === "desktopOrLaptop" ? null : " column",
        padding: propsDevice.device === "desktopOrLaptop" || !token|| !dataCurrentUser?.getCurrentUser ? "0" : "90px 0 0 0",
      }}
    >
      {dataCurrentUser?.getCurrentUser && token ? (
        <SideMenu
          propsDevice={propsDevice}
          currentUser={dataCurrentUser?.getCurrentUser}
        />
      ) : (
        <></>
      )}
      
      {/*  <Link to={"/login"}>
          <Login propsDevice={propsDevice} />
        </Link>
        <Link to={"workspace"}>
        <Workspace
                propsDevice={propsDevice}
                currentUser={dataCurrentUser?.getCurrentUser}
              />
        </Link> */}
      <Routes>
      {<Route path='*' element={
     token  ?  (
        // Redirige vers la page de connexion si l'utilisateur n'est pas connecté
        <NotFound propsDevice={propsDevice}/>
      
     ) : (
      
      // Redirige vers la page 404 si l'utilisateur est connecté
      <Navigate to="/login" />)}/> }
        <Route
          exact
          path={"/login"}
          element={<Login propsDevice={propsDevice} />}
        />
        <Route
          exact
          path={"/signup/:idCrypted"}
          element={<SignUp propsDevice={propsDevice} />}
        />

        <Route
          exact
          path={"/shared/:idCrypted"}
          element={
            <SharedPage propsDevice={propsDevice} currentUser={userLocal} />
          }
        />
        {/* <Route
          exact
          path={`/resetPassword/:${resetToken}`}
          element={<ForgotPassword mailUser={"oui"} />}
        /> */}
        <Route
          exact
          path={"/workspace"}
          element={
            <PrivateRoute
            token = {token}>
              <Workspace
                propsDevice={propsDevice}
                currentUser={dataCurrentUser?.getCurrentUser}
              />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path={"/workspaceAdmin"}
          element={
            <PrivateRoute
            token = {token}>
              <WorkspaceForAdmin
                propsDevice={propsDevice}
                currentUser={dataCurrentUser?.getCurrentUser}
              />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path={"/workspaceAdmin/:idSchool/:name"}
          element={
            <PrivateRoute
            token = {token}>
              <WorkspaceForAdminToSchool
                propsDevice={propsDevice}
                currentUser={dataCurrentUser?.getCurrentUser}
              />
            </PrivateRoute>
          }
        />

        <Route
          exact
          path={"/list/:idUser/:idGroup/:name"}
          element={
            <PrivateRoute
            token = {token}>
              <PageListTab
                propsDevice={propsDevice}
                currentUser={dataCurrentUser?.getCurrentUser}
              />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path={"/list/:idUser/:idGroup/:nameGroup/:idStudent/:studentName"}
          element={
            <PrivateRoute
            token = {token}>
              <StudentPage propsDevice={propsDevice} currentUser={userLocal} />
            </PrivateRoute>
          }
        />

        <Route
          exact
          path={"/studentSingularitySetting/:idUser"}
          element={
            <PrivateRoute
            token = {token}>
             <StudentSingularitySettings
              propsDevice={propsDevice}
              currentUser={dataCurrentUser?.getCurrentUser}
              
              resultDisc={dataModule?.getModuleByUserId?.resultDisc}
              resultValMot={dataModule?.getModuleByUserId?.resultValMot}
              resultIntMul={dataModule?.getModuleByUserId?.resultIntMul}
            /> 
            </PrivateRoute>
          }
        />
         <Route
          exact
          path={"/usersettings/:idUser"}
          element={
            <PrivateRoute
            token = {token}>
             <UserSettings
              propsDevice={propsDevice}
              currentUser={dataCurrentUser?.getCurrentUser}
            /> 
             </PrivateRoute>
          }
        />
        
        {  <Route
          path="*"
          element={
            !token || !dataCurrentUser?.getCurrentUser ?  (
               // Redirige vers la page de connexion si l'utilisateur n'est pas connecté
              <Navigate to="/login" />
            ) : (
             
             // Redirige vers la page d'accueil si l'utilisateur est connecté
              <Navigate to="/workspace" />
            )
          }
        /> }
      </Routes>
    </div>
  );
};
