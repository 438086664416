import React, { useState, useEffect } from "react";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import { useNavigate, useParams } from "react-router-dom";
import { GET_CURRENT_USER } from "../../utils/graphql/user/query";
import { UPDATE_USER } from "../../utils/graphql/user/mutation";
import { useQuery, useMutation, gql } from "@apollo/client";
import { GET_SCHOOL_BY_ID } from "../../utils/graphql/school/query";
import { GET_GROUP_BY_ID } from "../../utils/graphql/group/query";
import { StyledInput } from "../../utils/StyledInput.style";
import { Element,Title,ElementLabel,UpdateBox,WrapperSection,ElementWrapper,ElementEdit, UpdateButton} from "./UserSettings.style";




export const UserSettings= ({propsDevice, currentUser}) =>{


const {
    loading: loadingSchool,
    error: errorSchool,
    data: dataSchool,
  } = useQuery(GET_SCHOOL_BY_ID, {
    variables: {
      getSchoolByIdId: currentUser?.school_id,
    },
  });

  const {
    loading: loadingGroup,
    error: errorGroup,
    data: dataGroup,
  } = useQuery(GET_GROUP_BY_ID, {
    variables: {
      getGroupByIdId: currentUser?.groupId,
    },
  });

  const [
    updateUser,
    { dataUpdatedUser, loadingUpdatedUser, errorUpdatedUser },
  ] = useMutation(UPDATE_USER);

  const [values, setValues] = useState({
    email: currentUser?.email,
    password: "",
    lastname:currentUser?.lastname,
    name:currentUser?.name
  });

  const [modifActLN, setModifActLN] = useState(false)
  const [modifActN, setModifActN] = useState(false)
  const [modifActE, setModifActE] = useState(false)
  const [modifActP, setModifActP] = useState(false)



  const  updateUserFunction = async (newName,newLastName,newEmail, idUser) => {
    const data = await updateUser({
      variables: {
        userUpdateInput: {
          id : parseInt(idUser),
          name: newName,
          email: newEmail,
          lastname:newLastName,
        },
      },
    });

  };





const handleChange =(prop) => (e) =>{
    setValues({ ...values, [prop]: e.target.value });
}

  const  updatebox = (placeholder, value,prop) => {
    return(
        <UpdateBox  ><StyledInput
        id="standard-adornment-email"
        type={"text"}
        value={value}
        placeholder={'Changer  " ' + placeholder  + '" ?'  } 
        onChange={handleChange(prop)}
      /></UpdateBox>
    )
  }

return (
    <WrapperSection>
    <Title> MES INFORMATIONS </Title>
    <ElementLabel>NOM</ElementLabel>
    <ElementWrapper>
    

    {modifActLN ? updatebox(currentUser?.lastname, values.lastname, "lastname"): <Element> {currentUser?.lastname}</Element>}
        <ElementEdit>
            <IconButton
                size="small"
                aria-label="edit"
                color="inherit"
                onClick={() => setModifActLN(!modifActLN)}
                >
                <EditIcon />
            </IconButton>
        </ElementEdit>
    </ElementWrapper>
    <ElementLabel>PRÉNOM</ElementLabel>
    <ElementWrapper>
     
     {modifActN ? updatebox(currentUser?.name, values.name, "name"):<Element>{currentUser?.name} </Element>}
        <ElementEdit>
            <IconButton
                size="small"
                aria-label="edit"
                color="inherit"
                onClick={() => setModifActN(!modifActN)}
                >
                <EditIcon />
            </IconButton>
        </ElementEdit>
    </ElementWrapper>
    <ElementLabel>EMAIL </ElementLabel>
    <ElementWrapper>
   
    {modifActE ? updatebox(currentUser?.email , values.email,"email" ): <Element> {currentUser?.email}</Element>}
    <ElementEdit>
            <IconButton
                size="small"
                aria-label="edit"
                color="inherit"
                onClick={() => setModifActE(!modifActE)}
                >
                <EditIcon />
            </IconButton>
        </ElementEdit>
    </ElementWrapper>
    <ElementLabel>MOT DE PASSE</ElementLabel>
    
    <ElementWrapper>
   
    {modifActP? updatebox("****", values.password,"password"): <Element> {"*******"}</Element>}
    <ElementEdit>
            <IconButton
                size="small"
                aria-label="edit"
                color="inherit"
                onClick={() => setModifActP(!modifActP)}
                >
                <EditIcon />
            </IconButton>
        </ElementEdit>
    </ElementWrapper>
    <ElementLabel>JE SUIS </ElementLabel>
    <ElementWrapper>

        <Element>{currentUser?.roleId === 3 ? "Élève" :currentUser?.roleId === 2 ? "Enseignant" :currentUser?.roleId === 1 ? "Admin"  :currentUser?.roleId === 4 ? "Awayker" : ""  } </Element>
    </ElementWrapper>
    <ElementLabel>ETABLISSEMENT</ElementLabel>
    <ElementWrapper>
    <Element>{dataSchool?.getSchoolById?.name } </Element>
    </ElementWrapper>
    
    <ElementLabel>GROUPE</ElementLabel>
    <ElementWrapper>
    
    <Element>  {dataGroup?.getGroupById?.name }</Element>
    </ElementWrapper>

    <UpdateButton
     onClick={() => 
    updateUserFunction(values.name,values.lastname,values.email,currentUser?.id)}>
        Mettre à jour les informations
    </UpdateButton>
    </WrapperSection>
)

}
