const createFilteredObject = (obj, keysToRemove) => {
  let filteredObject = Object.fromEntries(
    Object.entries(obj).filter(([key]) => !keysToRemove.includes(key))
  );


  if (obj["__typename"] === "ValeurMotivation") {
    if (filteredObject.hasOwnProperty("s")) {
      filteredObject.so = filteredObject.s;
      delete filteredObject.s;
    } else if (obj["__typename"] === "IntelligenceMultiple") {
      if (filteredObject.hasOwnProperty("s")) {
        filteredObject.sp = filteredObject.s;
        delete filteredObject.s;
      }
    }
  }
  return filteredObject;
};

const keysToRemove = ["__typename", "name", "comment", "id"];

const selectGoodKey = (key) => {
  let newKey;
  switch (key) {
    case "r":
      newKey = "dominant";
      break;
    case "y":
      newKey = "influent"; // Yellow color for 'y'
      break;
    case "g":
      newKey = "stabilité"; // Green color for 'g'
      break;
    case "b":
      newKey = "conformité";
      break;
    case "vl":
      newKey = "linguistique";
      break;
    case "lm":
      newKey = "logique";
      break;
    case "intra":
      newKey = "intrapersonnel";
      break;
    case "inter":
      newKey = "interpersonnel";
      break;
    case "n":
      newKey = "naturaliste";
      break;
    case "sp":
      newKey = "spaciale";
      break;
    case "k":
      newKey = "kinesthésique";
      break;
    case "m":
      newKey = "musicale";
      break;
    case "c":
      newKey = "cognitive";
      break;
    case "e":
      newKey = "esthetique";
      break;
    case "indiv":
      newKey = "individuelle";
      break;
    case "so":
      newKey = "sociale";
      break;
    case "util":
      newKey = "utilitaire";
      break;
    case "t":
      newKey = "traditionnelle";
      break;
    default:
      console.log(`Sorry, we are out of ${key}.`);
  }
  return newKey;
};

const calculateMatchScore = (userScores, phraseScores) => {
  let matchScore = 0;

  const cleanUserScore = userScores ? createFilteredObject(userScores, keysToRemove) : [];

  for (const key in cleanUserScore) {
    if (key !== "s") {
      {
        matchScore +=
          cleanUserScore[key] * 0.01 * phraseScores[selectGoodKey(key)];
      }
    }
  }

  return matchScore;
};

export const getBestPhrases = (userScores, phrases, topN) => {
  const scoredPhrases = phrases.map((phrase) => {
    const threshold = 0.6;
    const filteredLevels = Object.keys(phrase.scores)
      .filter((key) => phrase.scores[key] > threshold)
      .reduce((acc, key) => {
        acc[key] = phrase.scores[key];
        return acc;
      }, {});

    return {
      text: phrase.text,
      matchScore: calculateMatchScore(userScores, phrase.scores),
      level: filteredLevels,
    };
  });

  scoredPhrases.sort((a, b) => b.matchScore - a.matchScore);

  return scoredPhrases.slice(0, topN);
};
