import crypto from "crypto-js";

let key =  "@Wk2SS3cree!"

export const  tokenise = (item) => {
    const itemCrypted = crypto.AES.encrypt(item, key)
      .toString()
      .replaceAll("/", "20%==");
    return itemCrypted;

}