import styled from "styled-components";
import "../../asset/fonts/grotasansaltrd-heavy.otf"

export const Element = styled.div`
font-style: italic;
border-radius : 5px;
background-color: #f4f4f4;
    border: 4px #000;
    display: flex;
    padding: 5px 10px;
`



export const UpdateBox = styled.div`
font-style: italic;
border-radius : 5px;
border: 4px #000;
display: flex;
padding:1px 1px;

`



export const UpdateButton = styled.span`
background-color: #002B3F;
color : #FFF;
FONT-SIZE: 22px;
display: flex;
align-self: center;
border-radius : 5px;
margin : 20px 0 0 0; 
padding : 10px 15px;
&:hover{
    box-shadow: 7px 7px 10px #0000003b;
    cursor: pointer;
 } 
transition: all 150ms ease;
`

export const Title= styled.div`
FONT-WEIGHT: BOLD;
FONT-SIZE: 32px;
display: flex;
align-self: center;
margin : 20px 0 0 0; 
`

export const ElementLabel = styled.div`
FONT-WEIGHT: BOLD;
FONT-SIZE: 22px;
margin : 10px 0; 
color : #5BC5F2;

`

export const WrapperSection = styled.div`
display: flex;
    flex-direction: column;
    align-content: center;
    flex-wrap: nowrap;
    margin: 10px 20px;
    //font-family: GrotaSansAltRd;
    width: 100%;
`


export const ElementWrapper = styled.div`
display: flex;
    align-content: center;
    flex-wrap: nowrap;
   align-items: center;
    //font-family: GrotaSansAltRd;
    width: 100%;
`


export const ElementEdit = styled.div`
display: flex;
  
    width: 100%;
`