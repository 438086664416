import { gql } from "@apollo/client";

export const CREATE_SCHOOL = gql`
  mutation CreateSchool($schoolInput: SchoolInput!) {
    createSchool(schoolInput: $schoolInput) {
      id
      created_at
      name
    }
  }`;



export const DELETE_SCHOOL = gql`
mutation DeleteSchool($deleteSchoolId: ID!) {
  deleteSchool(id: $deleteSchoolId) {
    id
  }
}
`;

