import { currentToken } from "../config/cache";

import { useReactiveVar } from "@apollo/client";

export const useToken = () => {
  const token = useReactiveVar(currentToken);
  
  const setToken = async (value) => {
    currentToken(value);
    await localStorage.setItem("token", value);

   // console.log("currentoken",currentToken());
  };
  
  const removeToken = async () => {
    currentToken(null);
    await localStorage.removeItem("token");
  };

  return { token, setToken, removeToken };
};
