import { gql } from "@apollo/client";

export const GET_ALL_USERS = gql`
  query GetUsers {
    getUsers {
      created_at
      email
      id
     name
     lastname
     roleId
    }
  }
`;
