
import QrCode from 'qrcodejs';

export const generateQRCode = (content) => {
  if (content === "") return;

/*   const qrcode = new QRCode('qrcode', {
    text: content,
    width: 100,
    height: 100,
  }); */

  const matrix = QrCode.generate(content);
const text = QrCode.render('medium', matrix);
const uri = QrCode.render('svg-uri', matrix);
   



  return uri;
};