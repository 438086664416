import { gql } from "@apollo/client";

export const UPDATE_USER = gql`
  mutation UpdateUser($userUpdateInput: UserUpdateInput!) {
    updateUser(userUpdateInput: $userUpdateInput) {
      id
      profilVisible
      lastname
      name
      email
      roleId
    }
  }
`;


export const UPDATE_USER_PIC = gql`
mutation AddUserProfilPic($profilePicture: Upload!) {
  addUserProfilPic(profilePicture: $profilePicture) {
    id
    profilePicture
  }
}
`;
