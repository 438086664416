import styled from "styled-components";
import DiscIcon from "../../asset/DiscIcon.svg";
import ValMotIcon from "../../asset/ValMotIcon.svg";
import IntMulIcon from "../../asset/IntMulIcon.svg";
import "../../App.css"
import "../../asset/fonts/grotasansaltrd-heavy.otf"


export const WrapperPage = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 30px;
  width: auto;
`;

export const WrapperTextAndCircle = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: flex-end;
  position: fixed;
  z-index: 1000;
  left: 0px;
`;
export const StyledTextCircle = styled.div`
  display: flex;

  flex-wrap: wrap;
  justify-content: center;
 // font-family: GrotaSansAltRd;
  font-weight: 700;
  transition: all 150ms ease;
  transform: ${(props) => (props.hover ? "scale(1.1)" : "scale(0)")};
`;
export const SingulariteHowCircle = styled.div`
  height: 110px;
  width: 110px;
  border-radius: 100%;
  margin: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => (props.hover ? "#e74e0fb3" : "#e74e0f80")};
  &:hover {
    background-color: #e74e0fb3;
    transform: scale(1.1);
  }
  transform: ${(props) => (props.hover ? "scale(1.1)" : "scale(1)")};
  transition: all 150ms ease;
`;

export const SingulariteWithCircle = styled.div`
  height: 80px;
  width: 80px;
  border-radius: 100%;
  margin: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 150ms ease;
  background-color: ${(props) => (props.hover ? " #316880b3" : "#31688080")};
  transform: ${(props) => (props.hover ? "scale(1.1)" : "scale(1)")};
  &:hover {
    background-color: #316880b3;

    transform: scale(1.1);
  }
`;

export const SingulariteWhyCircle = styled.div`
  height: 60px;
  width: 60px;
  border-radius: 100%;
  margin: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 150ms ease;
  background-color: ${(props) => (props.hover ? " #5bc5f2b3" : "#5bc5f280")};
  transform: ${(props) => (props.hover ? "scale(1.1)" : "scale(1)")};
  &:hover {
    background-color: #5bc5f2b3;

    transform: scale(1.1);
  }
`;

export const BackJauge = styled.div`
  width: 8px;
  height: 70px;
  background-color: #ffffff;
  border: 2px solid #000000;
  border-radius: 17px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin: 10px 10px 5px 10px;
}
`;

export const ColorJauge = styled.div`
  width: 8px;
  height: ${(props) => (props.height ? props.height + "%" : "0%")};
  background-color: ${(props) => props.color ?? "#fff"};

  border-radius: 17px;
`;

export const WrapperJauges = styled.div`
  display: flex;
  width: fit-content;
  margin: 0 5px;
`;

export const WrapperOneJauge = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  text-transform: uppercase;
  font: normal normal bold 18px GrotaSansAltRd;
  margin: 0 1px 0 1px;
`;

export const WrapperModule = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  margin: 10px;
  padding: 10px;
  align-items: center;
  transition: all 200ms ease;
  &:hover {
    border-radius: 32px;
    
   
    cursor: pointer;
  }
  justify-content: center;
`;

export const WrapperPicture = styled.div`
  display: flex;
  flex-direction: column;
`;

export const WrapperAllModule = styled.div`
  display: flex;
  flex-direction: column;
 margin-top : 70px;

`;

export const WrapperHeaderText= styled.div`

display: flex;
flex-direction: row-reverse;
align-items: center;
`;



export const WrapperPlayer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-right : 30px;
`;

export const PlayerRed = styled.div`
display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
width: 91px;
height: 71px;

background: #C10000 0% 0% no-repeat padding-box;
box-shadow: 1px 2px 9px #00000017;
border-radius: 8px;
opacity: 0.5;
transition: all 150ms ease;
&:hover {
  opacity: 1;
cursor : pointer;
  transform: scale(1.1);
}
scale: ${(props) => (props.scale ?? "1")};
`;

export const PlayerText = styled.div`
display: flex;
width:100%;
margin : 2px;
font-weight: bold;
color: #373a3c;
font-size: ${(props) => (props.size ?? "14px")};


`;


export const PlayerWhiteTriangle = styled.div`


border-top : 18px solid transparent;
border-bottom : 18px solid transparent;
border-left : 32px solid #FFFFFF;


opacity: 1;
`;

export const WrapperCirclePicture = styled.div`
  display: flex;
  background: #ffffff;
  box-shadow: 1px 2px 9px #00000017;
  margin: 5px 10px 0 10px;
  padding: 10px;
  height: 80px;
  width: 80px;
  border-radius: 100%;
  justify-content: center;
`;

export const WrapperIconDisc = styled.div`
  background-image: url("${DiscIcon}");
  background-size: cover;
  height: 45%;
  width: 45%;
  background-repeat: no-repeat;
  padding: 10px;
  align-self: center;
  display: flex;
`;

export const WrapperIconIntMul = styled.div`
  background-image: url("${IntMulIcon}");
  background-size: cover;
  height: 60%;
  width: 28%;
  background-repeat: no-repeat;
  padding: 10px;
  align-self: center;
  display: flex;
`;

export const WrapperIconValMot = styled.div`
  background-image: url("${ValMotIcon}");
  background-size: cover;
  height: 49%;
  width: 45%;
  background-repeat: no-repeat;
  padding: 10px;
  align-self: center;
  display: flex;
`;

export const WrapperBigArrow = styled.div`
  display: flex;
  margin: 0 10px;
`;
export const BigArrow = styled.div`
  display: inline-block;
  height: 0;
  width: 0;
  border-top: 29px solid transparent;
  border-bottom: 29px solid transparent;
  transition: all 150ms ease;
  transform : ${(props)=> props.transform ?? "rotate(0deg)"};
  border-left: ${(props) =>
    props.hover !== false ? "29px solid #144153" : "29px solid #5bc5f24b"};
`;

export const WrapperAllTextResults = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 10px;
`;

export const WrapperTextResult = styled.div`
  display: flex;

  align-items: center;
`;

export const BulletTextResult = styled.div`
  display: flex;

  width: 5px;
  height: 5px;
  border-radius: 100%;
  background-color: ${(props) =>
    props.hover !== false ? "#316880" : "transparent"};
  transition: all 150ms ease;
  border: 1px solid #316880;
`;
export const TextResult = styled.div`
  display: flex;

  margin: 0 0 0 5px;
  color: #316880;
  font: normal normal bold 14px Helvetica Neue;
`;

export const Divider = styled.div`
  height: 1px;
  width: 100%;
  background-color: #b2b2b263;
  border-radius: 2px;
`;

export const TitleSoftSkils = styled.div`
  margin: 5px 10px;
  width: 100%;
  font-size: 20px;
  font-style: italic;
  text-align: center;
  color: #b2b2b2;
`;

export const SubTitleSoftSkils = styled.div`
  margin: 5px 10px;
  width: 100%;
  font-size: 16px;
  font-style: italic;
  font-weight: 300;
  color: #e74e0f;
`;
