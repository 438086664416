import HighchartsReact from "highcharts-react-official";

import * as Highcharts from "highcharts";
import variablepie from "highcharts/modules/variable-pie";

variablepie(Highcharts);

export const DiscChart = ({ r, y, b, g,height="170px", maxWidth= "345px" }) => {
  const options = {
    chart: {
      height: height,
      backgroundColor: "transparent",
    },
    title: {
      text: "",
      style: {
        textAlign: "center",
      },
    },
    tooltip: {
      useHTML: true,
      backgroundColor: null,
      borderWidth: 0,
      shadow: false,
    },
    plotOptions: {
      variablepie: {
        borderWidth: 2,
        borderColor: "#000",
        dataLabels: {
          enabled: false,
        },
        minPointSize: "80%",
        innerSize: "0%",
        tooltip: {
          headerFormat: "",
          pointFormatter: function () {
            return (
              '<div style="background-color:' +
              this.color +
              ';color: #000; z-index: 12000 ; border-radius: 3px ; padding: 2px" > ' +
              "<b>" +
              this.y +
              "</b>" +
              "% " +
              "<b>" +
              this.name +
              "</b>" +
              "</div>"
            );
          },
        },
      },
    },
    credits: {
      enabled: false,
    },
    series: [
      {
        type: "variablepie",
        states: {
          hover: {
            halo: null,
          },
        },
        data: [
          {
            name: "Dominant",
            y: r,
            z: r > 50 ? 2 : 1,
            color: "#F61010",
          },
          {
            name: "Influence",
            y: y,
            z: y > 50 ? 2 : 1,
            color: "#FCE200",
          },
          {
            name: "Stablité",
            y: g,
            z: g > 50 ? 2 : 1,
            color: "#37E600",
          },
          {
            name: "Conformité",
            y: b,
            z: b > 50 ? 2 : 1,
            color: "#2B9DC6",
          },
        ],
      },
    ],
  };

  return (
    <div style={{ maxWidth: maxWidth }}>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
};

export const IntellMultipleChart = ({maxWidth= "345px" ,height="170px", vl, lm, intra, inter, m, k, s, n }) => {
  const options = {
    chart: {
      height: height,
      backgroundColor: "transparent",
    },
    title: {
      text: "",
      style: {
        textAlign: "center",
      },
    },
    tooltip: {
      useHTML: true,
      backgroundColor: null,
      borderWidth: 0,
      shadow: false,
    },
    plotOptions: {
      variablepie: {
        borderWidth: 2,
        borderColor: "#000",
        dataLabels: {
          enabled: false,
        },
        minPointSize: "80%",
        innerSize: "0%",
        tooltip: {
          headerFormat: "",
          pointFormatter: function () {
            return (
              '<div style="background-color:' +
              this.color +
              ';color: #000; z-index: 12000; border-radius: 3px ; padding: 2px" > ' +
              "<b>" +
              this.y +
              "</b>" +
              "% " +
              "<b>" +
              this.name +
              "</b>" +
              "</div>"
            );
          },
        },
      },
    },
    credits: {
      enabled: false,
    },
    series: [
      {
        type: "variablepie",
        states: {
          hover: {
            halo: null,
          },
        },
        data: [
          {
            name: "Verbo-Linguistique",
            y: vl,
            z: vl > 50 ? 2 : 1,
            color: "#5bc5f2",
          },
          {
            name: "Logico-Mathématique",
            y: lm,
            z: lm > 50 ? 2 : 1,
            color: "#6bade8",
          },
          {
            name: "Intrapersonelle",
            y: intra,
            z: intra > 50 ? 2 : 1,
            color: "#7a95dd",
          },
          {
            name: "Interpersonnelle",
            y: inter,
            z: inter > 50 ? 2 : 1,
            color: "#897dd2",
          },
          {
            name: "Kinésthésique",
            y: k,
            z: k > 50 ? 2 : 1,
            color: "#9865c7",
          },
          {
            name: "Musicale",
            y: m,
            z: m > 50 ? 2 : 1,
            color: "#a74dbd",
          },
          {
            name: "Spaciale",
            y: s,
            z: s > 50 ? 2 : 1,
            color: "#b635b2",
          },
          {
            name: "Naturaliste",
            y: n,
            z: n > 50 ? 2 : 1,
            color: "#d4049c",
          },
        ],
      },
    ],
  };

  return (
    <div style={{ maxWidth: maxWidth }}>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
};

export const MotivationChart = ({maxWidth= "345px" ,height="170px", e, c, s, indiv, util, t }) => {
  const options = {
    chart: {
      height: height ,
      backgroundColor: "transparent",
    },
    title: {
      text: "",
      style: {
        textAlign: "center",
      },
    },
    tooltip: {
      useHTML: true,
      backgroundColor: null,
      borderWidth: 0,
      shadow: false,
    },
    plotOptions: {
      variablepie: {
        borderWidth: 2,
        borderColor: "#000",
        dataLabels: {
          enabled: false,
        },
        minPointSize: "80%",
        innerSize: "0%",
        tooltip: {
          headerFormat: "",
          pointFormatter: function () {
            return (
              '<div style="background-color:' +
              this.color +
              ';color: #000; z-index: 12000; border-radius: 3px ; padding: 2px"> ' +
              "<b>" +
              this.y +
              "</b>" +
              "% " +
              "<b>" +
              this.name +
              "</b>" +
              "</div>"
            );
          },
        },
      },
    },
    credits: {
      enabled: false,
    },
    series: [
      {
        type: "variablepie",
        states: {
          hover: {
            halo: null,
          },
        },
        data: [
          {
            name: "Esthétique",
            y: e,
            z: e > 50 ? 2 : 1,
            color: "#a2f25c",
          },
          {
            name: "Cognitive",
            y: c,
            z: c > 50 ? 2 : 1,
            color: "#b8c746",
          },
          {
            name: "Individuelle",
            y: indiv,
            z: indiv > 50 ? 2 : 1,
            color: "#c3b13b",
          },
          {
            name: "Utilitaire",
            y: util,
            z: util > 50 ? 2 : 1,
            color: "#ce9b2f",
          },
          {
            name: "Traditionelle",
            y: t,
            z: t > 50 ? 2 : 1,
            color: "#e47019",
          },
          {
            name: "Sociale",
            y: s,
            z: s > 50 ? 2 : 1,
            color: "#fa4402",
          },
        ],
      },
    ],
  };

  return (
    <div style={{ maxWidth: maxWidth }}>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
};
