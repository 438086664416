import { gql } from "@apollo/client";

export const UPDATE_DISC = gql`
  mutation UpdateDisc($discUpdateInput: DiscUpdateInput!) {
    updateDisc(discUpdateInput: $discUpdateInput) {
      id
      name
      r
      g
      b
      y
    }
  }
`;

export const UPDATE_INTMUL = gql`
  mutation UpdateIntelligenceMultiple(
    $intelligenceMultipleUpdateInput: IntelligenceMultipleUpdateInput!
  ) {
    updateIntelligenceMultiple(
      intelligenceMultipleUpdateInput: $intelligenceMultipleUpdateInput
    ) {
      id
      name
      lm
      vl
      k
      s
      m
      n
      inter
      intra
    }
  }
`;

export const UPDATE_VALMOT = gql`
  mutation UpdateValeurMotivation(
    $valeurMotivationUpdateInput: ValeurMotivationUpdateInput!
  ) {
    updateValeurMotivation(
      valeurMotivationUpdateInput: $valeurMotivationUpdateInput
    ) {
      id
      name
      c
      e
      s
      t
      indiv
      util
    }
  }
`;

export const CREATE_MODULE = gql`
  mutation CreateModule($moduleInput: ModuleInput!) {
    createModule(moduleInput: $moduleInput) {
      student {
        name
      }
    }
  }
`;
