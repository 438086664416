import styled from "styled-components";
import { theme } from "./theme";
import {
  purple,
  blueGrey,
  orange,
  deepOrange,
  blue,
  grey,
} from "@mui/material/colors";

export const StyledInput = styled.input`
  width: 100%;
  height: 0px;
  font-size: 0.8rem;
  font-family: Helvetica Neue;
  font-weight: 400;
  line-height: 1.5;
  color: ${(props) => (props.mode === "dark" ? grey[300] : grey[900])};
  background: ${(props) => (props.mode === "dark" ? grey[900] : grey[50])};
  border: 1px solid
    ${(props) => (props.mode === "dark" ? grey[800] : grey[300])};
  border-radius: 8px;
  padding: 12px 12px;
  margin: 5px 0;
  &:hover {
    background: ${(props) => (props.mode === "dark" ? "" : grey[100])};
    border-color: ${(props) => (props.mode === "dark" ? grey[700] : grey[400])};
  }

  &:focus {
    outline: 3px solid
      ${(props) => (props.mode === "dark" ? blue[600] : blue[100])};
  }
`;
