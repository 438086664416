import * as React from "react";

import { useNavigate } from "react-router-dom";

import { RoundNameUser, WrapperUser } from "./ButtonProfil.style";

export const ButtonProfil = ({ propsDevice, userName, userRole, userId }) => {

  let navigate = useNavigate();
  return (
    <WrapperUser onClick={() => navigate(`/usersettings/` + userId)}>
      <RoundNameUser>
        {" "}
        {userName[0]?.toUpperCase() + userName[1]?.toUpperCase()}
      </RoundNameUser>{" "}
      {propsDevice === "desktopOrLaptop" ? (
        userRole === "STUDENT" ? (
          "Profil Élève"
        ) : userRole === "TEACHER" ? (
          "Profil Enseignant"
        ) : (
          "Profil " + userRole
        )
      ) : (
        <></>
      )}
    </WrapperUser>
  );
};
