import styled from "styled-components";
import { theme } from "./theme";
import "../App.css"
import "../asset/fonts/grotasansaltrd-heavy.otf"


export const StyledButton = styled.button`
  background-color: ${(props) => props.backgroundColor ?? "#fff"};
  border-radius: ${(props) => props.borderRadius ?? "10px"};
  border: ${(props) => (props.isBorder === true ? "solid 2px " : "none")};
  border-color: ${(props) => props.borderColor ?? "#000"};
  color: ${(props) => props.colorText ?? "#fff"};
  margin: ${(props) => props.margin ?? "0.5em 1em"};
  padding: 0.5em 0.5em 0.5em 0.5em;
  font-size: ${(props) => props.fontSize ?? "25px"};
  font-weight: ${(props) => props.fontWeight ?? "600"};
  // font-family: ${(props) => props.grota ? "GrotaSansAltRd" : "Helvetica Neue"};
  transition: all 150ms ease;
  cursor: pointer;
  position: relative;
  &:hover {
    background-color: ${(props) => props.colorText ?? "#fff"};
    color: ${(props) => props.colorHover ?? "#fff"};
  }
`;
