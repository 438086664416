import React, { useState, useEffect } from "react";
import { RoundClass } from "../../utils/roundClass/RoundClass";
import { WrapperWorkspace } from "./Workspace.style";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { colors } from "../../utils/colors";
import { InputLabel } from "@mui/material";
import { FormControl } from "@mui/material";
import { StyledButton } from "../../utils/StyledButton.style";
import { DialogInput } from "../../utils/dialog/DialogInput";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Link, useNavigate, useParams } from "react-router-dom";
import { GET_CURRENT_USER } from "../../utils/graphql/user/query";
import { useQuery, useMutation, gql } from "@apollo/client";
import { CREATE_SCHOOL } from "./graphgl/mutation";
import { GET_GROUPS } from "../../utils/graphql/group/query";
import { CREATE_GROUP } from "../../utils/graphql/group/mutation";
import { GET_SCHOOLS } from "../../utils/graphql/school/query";
import { tokenise } from "../../utils/tokenise";

export const WorkspaceForAdmin = ({ propsDevice }) => {
  let navigate = useNavigate();
  const {
    client,
    loading: loadingCurrentUser,
    error: errorCurrentUser,
    data: dataCurrentUser,
  } = useQuery(GET_CURRENT_USER);





  const [openDialogSchool, setOpenDialogSchool] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [valueLibele, setValueLibele] = useState("");
  const [valueSchool, setValueSchool] = useState("");
  const [valueGroup, setValueGroup] = useState("");
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredItems, setFilteredItems] = useState([]);

        const enviroment = process.env.APP_ENV === "development" ?  "https://waykeup-dev.herokuapp.com/" : "https://www.waykeup.app/" 

  const {
    loading: loadingGroup,
    error: errorGroup,
    data: dataGroup,
  } = useQuery(GET_GROUPS);

  const {
    loading: loadingSchools,
    error: errorSchools,
    data: dataSchools,
  } = useQuery(GET_SCHOOLS);





  const [
    createSchool,
    { dataCreationSchool, loadingCreationSchool, errorCreationSchool },
  ] = useMutation(CREATE_SCHOOL, {
    update(cache, { data: { createSchool } }) {
      cache.modify({
        fields: {
          getSchools(existingSchools = []) {
            const newSchoolRef = cache.writeFragment({
              data: createSchool,
              fragment: gql`
                fragment NewSchool on School {
                  id
                  name
                }
              `,
            });
            return [...existingSchools, newSchoolRef];
          },
        },
      });
    },
  });


  const [year, setYear] = useState(2324);


  const redirect = () => {
    if (dataCurrentUser) {
      if (dataCurrentUser?.getCurrentUser?.roleId === 2) {
        navigate("/workspace");
      }
      if (dataCurrentUser?.getCurrentUser?.roleId === 3) {
        navigate(`/studentSingularitySetting/${dataCurrentUser?.getCurrentUser?.id.parse()}`);
      }
    }
  };
  redirect();



  const handleClickOpenSchool = () => {
    setOpenDialogSchool(!openDialogSchool);
  };
  const handleClickOpen = () => {
    setOpenDialog(!openDialog);
  };

  useEffect(() => {
    if (dataCurrentUser?.getCurrentUser?.roleId === 2) {
      <Link to={"/workspace"} />;
    }
    if (dataCurrentUser?.getCurrentUser?.roleId === 3) {
      <Link
        to={"/studentSingularitySetting/" + dataCurrentUser?.getCurrentUser?.id}
      />;
    }
  }, [dataCurrentUser]);

  const addNewItem = async (libelle) => {
    const data = await createSchool({
      variables: {
        schoolInput: {
          name: libelle,
        },
        optimisticResponse: {
          __typename: "Mutation",
          createSchool: {
              __typename: "School",
              name: libelle,
          }
      }
      },
    });
    

  };

  const handleCloseSchool = () => {
    setOpenDialogSchool(false);
  };
  const handleClose = () => {
    setOpenDialog(false);
  };

  const handleConfirm = (value) => {
    setOpenDialogSchool(false);

    addNewItem(value);
  };

  useEffect(() => {
    if (dataCurrentUser) {
      console.log("WELCOME EN LIGNE !!!")
      
    }
    else{
      navigate("/login");
    }
  }, [dataCurrentUser]);




  useEffect(() => {
    if (dataSchools) {
      const schools = Object.values(dataSchools?.getSchools || {});
      const filtered = schools.filter(item =>
        item?.name?.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredItems(filtered);
    }
  }, [searchTerm, dataSchools]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        margin: "20px",
        width: "100%",
        height: "100%",
        alignItems: "center",
      }}
    >
      {" "}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "90%",
        }}
      >
        <div
          style={{
            alignSelf: "center",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              font: "normal normal bold 27px Helvetica Neue",
              color: "#316880",
            }}
          >
            {dataCurrentUser?.getCurrentUser?.roleId === 1 ? "NOS ETABLISSEMENTS" : "MES CLASSES"}
          </div>
          <StyledButton
            grota={true}
            colorText={"#326880"}
            backgroundColor={"transparent"}
            colorHover={"#FFF"}
            fontSize={"13px"}
            isBorder={true}
            borderColor={"#326880"}
            onClick={handleClickOpenSchool}
          >
            {"AJOUTER UN ÉTABLISSEMENT"}
          </StyledButton>
          <StyledButton
            grota={true}
            colorText={"#326880"}
            backgroundColor={"transparent"}
            colorHover={"#FFF"}
            fontSize={"13px"}
            isBorder={true}
            borderColor={"#326880"}
            onClick={handleClickOpen}
          >
            {`AJOUTER UN "RESPONSABLE PÉDAGOGIQUE"`}
          </StyledButton>
        </div>
      </div>
      <input
    type="text"
    placeholder="Rechercher..."
    value={searchTerm}
    onChange={(e) => setSearchTerm(e.target.value)}
  />
      <WrapperWorkspace>
      

  {filteredItems.length > 0 ? (
    filteredItems.map((item, index) => (
      <RoundClass
        key={item?.id || index}
        propsDevice={propsDevice.device}
        item={item}
        itemSize={item["Group"]?.length || 0}
        color={colors[index]}
        currentUserId={dataCurrentUser?.getCurrentUser?.id}
        navigateLink={`/workspaceAdmin/${item?.id}/${item?.name}`}
      />
    ))
  ) : (
    <>Aucun élément trouvé</>
  )}
      </WrapperWorkspace>
      <div>
        <Dialog open={openDialogSchool} onClose={handleClose}>
          <DialogTitle>{"AJOUTER UN ÉTABLISSEMENT"}</DialogTitle>
          <DialogContent>
            <DialogContentText>{""}</DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label={"Libélé de l'établissement"}
              type="text"
              fullWidth
              variant="standard"
              value={valueLibele}
              onChange={(e) => setValueLibele(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseSchool}>Fermer</Button>
            <Button onClick={() => handleConfirm(valueLibele)}>
              Confirmer
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      <div>
        <Dialog open={openDialog} onClose={handleClose}>
          <DialogTitle>{`AJOUTER UN "RESPONSABLE PÉDAGOGIQUE"`}</DialogTitle>
          <DialogContent>
            <DialogContentText>{""}</DialogContentText>
            <FormControl sx={{ m: 1, minWidth: 80 }}>
              <InputLabel id="demo-simple-select-autowidth-label">
                Établissement
              </InputLabel>
              <Select
                labelId="demo-simple-select-autowidth-label"
                id="demo-simple-select-autowidth"
                value={valueSchool}
                onChange={(e) => setValueSchool(e.target.value)}
                label="Établissement"
                sx={{ width: "220px", margin: "0 10px 0 0" }}
              >
                {dataSchools?.getSchools?.map((item) => {
                  if (item.id != 1) {
                    return <MenuItem value={item.id}>{item.name}</MenuItem>;
                  }
                })}
              </Select>
            </FormControl>
            <FormControl sx={{ m: 1, minWidth: 80 }}>
              <InputLabel id="demo-simple-select-autowidth-label">
                Groupe
              </InputLabel>
              <Select
                labelId="demo-simple-select-autowidth-label"
                id="demo-simple-select-autowidth"
                value={valueGroup}
                onChange={(e) => setValueGroup(e.target.value)}
                label="Groupe"
                sx={{ width: "220px", margin: "0 10px 0 0" }}
              >
                {dataGroup?.getGroups?.map((item) => {
                  if (
                    item?.school_id == valueSchool &&
                    item?.name.includes("TEAM")
                  ) {
                    return <MenuItem value={item.id}>{item.name}</MenuItem>;
                  }
                })}
              </Select>
            </FormControl>

            <div>
              {
                "Copier ce lien pour que le responsable pédagogique créé ses identifiants et s'inscrive :"
              }
            </div>
            <TextField
              sx={{ fontSize: "10px" }}
              fullWidth
              value={`${enviroment}signup/${tokenise(
                `2/${valueSchool}/${valueGroup}`
              )}`}
              id="fullWidth"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Fermer</Button>
            <Button
              disabled={!valueSchool || !valueGroup}
              onClick={() => {
                navigator.clipboard.writeText(
                  `${enviroment}signup/${tokenise(
                    `2/${valueSchool}/${valueGroup}`
                  )}`
                );
                handleClose();
              }}
            >
              Copier le lien dans le press-papier
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
};
