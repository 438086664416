import styled from "styled-components";

export const WrapperUser = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 76px;
  color: #fff;
  font-family: Helvetica Neue;
  font-weight: 400;
  font-size: 20px;
  background: #43768b 0% 0% no-repeat padding-box;
  box-shadow: inset 3px 3px 9px #00000029;
  &:hover {
    background-color: #ff986e;
    color: #12516d;
  }
  cursor: pointer;
`;

export const RoundNameUser = styled.div`
  display: flex;
  width: 45px;
  height: 45px;
  color: #326880;
  border-radius: 100%;
  font-family: Helvetica Neue;
  font-weight: 600;
  font-size: 25px;
  align-items: center;
  justify-content: center;
  background: #fff;
  margin: 10px;
  cursor: pointer;
`;
