import { gql } from "@apollo/client";

export const ADD_AWAYKER_IN_GROUP = gql`
mutation AddAwaykerInGroup($awaykerGroupInput: AwaykerGroupInput!) {
    addAwaykerInGroup(awaykerGroupInput: $awaykerGroupInput) {
      groupId
      userId
    }
  }
`


export const REMOVE_AWAYKER_FROM_GROUP = gql`
mutation RemoveAwayker($userId: Int!, $groupId: Int!) {
    removeAwayker(userId: $userId, groupId: $groupId)
  }
`

