import styled from "styled-components";
import { theme } from "./theme";

export const StyledInlineTextButton = styled.button`
  background-color: transparent;

  border-bottom: transparent;
  color: ${(props) => (props.colorText ? "#fff" : props.colorText)};
  margin: 0 1em;
  padding: 0.32em 1em 0.2em 1em;
  font: normal normal 400 20px/12px Helvetica Neue;
  transition: all 150ms ease;
  cursor: pointer;
  border: none;

  &:hover {
    color: ${(props) => props.colorText ?? "#fff"};
    border-bottom: 2px dotted;
    border-color: ${(props) => props.colorText ?? "#fff"};
  }
`;
