import { gql } from "@apollo/client";

export const GET_AWAYKER_GROUPS_BY_AWAYKER_ID = gql`
  query GetAwaykerGroupsByUserId($userId: Int!) {
    getAwaykerGroupsByUserId(userId: $userId) {
      group {
        name
        id
        school_id
        size
        year
      }
      user {
        email
        lastname
        name
        profilePicture
      }
    }
  }
`;

export const GET_AWAYKER_GROUPS_BY_GROUP_ID = gql`
  query GetAwaykerGroupsByUserId($groupId: Int!) {
    getAwaykerGroupsByGroupId(groupId: $groupId) {
      group {
        name
        school {
          name
        }
      }
      user {
        email
        lastname
        name
        profilePicture
        id
      }
    }
  }
`;
